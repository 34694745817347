import React, { Component } from "react";
import { connect } from "react-redux";
import Cards from "../Component/cards";

import { fetchReviews } from "../../../Client/action";
export class Controller extends Component {
  render() {
    return <Cards {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    fetchReviews: () => {
      dispatch(fetchReviews());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
