import React, { useState } from "react";
import carens1 from '../../../Image/Carens/carens1.png';
import carens2 from '../../../Image/Carens/carns2.png';
import carens3 from '../../../Image/Carens/carens3.png';
import carens4 from '../../../Image/Carens/carens4.png';
import carens5 from '../../../Image/Carens/carens5.png';
import carens6 from '../../../Image/Carens/carens6.png';
import carens7 from '../../../Image/Carens/carens7.png';
import { Typography,Box } from "@mui/material";

const CustomCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0); 
  const [fixedImage, setFixedImage] = useState(carens1); 

  const carImages = [
    { src: carens1, alt: "car1", color: "#2B2C71" },
    { src: carens2, alt: "car2", color: "#A80F1C" },
    { src: carens3, alt: "car3", color: "#5D6062" },
    { src: carens4, alt: "car4", color: "#E0E0DF" },
    { src: carens5, alt: "car5", color: "#444444" },
    { src: carens6, alt: "car6", color: "#F0F8FC" },
    { src: carens7, alt: "car7", color: "#3E493B" },

  ];

  const handleSelect = (index) => {
    setActiveIndex(index);
    setFixedImage(carImages[index].src); 
  };

  return (
    <div style={{ position: "relative",margin:0,padding:0 }}>
    <div style={{ textAlign: "center",padding: "10px" }}>
    <Box sx={{paddingLeft:{xs:6,sm:13,md:23},textAlign:'left'}}>
      <Typography variant="h6" sx={{color:'#9F9C9C',fontSize:{xs:'16px',sm:'22px'},fontFamily:'KIAbodyLatinbasic_Bold',fontWeight:800,paddingBottom:1}}>DESIGN</Typography>
      <Typography variant="h4" sx={{fontSize:{xs:'22px',sm:'48px'},fontFamily:'KIAbodyLatinbasic_Bold',fontWeight:800,}}>CARENS</Typography>
    </Box>
      <img
        className="d-block w-100"
        src={carImages[activeIndex].src}
        alt={carImages[activeIndex].alt}
        style={{ maxWidth: "70%", height: "auto",paddingBottom:'10px' }}
      />
    </div>

    {/* Custom Circle Indicators */}
    <div style={{ position: "absolute", bottom: "20px", left: "50%", transform: "translateX(-50%)", display: "flex", gap: "12px" }}>
      {carImages.map((car, index) => (
        <div
          key={index}
          onClick={() => handleSelect(index)}
          style={{
            width: "23px",
            height: "22px",
            borderRadius: "50%",
            backgroundColor: activeIndex === index ? car.color : car.color, 
            cursor: "pointer",
          }}
        />
      ))}
    </div>
  </div>
  );
};

export default CustomCarousel;
