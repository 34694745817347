import React, { Component } from "react";
import { connect } from "react-redux";
import Enquiry from "../Components/Enquiry";

import {
  viewAllEnquiry,
  uploadImaes,
  updateEnquiryStatus,
  uploadupdatesImages,
  deleteEnquiry,
} from "../action";
export class Controller extends Component {
  render() {
    return <Enquiry {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    viewAllEnquiry: (
      token,
      user_id,
      searchInput,
      page,
      pageSize,
      startDate,
      endDate,
      selectedStatus
    ) => {
      dispatch(
        viewAllEnquiry(
          token,
          user_id,
          searchInput,
          page,
          pageSize,
          startDate,
          endDate,
          selectedStatus
        )
      );
    },

    updateEnquiryStatus: (token, user_id, id, status, page, pageSize) => {
      dispatch(updateEnquiryStatus(token, user_id, id, status, page, pageSize));
    },
    deleteEnquiry: (token, user_id, id, page, pageSize) => {
      dispatch(deleteEnquiry(token, user_id, id, page, pageSize));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
