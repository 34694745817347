import React, { useState } from 'react';
import { Container, Grid, Box, Button, Typography, Collapse, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const styles = {
  questionBox: {
    padding:'10px',
    width: '80vw',
    borderRadius: '4px',
    marginBottom: '10px',
    display: 'flex',
    fontFamily: 'KIAbodyLatinbasic_Bold',
    fontSize: '3.55556vw',
    flexDirection: 'column',
  },
  questionHeader: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'KIAbodyLatinbasic_Regular',
    justifyContent: 'space-between',
    color: 'black',
  },
  expandableContent: {
    padding: '5px',
  },
};

function QuestionBox({ question, content, expanded, onToggle }) {
  return (
    <Box sx={styles.questionBox}>
      <Box sx={styles.questionHeader}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'KIAbodyLatinbasic_Regular',
            fontSize: {md:'1.3vw',xs:'2.9vw',sm:'2.4vw'}, 
            backgroundColor: 'transparent',
            color: '#000',
            paddingRight: '4%',
            paddingLeft: '0',
            fontWeight: '750',
          }}
        >
          {question}
        </Typography>
        <Button onClick={onToggle} sx={{ color: 'black' }}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
      </Box>

      <Collapse in={expanded}>
        <Box sx={styles.expandableContent}>
          <Typography
            variant="body2"
            sx={{
              fontFamily: 'Roboto-Regular',
              fontSize: {md:'1.1vw',xs:'2.7vw',sm:'2.2vw'},
            }}
          >
            {content}
          </Typography>
        </Box>
      </Collapse>
    </Box>
  );
}


export default function App() {
  const [expanded, setExpanded] = useState(null);

  const contents = [
   
  'Kia Carens MSRP* starts at ₹10 51 900* (exclusive of all taxes).',
  "Yes, selected variants of Kia Carens top model comes with a sunroof.",
  "The Carens provides best-in-class cabin space, along with luxurious 3-row seating comfort, in Kia's 6 and 7-seater car options in India, allowing you to take your world with you wherever you go.",
  "Kia Carens is a family car that offers 6 airbags to ensure comprehensive safety for you and your loved ones.",
  'The all-new Kia Carens is available in both manual and automatic transmissions, namely – 7DCT, 6AT and 6 MT, 6iMT.',
  'Kia Carens Sunroof Models are Prestige+(O), Luxury and Luxury Plus. These selected top models feature a skylight sunroof for enhanced openness and ambiance.',
  ];

  const handleToggle = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <Container>
      <Box
  sx={{
    width: '100%',
    height:'100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }}
>
  <Typography
    variant="h2"
    sx={{
      fontSize: { md: '3.08333vw', xs: '4.7vw' },
      fontFamily: 'KIAbodyLatinbasic_Bold',
      paddingBottom: { md: '3.125vw', xs: '5.4vw' },
      fontWeight: '900',
      marginTop: { xs: '50px' },
    }}
  >
    Frequently Asked Questions
  </Typography>
  <Grid container spacing={1}>
    <Grid item xs={12} md={12}>
      <Box
        sx={{
          fontFamily: 'KIAbodyLatinbasic_Bold',
          backgroundColor: 'transparent',
          color: '#000',
          paddingRight: '4%',
          paddingLeft: '0',
        }}
      >
        {[
          'What is the price of Kia Carens?',
          'Does the Kia Carens come with a sunroof?', 
          'What are the seating options available in the Kia Carens?',
          'How many airbags are there in Kia Carens?', 
          'What are the transmission options available in the Kia Carens?',
          'Which Kia carens model has a sunroof?',

        ].map((question, index) => (
          <React.Fragment key={index}>
            <QuestionBox
              question={question}
              content={contents[index]}
              expanded={expanded === index}
              onToggle={() => handleToggle(index)}
            />
            {index < contents.length - 1 && (
              <Divider
                sx={{
                  margin: '10px 0',
                  backgroundColor: 'black',
                  height: '2px',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                }}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
    </Grid>
  </Grid>
</Box>

    </Container>
  );
}
