import React, { useState } from "react";
import { Box, Typography, Grid, Paper, TextField, Button } from "@mui/material";
import Footer from "../../Footer/Component/Footer";

const ContactUs = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!firstName) newErrors.firstName = "First Name is required.";
    if (!lastName) newErrors.lastName = "Last Name is required.";
    if (!email) newErrors.email = "Email ID is required.";
    if (!mobile) newErrors.mobile = "Mobile Number is required.";
    if (!address) newErrors.address = "Address is required.";

    setErrors(newErrors);

    props.AddContactForm1(firstName, lastName, email, mobile, address);
    setFirstName("");
    setLastName("");
    setEmail("");
    setMobile("");
    setAddress("");
  };

  return (
    <Box>
      {/* Hero Section */}
      <Box
        sx={{
          position: "relative",
          height: "70vh",
          overflow: "hidden",
          background:
            "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(132,132,132,1) 100%)",
        }}
      >
        <Box
          sx={{
            height: "70vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "13px",
          }}
        >
          <Typography
            variant="h2"
            sx={{ color: "white", fontWeight: "bolder",fontFamily:"KIAbodyLatinbasic_Bold" }}
          >
            Contact
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "white", fontWeight: "700",fontFamily:"KIAbodyLatinbasic_Bold" }}
          >
            Feel free to get in touch with us
          </Typography>
          <Button
                        type="submit"
                        variant="contained"
                        sx={{
                          backgroundColor: "#E50063",
                          fontWeight: "700",
                          "&:hover": {
                            backgroundColor: "#E50063",
                          },
                        }}
                      >
                        Get Direction
                      </Button>
        </Box>
      </Box>

      {/* Contact Us Form */}
      <Box sx={{ backgroundColor: "#F0F0F0", padding: "20px 0" }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={8} md={6}>
            <Paper sx={{ padding: "20px", boxShadow: "none" }}>
              <Typography
                variant="h3"
                sx={{
                  color: "#000",
                  fontWeight: "900",
                  marginBottom: "20px",
                  fontSize: { md: "48px", xs: "22px" },
                  fontFamily:"KIAbodyLatinbasic_Bold"
                }}
              >
                Contact Us
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  marginBottom: "15px",
                  fontSize: { md: "15px", sm: "13px", xs: "11px" },
                  fontFamily:"KIAbodyLatinbasic_Bold"
                }}
              >
                Call Us at <b>09237386213</b>
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  marginBottom: "15px",
                  fontSize: { md: "15px", sm: "13px", xs: "11px" },
                  fontFamily:"KIAbodyLatinbasic_Regular"
                }}
              >
                Mail us at: <b>customercare@centralkia.in</b>
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "900",
                  marginBottom: "35px",
                  fontSize: { md: "15px", sm: "13px", xs: "11px" },
                  fontFamily:"KIAbodyLatinbasic_Bold"
                }}
              >
                For any Enquiry fill your details below, we will call you back.
              </Typography>

              <Box
                component="form"
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <Grid container spacing={2} sx={{ marginBottom: "15px" }}>
                  <Grid item xs={12} sm={6}>
                    <Typography>
                      First Name{" "}
                      <sup style={{ color: "red", fontSize: "18px" }}>*</sup>
                    </Typography>
                    <TextField
                      fullWidth
                      label="Enter First Name"
                      variant="standard"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      error={!!errors.firstName}
                      helperText={errors.firstName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>
                      Last Name{" "}
                      <sup style={{ color: "red", fontSize: "18px" }}>*</sup>
                    </Typography>
                    <TextField
                      fullWidth
                      label="Enter Last Name"
                      variant="standard"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      error={!!errors.lastName}
                      helperText={errors.lastName}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: "15px" }}>
                  <Grid item xs={6}>
                    <Typography>
                      Email ID{" "}
                      <sup style={{ color: "red", fontSize: "18px" }}>*</sup>
                    </Typography>
                    <TextField
                      fullWidth
                      label="Enter Email ID"
                      variant="standard"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      error={!!errors.email}
                      helperText={errors.email}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      Mobile No.{" "}
                      <sup style={{ color: "red", fontSize: "18px" }}>*</sup>
                    </Typography>
                    <TextField
                      fullWidth
                      label="Enter Mobile Number"
                      variant="standard"
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                      error={!!errors.mobile}
                      helperText={errors.mobile}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ marginBottom: "15px" }}>
                  <Grid item xs={12}>
                    <Typography>
                      Address{" "}
                      <sup style={{ color: "red", fontSize: "18px" }}>*</sup>
                    </Typography>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      label="Enter Your Address"
                      variant="standard"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      error={!!errors.address}
                      helperText={errors.address}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{
                          backgroundColor: "#E50063",
                          fontWeight: "700",
                          "&:hover": {
                            backgroundColor: "#E50063",
                          },
                        }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Box>
  );
};

export default ContactUs;
