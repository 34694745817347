import { createStore, combineReducers, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import loader from "./Common/loader/reducer";
import snackbar from "./Common/snackbar/reducer";
import login from "./Auth/Reducer";
// import exec from "./Pages/Executive/Reducer";
import client from "../src/Client/reducer";
// import model from "./Pages/Model/Reducer";
import oldcar from "./Pages/Preownedcars/reducer";

const persistConfig = {
  key: "root",
  storage,
};

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
const reducer = combineReducers({
  loader,
  snackbar,
  login,
  client,
  oldcar,
});

const persistedReducer = persistReducer(persistConfig, reducer);
const configureStore = (initialState) =>
  createStoreWithMiddleware(persistedReducer, initialState);
const store = configureStore();
let persistor = persistStore(store);
export default { store, persistor };
