import React from "react";
import {
  Typography,
  Button,
  Box,
  Grid,
  Card,
} from "@mui/material";
import Video from "../../../Video/Carnival/carnival.mp4";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import DescriptionIcon from "@mui/icons-material/Description";
import Carnivalbrochure from "../../../Brochure/Carnival/Kia_Carnival_Brochure_Desktop.pdf";
import Carnival from "../../../Image/Carnival/carnival1.jpg";
import Slidingcarnival from "../../../Cars/Carnival/Component/Slidingcarnival";
import Carnivalfullimage from "../../../Image/Carnival/carnivalfull.jpg";
import Contentcarrousel from "../../../Cars/Carnival/Component/Contentcarrousel";
import ShowInterest from "../../../show interest/Container/showinterestCon";
import ColorCarnival from './color'
const Carnivals = () => {

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Box sx={{ position: "relative", height: "100vh", overflow: "hidden" }}>
        <video
            playsInline
            autoPlay
            loop
            muted
            preload="auto"
            style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                objectFit: 'cover',
            }}
        >
          <source src={Video} type="video/mp4" />
        </video>
      </Box>

      <Box sx={{ backgroundColor: "#FFFFFF" }}>
        {/* Secord card section */}
        <Box sx={{ padding: "20px", maxWidth: "1200px", margin: "0 auto" }}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={12} md={6}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "20px",
                  boxShadow: 3,
                  gap: "15px",
                }}
              >
                <DriveEtaIcon sx={{ fontSize: "40px", color: "#000" }} />
                <Typography
                  variant="h6"
                  sx={{ marginTop: "10px", fontWeight: "bold",fontFamily:"KIAbodyLatinbasic_Bold" }}
                >
                  Register your interest
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#848484", fontSize: "13px", fontFamily:"KIAbodyLatinbasic_Regular" }}
                >
                  Fill in the required details by clicking here, We will contact
                  you as soon as possible.
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    marginTop: "10px",
                    backgroundColor: "#E50063",
                    color: "white",
                    borderRadius: "8px",
                    height: "50px",
                    fontFamily:"KIAbodyLatinbasic_Bold",
                    fontWeight:800,
                    "&:hover": {
                      backgroundColor: "#E50063",
                    },
                  }}
                  onClick={() => {
                    const element = document.getElementById("showinterest");
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                  }}
                >
                  Click Here
                </Button>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "20px",
                  boxShadow: 3,
                  gap: "15px",
                }}
              >
                <DescriptionIcon sx={{ fontSize: "40px", color: "#000" }} />
                <Typography
                  variant="h6"
                  sx={{ marginTop: "10px", fontWeight: "bold",fontFamily:"KIAbodyLatinbasic_Bold" }}
                >
                  Download Brochure
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#848484", fontSize: "13px", fontFamily:"KIAbodyLatinbasic_Regular" }}
                >
                  Click here to download the Brochure and price list of all new
                  Carnival on your device
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    marginTop: "10px",
                    backgroundColor: "#E50063",
                    color: "white",
                    borderRadius: "8px",
                    height: "50px",
                    fontFamily:"KIAbodyLatinbasic_Bold",
                    fontWeight:800,
                    "&:hover": {
                      backgroundColor: "#E50063",
                    },
                  }}
                  onClick={() => {
                    const link = document.createElement("a");
                    link.href = Carnivalbrochure;
                    link.download = "Kia-Carnival_brochure.pdf";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }}
                >
                  Click To Download
                </Button>
              </Card>
            </Grid>
          </Grid>
        </Box>

        {/* Content Section */}
        <Box>
          <Box
            sx={{
              textAlign: "left",
              paddingLeft: { xs: "20px", md: "50px" },
              marginTop: "30px",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontWeight: "bold",
                marginTop: "6px",
                fontWeight: "800",
                fontFamily: "KIAbodyLatinbasic_Bold",
                fontSize: { xs: "24px", md: "36px" },
              }}
            >
              Your own luxury liner.
            </Typography>

            <Typography
              variant="body1"
              color="text.secondary"
              sx={{
                fontSize: { xs: "16px", md: "22px" },
                width: "80%",
                fontFamily: "KIAbodyLatinbasic_Regular",
                marginTop: "6px",
              }}
            >
              With a bold grille, dazzling projection headlamps, and design that
              turns heads, The new Carnival is here to announce your arrival in
              unparalleled style.
              <br />
              <br />
              The new Carnival Limousine is more than a car; it's an experience.
              It gives wings to finely crafted comfort and envelops you in
              opulence. Step in, and you'll forget you're still on the ground.
              <br />
              <br />
            </Typography>

            
          </Box>

          <Box
            sx={{
              backgroundImage: `url(${Carnival})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              height: "500px",
              mt: 3,
            }}
          />
        </Box>

        {/*color carnival  */}
        <ColorCarnival/>

        {/* Sliding Carnival */}
        <Slidingcarnival />

        {/* Show Interest */}
        <Box id="showinterest">
          <ShowInterest someValue="Carnival" />
        </Box>

        {/* Full-sized image */}
        <Box
          sx={{
            width: "100vw",
            height: { md:'100vh',sm:'60vh',xs:'40vh'},
            overflow: "hidden",
          }}
        >
          <Grid
            container
            sx={{
              width: "100%",
              height: {md:'100vh',sm:'60vh',xs:'40vh'},
              backgroundImage: `url(${Carnivalfullimage})`,
              backgroundSize: "100% 100%",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                color: "#fff",
                textShadow: "1px 1px 4px rgba(0, 0, 0, 0.7)",
              }}
            ></Grid>
          </Grid>
        </Box>

        {/* Content carrousels */}
        <Contentcarrousel />
      </Box>
    </Box>
  );
};

export default Carnivals;
