import React, { useState } from 'react';
import { Container, Grid, Box, Button, Typography, Collapse, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const styles = {
  questionBox: {
    padding:'10px',
    width: '80vw',
    borderRadius: '4px',
    marginBottom: '10px',
    display: 'flex',
    fontFamily: 'GillSansSemiBold, sans-serif',
    fontSize: '3.55556vw',
    flexDirection: 'column',
    fontSize:{xs:'18px'}
  },
  questionHeader: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'GillSansSemiBold, sans-serif',
    fontSize: '5.55556vw',
    justifyContent: 'space-between',
    color: 'black',
  },
  expandableContent: {
    padding: '5px',
  },
};

function QuestionBox({ question, content, expanded, onToggle }) {
  return (
    <Box sx={styles.questionBox}>
      <Box sx={styles.questionHeader}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'KIAbodyLatinbasic_Bold',
            fontSize: { xs: '2.7vw', md: '1.55556vw', }, // Increase font size for mobile
            backgroundColor: 'transparent',
            color: '#000',
            paddingRight: '4%',
            paddingLeft: '0',
            fontWeight: '750',
          }}
        >
          {question}
        </Typography>
        <Button onClick={onToggle} sx={{ color: '#CF2230' }}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
      </Box>

      <Collapse in={expanded}>
        <Box sx={styles.expandableContent}>
          <Typography
            variant="body2"
            sx={{
              fontFamily: 'KIAbodyLatinbasic_Regular',
              fontSize: {md:'1.3vw',xs:'2.7vw',sm:'2.4vw'},
            }}
          >
            {content}
          </Typography>
        </Box>
      </Collapse>
    </Box>
  );
}


export default function App() {
  const [expanded, setExpanded] = useState(null);

  const contents = [
   
'7 Day Moneyback guarantee is a peace of mind proposition given to buyers who purchase CarWale abSure4S certified cars. Post vehicle delivery if you feel to return the car, you can do so within 7 days of the car delivery subject to conditions. You will get 100% money refund no questions asked.',
"MG Astor is 5 seater SUV Car. It comes with both manual & automatic transmission.",
"No. 7 Day Money Back guarantee or comprehensive warranty is applicable only on CarWale abSure4S certified cars. These cars are thoroughly certified by our inhouse expert evaluators across 167 certification points.",
'Our executive will get in touch to book a test drive if you have reserved a car or shown interest by giving your contact details for the car. You can also call toll-free abSure helpline number 1800-210-2180, operational Sunday-Friday 10AM - 7PM & Saturday 10AM - 5:30PM.',
'You can reserve a car that you like by paying a refundable amount & it shall be reserved exclusively for you for 3 days. This car will not be sold to anyone else for those 3 days. You can choose to buy the car if you are satisfied or get 100% refund in case not interested.',
'You can call toll-free abSure helpline number 1800-210-2180, operational Sunday-Friday 10AM-7PM & Saturday 10AM-5:30PM. You can also write to us at contact@carwale.com.',
'Our Dealer partner will assist in the transfer of RC & other vehicle documents in the name of the customer in a stipulated period of time. It shall also assist the customer in finding an insurance policy if needed for the car.',
"Warranty helpline no: 9599107094, Roadside assistance: 18002101010, CarWale abSure: 18002102180"
  ];

  const handleToggle = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <Container>
      <Box
  sx={{
    width: '100%',
    height:'100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }}
>
  <Typography
    variant="h2"
    sx={{
      fontSize: { md: '2.08333vw', xs: '4.7vw' },
      color: '#cf2230',
      fontFamily: 'KIAbodyLatinbasic_Bold',
      paddingBottom: { md: '3.125vw', xs: '5.4vw' },
      fontWeight: '400',
      marginTop: { xs: '50px' },
    }}
  >
    Frequently Asked Questions
  </Typography>
  <Grid container spacing={1}>
    <Grid item xs={12} md={12}>
      <Box
        sx={{
          fontFamily: 'KIAbodyLatinbasic_Bold',
          fontSize: { md: '5.55556vw' },
          backgroundColor: 'transparent',
          color: '#000',
          paddingRight: '4%',
          paddingLeft: '0',
        }}
      >
        {[
          'What is 7 day moneyback guarantee?',
          'Is 7 day moneyback guarantee or 15000KM warranty applicable on all cars?', 
          'How do I book a test drive of an abSure car?',
          'Why should I reserve an abSure car I am interested in?', 
          'I have paid token to reserve an abSure car, how do I get a refund?',
          'What about the RC, Insurance & other documents related to the car?',
          'What are the Toll free contact numbers for assistance?'

        ].map((question, index) => (
          <React.Fragment key={index}>
            <QuestionBox
              question={question}
              content={contents[index]}
              expanded={expanded === index}
              onToggle={() => handleToggle(index)}
            />
            {index < contents.length - 1 && (
              <Divider
                sx={{
                  margin: '10px 0',
                  backgroundColor: 'black',
                  height: '2px',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                }}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
    </Grid>
  </Grid>
</Box>

    </Container>
  );
}
