import React from 'react';
import Slider from 'react-slick';
import { Box, Typography } from '@mui/material';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Image1 from '../../../Image/Ev6/card1.jpg';
import Image2 from '../../../Image/Ev6/card2.jpg';
import Image3 from '../../../Image/Ev6/card3.jpg';
import Image4 from '../../../Image/Ev6/card4.png';
import Image5 from '../../../Image/Ev6/card5.jpg';
import Image6 from '../../../Image/Ev6/card6.jpg';
import Image7 from '../../../Image/Ev6/card7.png';


const cardData = [
  {
    image: Image1,
    title: 'Sustainable Material',
    description: 'The upholstery makes use of sustainable suede and vegan leather while the cabin uses abundant recycled and durable materials.'
  },
  {
    image: Image2,
    title: 'Remote Folding 60:40 Split Rear Seats',
    description: 'Pull the conveniently located remote release lever on the wall of the cargo space to fold the rear seatback down, creating 1,300 l of storage capacity.'
  },
  {
    image: Image3,
    title: 'Wide Electric Sunroof',
    description: 'The roomy dimensions of the Kia EV6 seem to expand with the wide sunroof.'
  },
  {
    image: Image4,
    title: 'Connected LED Tail Lamps with Sequential Indicators',
    description: 'The dynamic curved shape full LED Taillight lends sleek and futurustic look to the rear of EV6'
  },
  {
    image: Image5,
    title: 'Body Coloured Exterior flush Door handles- Automatic',
    description: 'The seamlessly designed auto-door handles are hidden inside the doors and pop-out as you approach it with smart key'
  },
  {
    image: Image6,
    title: 'R19 - 48.74 cm (19") Crystal-Cut Alloy Wheels',
    description: 'The edgy alloy wheel design along with two color tone makes a statement and lends the EV6 a sporty and distinctive stance.'
  },
  {
    image: Image7,
    title: 'Dual LED Headlamps with Adaptive driving Beam',
    description: 'The distinctive headlamps not only have a progressive design language but also theb intelligence to automatically adjust to avoid dazzling oncoming vehicles '
  },
];

const CustomCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024, // Tablet view
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600, // Mobile view
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <Box sx={{ margin: '15px' }}>
      <Typography variant="h3" sx={{ textAlign: 'center', fontFamily: 'Kia-sig', fontWeight: 800,fontFamily:'KIAbodyLatinbasic_Bold' }}>
        Meet our Global Superstar
      </Typography>
      <Box sx={{ width: '88%', margin: 'auto', padding: '20px 30px' }}>
        <Slider {...settings}>
          {cardData.map((card, index) => (
            <Box key={index} sx={{ padding: '0 10px', }}>
              <img src={card.image} alt={card.title} style={{ width: '90%', height: 'auto', borderRadius: '8px' }} />
              <Typography variant="h6" sx={{ marginTop: 2 ,width:'95%',fontFamily:"KIAbodyLatinbasic_Bold"}}>{card.title}</Typography>
              <Typography variant="body2" sx={{ color: 'gray',width:'80%',fontFamily:"KIAbodyLatinbasic_Regular" }}>{card.description}</Typography>
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

// Custom arrow components
const SampleNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      style={{
        display: 'block',
        background: 'rgba(0, 0, 0, 0.5)',
        borderRadius: '50%',
        padding: '10px',
        right: '-30px',
        zIndex: 1,
      }}
    />
  );
}

const SamplePrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      style={{
        display: 'block',
        background: 'rgba(0, 0, 0, 0.5)',
        borderRadius: '50%',
        padding: '10px',
        left: '-50px',
        zIndex: 1,
      }}
    />
  );
}

export default CustomCarousel;
