import React, { Component } from "react";
import { connect } from "react-redux";
import Pricepagedesign from "../Components/Pricepagedesign";
import { close_snack_bar } from "../../../Common/snackbar/action";
import { getQuotationsRequest } from "../../../Client/action";

export class Controller extends Component {
  render() {
    return <Pricepagedesign {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {

    close_snack_bar: () => {
        dispatch(close_snack_bar());
    },
    getQuotationsRequest: (
      carName,
      firstName,
      lastName,
      email,
      mobile,
      city,
      dealership,
      pincode
    ) => {
      dispatch(
        getQuotationsRequest(
          carName,
          firstName,
          lastName,
          email,
          mobile,
          city,
          dealership,
          pincode
        )
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
