import React, { useState, useEffect } from "react";
import { Grid, Card, Typography, Avatar, Box, Button } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import Rating from "@mui/material/Rating";
const Testimonials = (props) => {
  const [expandedReviews, setExpandedReviews] = useState({});
  const visibleCount = 12; 
  const [reviewsToShow, setReviewsToShow] = useState(
    props.client.all_reviews?.slice(0, visibleCount) || []
  );
  const testimonials = [
    {
      rating: "5/5",
      feedback:
        "Good experience buying a car. Clear and honest communication. All papers were handed over on time.",
      avatar: "A",
      name: "Akash Agarwal",
    },
    {
      rating: "4/5",
      feedback:
        "I recently had my Kia serviced at Central Kia, and I must say, it was a fantastic experience from start to finish. The service was completed in a timely manner, and my car is running smoothly thanks to expertise. Thanks Shakti for guiding through",
      avatar: "D",
      name: "Dipty Ranjan Pradhan",
    },
    {
      rating: "4/5",
      feedback:
        "Nice behaviour by the advisor, I was very happy with the service. Fast service which is very helpful to me.",
      avatar: "D",
      name: "Deepak Sahoo",
    },
    {
      rating: "5/5",
      feedback:
        "Good experience buying a car. Clear and honest communication. All papers were handed over on time.",
      avatar: "AA",
      name: "Akash Agarwal",
    },
    {
      rating: "4/5",
      feedback:
        "I recently had my Kia serviced at Central Kia, and I must say , it was a fantastic experience from start to finish. The service was completed in timely manner, and my car is running smoothly thanks to their expertise. Thanks Shakti for guiding through",
      avatar: "DRP",
      name: "Dipti Ranjan Pradhan",
    },
    {
      rating: "4/5",
      feedback:
        "Nice behaviour by the advisor, I was very happy with the service. Fast service which is very helpful to me.",
      avatar: "D",
      name: "Deepak Sahoo",
    },
    {
      rating: "5/5",
      feedback:
        "Good experience buying a car. Clear and honest communication. All papers were handed over on time.",
      avatar: "AA",
      name: "Akash Agarwal",
    },
    {
      rating: "4/5",
      feedback:
        "I recently had my Kia serviced at Central Kia, and I must say , it was a fantastic experience from start to finish. The service was completed in timely manner, and my car is running smoothly thanks to their expertise. Thanks Shakti for guiding through",
      avatar: "DRP",
      name: "Dipti Ranjan Pradhan",
    },
    {
      rating: "4/5",
      feedback:
        "Nice behaviour by the advisor, I was very happy with the service. Fast service which is very helpful to me.",
      avatar: "D",
      name: "Deepak Sahoo",
    },
    {
      rating: "5/5",
      feedback:
        "Good experience buying a car. Clear and honest communication. All papers were handed over on time.",
      avatar: "AA",
      name: "Akash Agarwal",
    },
    {
      rating: "4/5",
      feedback:
        "I recently had my Kia serviced at Central Kia, and I must say , it was a fantastic experience from start to finish. The service was completed in timely manner, and my car is running smoothly thanks to their expertise. Thanks Shakti for guiding through",
      avatar: "DRP",
      name: "Dipti Ranjan Pradhan",
    },
    {
      rating: "4/5",
      feedback:
        "Nice behaviour by the advisor, I was very happy with the service. Fast service which is very helpful to me.",
      avatar: "D",
      name: "Deepak Sahoo",
    },
  ];

  useEffect(() => {
    props.fetchReviews();
  }, []); // Effect runs when `placeId` changes
  const handleLoadMore = () => {
    const newVisibleCount = reviewsToShow.length + visibleCount;
    setReviewsToShow(props.client.all_reviews?.slice(0, newVisibleCount));
  };

  const handleToggleReadMore = (index) => {
    setExpandedReviews((prev) => ({
      ...prev,
      [index]: !prev[index], 
    }));
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return {
        truncated: words.slice(0, wordLimit).join(" "),
        remaining: words.slice(wordLimit).join(" "),
      };
    }
    return { truncated: text, remaining: "" };
  };

  return (
    <Box sx={{ paddingBottom: "40px" }}>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="stretch"
        sx={{ padding: "20px" }}
      >
        {reviewsToShow?.filter((review) => review.rating >= 4).length > 0 ? (
          reviewsToShow
            ?.filter((review) => review.rating >= 4) // Filter reviews with rating 4 or 5
            .map((item, index) => {
              const { truncated, remaining } = truncateText(
                item.text || "",
                50
              );
              const isExpanded = expandedReviews[index];

              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  key={index}
                  sx={{ display: "flex", paddingBottom: "40px" }}
                >
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "20px",
                      textAlign: "center",
                      height: "100%",
                    }}
                  >
                    <GoogleIcon fontSize="large" sx={{ color: "#848484" }} />
                    <Rating
                      value={item.rating} 
                      precision={0.1} 
                      readOnly 
                      sx={{ marginBottom: "10px" }} 
                    />
                    <Typography variant="h6" sx={{ margin: "10px 0" }}>
                      {item.rating}/5
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        margin: "10px 0",
                        color: "#848484",
                        fontFamily: "KIAbodyLatinbasic_Regular",
                        textAlign: "justify",
                        fontSize:{xs:'14px',sm:'15px'}
                      }}
                    >
                      {isExpanded
                        ? `${truncated} ${remaining}`
                        : `${truncated}...`}
                    </Typography>
                    {remaining && (
                      <Button
                        onClick={() => handleToggleReadMore(index)}
                        sx={{
                          textTransform: "none",
                          color: "primary.main",
                          padding: 0,
                          fontSize: "12px",
                        }}
                      >
                        {isExpanded ? "Show Less" : "Read More"}
                      </Button>
                    )}
                    <Avatar
                      src={item.profile_photo_url}
                      alt={item.author_name || "User"}
                      sx={{
                        bgcolor: "#E50063",
                        width: 40,
                        height: 40,
                        fontSize: "16px",
                        margin: "10px 0",
                      }}
                    />
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      {item.author_name || "Anonymous"}
                    </Typography>
                  </Card>
                </Grid>
              );
            })
        ) : (
          <Typography
            variant="h6"
            sx={{ color: "#848484", textAlign: "center" }}
          >
            No reviews available.
          </Typography>
        )}
      </Grid>
      {props.client?.all_reviews?.filter((review) => review.rating >= 4)
        .length >
        reviewsToShow.filter((review) => review.rating >= 4).length && (
        <Box sx={{ textAlign: "right", marginTop: "20px" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleLoadMore}
            sx={{ fontWeight: "bold" }}
          >
            Read More
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Testimonials;
