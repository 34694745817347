import {
  ALLADDES,
  ADDESBYSTATUS,
  ALLCONTACTUS,
  ALLTESTDRIVE,
  SETCARID,
  ALLOLDCAR,
  VIEWFETURESBYID,
  VIEWOLDCARBYID,
  VIEWOVERVIEWBYID,
  VIEWSPECIFICATIONBYID,
  VIEW_CONTACT_US_LENGTH,
  VIEW_TESTDRIVE_LENGTH,
  OLDCARLENGTH,
  ALLSERVICE,
  ALLVEHICLEENQ,
  ALLENQUIRY,
  ALLREVIEWS,
  ALLQUATATION,
} from "./constant";
const initial_state = {
  all_addeslength: 0,
  all_addes: [],
  addesByStatus: [],
  all_contactlength: 0,
  all_contactUs: [],
  allTestDrive: [],
  allTestDriveLength: 0,
  oldCarId: "",
  allOldCar: [],
  allOldCarLength: 0,
  viewSpecificationById: {},
  viewoldCarById: {},
  viewOverviewById: {},
  viewFetureById: {},
  viewContactUsLength: {},
  viewTestdriveLength: {},
  oldcarlength: 0,
  all_service: [],
  all_servicelength: 0,
  all_vehicleEnq: [],
  all_vehicleEnqlength: 0,
  all_enquiry: [],
  all_enquirylength: 0,
  all_reviews: [],
  all_reviewslength: 0,
  all_Quatation: [],
  all_Quatationlength: 0,
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case ALLADDES:
      return (state = {
        ...state,
        all_addes: action.payload,
        all_addeslength: action.count,
      });
    case ALLVEHICLEENQ:
      return (state = {
        ...state,
        all_vehicleEnq: action.payload,
        all_vehicleEnqlength: action.count,
      });
    case ALLENQUIRY:
      return (state = {
        ...state,
        all_enquiry: action.payload,
        all_enquirylengt: action.count,
      });
    case ALLQUATATION:
      return (state = {
        ...state,
        all_Quatation: action.payload,
        all_Quatationlength: action.count,
      });
    case ALLREVIEWS:
      return (state = {
        ...state,
        all_reviews: action.payload,
        all_reviewslength: action.count,
      });
    case ALLCONTACTUS:
      return (state = {
        ...state,
        all_contactUs: action.payload,
        all_contactlength: action.count,
      });
    case ALLSERVICE:
      return (state = {
        ...state,
        all_service: action.payload,
        all_servicelength: action.count,
      });
    case ALLTESTDRIVE:
      return (state = {
        ...state,
        allTestDrive: action.payload,
        allTestDriveLength: action.count,
      });
    case ALLOLDCAR:
      return (state = {
        ...state,
        allOldCar: action.payload,
        allOldCarLength: action.count,
      });

    case ADDESBYSTATUS:
      return (state = { ...state, addesByStatus: action.payload });
    case SETCARID:
      return (state = { ...state, oldCarId: action.payload });

    case VIEWSPECIFICATIONBYID:
      return (state = { ...state, viewSpecificationById: action.payload });
    case VIEWOVERVIEWBYID:
      return (state = { ...state, viewOverviewById: action.payload });

    case VIEWOLDCARBYID:
      return (state = { ...state, viewoldCarById: action.payload });
    case VIEWFETURESBYID:
      return (state = { ...state, viewFetureById: action.payload });
    case VIEW_CONTACT_US_LENGTH:
      return (state = { ...state, viewContactUsLength: action.payload });
    case VIEW_TESTDRIVE_LENGTH:
      return (state = { ...state, viewTestdriveLength: action.payload });
    case OLDCARLENGTH:
      return (state = { ...state, oldcarlength: action.payload });
    default:
      return state;
  }
}
