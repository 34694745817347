import { React, useState } from "react";
import {
  Card,
  CardContent,
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import "./addoldcar.css"
import { useNavigate } from "react-router-dom";

export default function CarInfoCards(props) {
  const [image, setImage] = useState([]);
  const [brand, setBrand] = useState("");
  const [carName, setCarName] = useState("");
  const [fuelType, setFuelType] = useState("");
  const [carType, setCarType] = useState("");
  const [price, setPrice] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState("");
  const [kilometer, setKilometer] = useState("");
  const navigate = useNavigate();
  const handleImageChange = (e) => {
    const selectedImages = Array.from(e.target.files);
    setImage(selectedImages);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const imagesData = image.map((image, index) => ({
      [`image${index + 1}`]: image,
    }));
    props.addOldCar(
      props.login.token,
      props.login.user_id,
      brand,
      carName,
      fuelType,
      carType,
      price,
      discountedPrice,
      kilometer,
      imagesData
    );

    navigate("/overview");
  };

  return (
    <Box sx={{ padding: "20px" }}>
      <Grid
        container
        spacing={1}
        justifyContent="center"
        sx={{ marginTop: "70px" }}
      >
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  variant="h6"
                  sx={{
                    marginBottom: 2,
                    fontWeight: "bold",
                    color: "#cf2230",
                    fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                    fontSize: { md: "22px", sm: "20px", xs: "17px" },
                  }}
                >
                  BASIC INFORMATION
                </Typography>
              </Box>
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    marginBottom: 2,
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    component="label"
                    sx={{ marginTop: 1, width: "90%" }}
                  >
                    Click to Upload Images
                    <input
                      type="file"
                      hidden
                      multiple 
                      onChange={handleImageChange}
                      required
                    />
                  </Button>
                </Box>

                {/* Preview selected images */}
                <Box
                  sx={{
                    marginBottom: 2,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {image.map((image, index) => (
                    <Box key={index} sx={{ margin: 1 }}>
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`Selected ${index + 1}`}
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                  ))}
                </Box>

                <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      sx={{ marginBottom: 2 }}
                      required
                    >
                      <InputLabel id="fuel-type-label">Brand Name</InputLabel>
                      <Select
                        labelId="brand-name-label"
                        label="Brand Name"
                        name="brand"
                        value={brand}
                        onChange={(e) => setBrand(e.target.value)}
                        required
                      >
                        <MenuItem value="Maruti">Maruti</MenuItem>
                        <MenuItem value="Hyundai">Hyundai</MenuItem>
                        <MenuItem value="Tata">Tata</MenuItem>
                        <MenuItem value="Honda">Honda</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Car Name"
                      variant="outlined"
                      fullWidth
                      name="carName"
                      value={carName}
                      onChange={(e) => setCarName(e.target.value)}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      sx={{ marginBottom: 2 }}
                      required
                    >
                      <InputLabel id="fuel-type-label">Fuel Type</InputLabel>
                      <Select
                        labelId="fuel-type-label"
                        label="Fuel Type"
                        name="fuelType"
                        value={fuelType}
                        onChange={(e) => setFuelType(e.target.value)}
                        required
                      >
                        <MenuItem value="Petrol">Petrol</MenuItem>
                        <MenuItem value="Diesel">Diesel</MenuItem>
                        <MenuItem value="CNG">CNG</MenuItem>
                        <MenuItem value="LPG">LPG</MenuItem>
                        <MenuItem value="Electric">Electric</MenuItem>
                        <MenuItem value="Hybrid">Hybrid</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      sx={{ marginBottom: 2 }}
                      required
                    >
                      <InputLabel id="car-type-label">Car Type</InputLabel>
                      <Select
                        labelId="car-type-label"
                        label="Car Type"
                        name="carType"
                        value={carType}
                        onChange={(e) => setCarType(e.target.value)}
                        required
                      >
                        <MenuItem value="SUV">SUV</MenuItem>
                        <MenuItem value="Sedan">Sedan</MenuItem>
                        <MenuItem value="Hatchback">Hatchback</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Price"
                      variant="outlined"
                      fullWidth
                      name="price"
                      type="number"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Discounted Price"
                      variant="outlined"
                      fullWidth
                      name="discountedPrice"
                      value={discountedPrice}
                      onChange={(e) => setDiscountedPrice(e.target.value)}
                      type="number"
                      required
                      sx={{ marginBottom: 2 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      label="Kilometer"
                      variant="outlined"
                      fullWidth
                      name="kilometer"
                      value={kilometer}
                      onChange={(e) => setKilometer(e.target.value)}
                      type="number"
                      required
                      sx={{ marginBottom: 2 }}
                    />
                  </Grid>
                </Grid>

                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        backgroundColor: "#E03012",
                        fontWeight: "bold",
                        width: "150px",
                        "&:hover": {
                          backgroundColor: "#E03012",
                        },
                      }}
                    >
                      Next
                    </Button>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
