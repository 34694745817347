import React from 'react';
import {
  Typography,
  Button,
  Box,
  Grid,
  Card,
} from '@mui/material';
import Colorsonet from './color'
import Video from '../../../Video/sonet.mp4';
import DriveEtaIcon from '@mui/icons-material/DriveEta'; 
import DescriptionIcon from '@mui/icons-material/Description'; 
import Sonet1 from '../../../Image/Sonet1.jpg';
import Slidingsonet from '../../../Cars/Sonet/Component/Slidingsonet';
import Sonetfullimage from '../../../Image/Sonet/sonetfullimage.jpg'
import Sonetblur from '../../../Image/Sonet/sonet.jpg';
import Contentcarrousel from '../../../Cars/Sonet/Component/Contentcarrousel';
import Footer from '../../../Footer/Component/Footer';


const Sonet = () => {
  return (
    <Box sx={{overflowX:'hidden'}}>
        <Box sx={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
            <video
              playsInline
              autoPlay
              loop
              muted
              preload="auto"
              style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
              }}
          >
              <source src={Video} type="video/mp4" />
          </video>
        </Box>

        <Box sx={{backgroundColor:'#FFFFFF'}}>
          
            {/* Secord card section */}
            <Box sx={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} sm={12} md={6}>
                        <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', boxShadow: 3 ,gap:'15px'}}>
                            <DriveEtaIcon sx={{ fontSize: '40px', color: '#000'  }} />
                            <Typography variant="h6" sx={{ marginTop: '10px',fontWeight:'bold',fontFamily:"KIAbodyLatinbasic_Bold" }}>
                            Register your interest
                            </Typography>
                            <Typography variant="body1" sx={{color:'#848484',fontSize:'13px',fontFamily:"KIAbodyLatinbasic_Regular"}}>Fill in the required details by clicking here, We will contact you as soon as possible.</Typography>
                            <Button
                                variant="contained"
                                sx={{
                                  marginTop: '10px',
                                  backgroundColor: "#E50063",
                                  color: 'white',
                                  borderRadius: '8px',
                                  fontFamily:"KIAbodyLatinbasic_Bold",
                                  fontWeight:800,
                                  height: '50px',
                                  '&:hover':{
                                    backgroundColor: "#E50063",
                                  }
                                }}
                                onClick={() => {
                                  const element = document.getElementById("showinterest");
                                  if (element) {
                                    element.scrollIntoView({ behavior: "smooth" });
                                  }
                                }}
                              >
                                Click Here
                            </Button>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', boxShadow: 3,gap:'15px' }}>
                            <DescriptionIcon sx={{ fontSize: '40px', color: '#000' }} />
                                <Typography variant="h6" sx={{ marginTop: '10px',fontWeight:'bold',fontFamily:"KIAbodyLatinbasic_Bold" }}>
                                Download Brochure
                                </Typography>
                                <Typography variant='body1' sx={{color:'#848484',fontSize:'13px',fontFamily:"KIAbodyLatinbasic_Regular"}}>Click here to download the Brochure and price list of all new Sonet on your device</Typography>
                                <Button variant="contained" sx={{ marginTop: '10px',backgroundColor:"#E50063",color:'white',borderRadius:'8px',height:'50px', fontFamily:"KIAbodyLatinbasic_Bold",
                                fontWeight:'bold',
                                '&:hover':{
                                    backgroundColor: "#E50063",
                                  }  }}>
                                Click To Download
                                </Button>
                        </Card>
                    </Grid>
                </Grid>
            </Box>

            {/* Content Section */}
            <Box sx={{ padding: '40px 20px' }}>
              <Grid
                container
                sx={{
                  display: 'flex',
                  flexDirection: 'column', 
                  alignItems: 'center',
                }}
              >
                {/* Description*/}
                <Grid item xs={12}>
                  <Box sx={{ padding: { xs: '10px',sm:'20px', md: '25px' } }}>
                  <Typography
                      variant="h4"
                      sx={{
                        marginBottom: '20px',
                        fontSize: { xs: '16px', md: '18px' },
                        color:'#959191',
                        paddingLeft:{md:6, xs:2,sm:4},
                        paddingRight:'40px',
                        fontFamily:"KIAbodyLatinbasic_Bold",
                        fontWeight:800,
                      }}
                    >
                        DRIVEN BY INSTINCT.
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{
                        marginBottom: '20px',
                        fontSize: { xs: '24px', md: '30px' },
                         paddingLeft:{md:6, xs:2,sm:4},
                        paddingRight:'40px',
                        fontFamily:"KIAbodyLatinbasic_Bold",
                        fontWeight:'bolder'
                      }}
                    >
                        Introducing, The new Sonet.
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: '16px', md: '20px',sm:'18px' },
                        marginBottom: '20px',
                        paddingLeft:{md:6, xs:2,sm:4},
                        paddingRight:'70px',
                        fontFamily:'KIAbodyLatinbasic_Regular'
                      }}
                    >
                        In a world gone tame, The new Sonet, a subcompact SUV is here to set you free.
                        Chiseled to perfection, raw in power, and loaded with tech, it's built to give you a one-of-a-kind driving experience. So, climb in the new Kia Sonet and let your wild instincts take over.
                    </Typography>
                  </Box>
                </Grid>

                {/* Image Section */}
                <Grid item xs={12}>
                  <Box
                    component="img"
                    src={Sonet1}
                    alt="Car Model"
                    sx={{
                      width: '100%',
                      height: 'auto',
                      maxHeight: { xs: '300px', md: '700px' },
                      objectFit: 'cover',
                    }}
                    />
                </Grid>
              </Grid>
            </Box>

            {/* color sonet */}
            <Colorsonet/>

            {/* Sliding Sonet */}
            <Slidingsonet/>

            {/*  */}
            <Box > 
              <Box 
                sx={{
                  textAlign: 'left',
                  paddingLeft: { xs: '18px',sm:'20px', md: '50px' },
                  marginTop:'30px'
                }}
              >
                <Typography variant="subtitle2" color="#959191" sx={{ fontWeight: 'bold', fontFamily: "KIAbodyLatinbasic_Bold", fontSize: { xs: '18px', md: '22px' } }}>
                Unrivalled ownership experience
                </Typography>

                <Typography 
                  variant="h3" 
                  sx={{ 
                    fontWeight: 'bold', 
                    marginTop:'6px', 
                    fontWeight:'800',
                    fontFamily:'KIAbodyLatinbasic_Bold',
                    fontSize: { xs: '24px', md: '36px' } 
                  }}
                >
                  Get it all beyond price: Premium, Quality & Value.
                </Typography>

                <Typography 
                  variant="body1" 
                  color="text.secondary" 
                  sx={{ 
                    fontSize: { xs: '16px', md: '17px' },
                    width:'80%',
                    fontFamily:'KIAbodyLatinbasic_Regular',
                    marginTop: '16px'
                  }}
                >
                 The new Sonet delivers a wild driving experience that’s reborn to transcend your expectations.
                 Get ready to immerse yourself in the world of unmatched quality, unbeatable value, and unparalleled premium appeal. With a commitment to excellence, the Sonet offers exceptional value-for-money boasting the Lowest Maintenance Cost, Best Total Cost of Ownership and Highest Residual Value in its segment*.
                </Typography>
              </Box>

              <Box 
                sx={{ 
                  position: 'relative', 
                  width: '100%', 
                  height: '500px', 
                  mt: 3, 
                  overflow: 'hidden' 
                }}
              >
                <Box 
                  sx={{
                    backgroundImage: `url(${Sonetblur})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    width: '100%',
                    height: '100%',
                    filter: 'brightness(60%)', 
                  }} 
                />

                <Box 
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '30%',
                    transform: 'translate(-50%, -50%)',
                    color: 'white', 
                    textAlign: 'center',
                    zIndex: 2,
                    padding: { xs: '0 20px', md: '0 40px' }, 
                  }}
                >

                  <Typography 
                    variant="h4" 
                    sx={{ 
                      fontWeight: 'bold', 
                      fontSize: { xs: '24px', md: '36px' },
                      fontFamily: 'KIAbodyLatinbasic_Bold',
                      mb: 1 
                    }}
                  >
                    Lowest Maintenance in the Segment
                  </Typography>

                  <Typography 
                    variant="body1" 
                    sx={{ 
                      fontSize: { xs: '14px', md: '18px' },
                      fontFamily: 'KIAbodyLatinbasic_Regular',
                      mb: 0.5, 
                    }}
                  >
                    Petrol - 16% lower than average
                  </Typography>

                  <Typography 
                    variant="body1" 
                    sx={{ 
                      fontSize: { xs: '14px', md: '18px' },
                      fontFamily: 'KIAbodyLatinbasic_Regular',
                    }}
                  >
                    Diesel - 14% lower than average
                  </Typography>
                </Box>
              </Box>

            </Box>

             {/* Full-sized image */}
             <Box sx={{ width: '100vw', height: {md:'100vh',sm:'60vh',xs:'40vh'}, overflow: 'hidden' }}>
                <Grid
                  container
                  sx={{
                    width: '100%',
                    height: {md:'100vh',sm:'60vh',xs:'40vh'},
                    backgroundImage: `url(${Sonetfullimage})`,
                    backgroundSize: 'cover', 
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      color: '#fff',
                      textShadow: '1px 1px 4px rgba(0, 0, 0, 0.7)',
                    }}
                  >

                  </Grid>
                </Grid>
              </Box>

              {/* Content carrousels */}
              <Contentcarrousel/>


            {/* Footer */}
            <Footer/>
        </Box>
    </Box>

  );
};

export default Sonet;
