import React, { useState } from 'react';
import { Box, Grid, Typography, IconButton, Divider} from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import image1 from '../../../Image/Carnival/image1.jpg';
import image2 from '../../../Image/Carnival/image2.jpg';
import image3 from '../../../Image/Carnival/image3.jpg';
import image4 from '../../../Image/Carnival/image4.jpg';

const images = [
  {
    src: image1,
    descriptions: [
      { title: 'Mile-High', subtitle: 'Safety' },
      { title: 'Dual Panaromic',subtitle:'Curved Display' },
      { title: 'Wide Electric', subtitle:'Dual Sunroof'  },
    ],
  },
  {
    src: image2,
    descriptions: [
      { title: 'Control Center', },
      { title: 'Head-up Display Unit',   },
      { title: '8 Speed Sportsmatic Transmission'},
    ],
  },
  {
    src: image3,
    descriptions: [
      { title: 'Mile-High', subtitle: 'Safety' },
      { title: 'Dual Panaromic',subtitle:'Curved Display' },
      { title: 'Wide Electric', subtitle:'Dual Sunroof'  },
    ],
  }, 
  {
    src: image4,
    descriptions: [
      { title: 'Control Center', },
      { title: 'Head-up Display Unit',   },
      { title: '8 Speed Sportsmatic Transmission'},
    ],
  },
];

const ImageCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <Box sx={{ position: 'relative', width: '100%', height: { xs: '600px', md: '700px' }, textAlign: 'center', overflowX: 'hidden' }}>
      <Grid container sx={{ height: '100%' }}>
        <Grid
          item
          xs={12}
          sx={{
            backgroundImage: `url(${images[currentIndex].src})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              bottom: '10%',
              left: '54%',
              transform: 'translateX(-50%)',
              color: '#fff',
              textShadow: '1px 1px 4px rgba(0, 0, 0, 0.8)',
              width: '80%',
            }}
          >
            <Grid container spacing={2} justifyContent="center">
              {images[currentIndex].descriptions.map((desc, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} sm={3.8} key={index}>
                  <Typography
                    variant="h6"
                    align="center"
                    sx={{
                      fontSize: { xs: '14px', sm: '18px', md: '20px' },
                      fontWeight: 'bolder',
                      fontFamily:"KIAbodyLatinbasic_Bold"
                    }}
                  >
                    {desc.title}
                  </Typography>
                  <Typography
                    variant="h6"
                    align="center"
                    sx={{
                      fontSize: { xs: '14px', sm: '18px', md: '20px' },
                      fontWeight: 'bolder',
                      fontFamily:"KIAbodyLatinbasic_Bold"
                    }}
                  >
                    {desc.subtitle}
                  </Typography>
                </Grid>
                 {index < images[currentIndex].descriptions.length - 1 && (
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ backgroundColor: '#fff', height: '90px',display:{xs:'none',sm:'flex'} }}
                  />
                )}
              </React.Fragment>
              ))}
            </Grid>
          </Box>
        </Grid>

        {/* Left Arrow */}
        <IconButton
          onClick={handlePrev}
          sx={{
            position: 'absolute',
            left: { xs: '10px', md: '40px', lg: '70px' },
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            color: '#fff',
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
          }}
        >
          <ArrowBackIos />
        </IconButton>

        {/* Right Arrow */}
        <IconButton
          onClick={handleNext}
          sx={{
            position: 'absolute',
            right: { xs: '10px', md: '40px', lg: '70px' },
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            color: '#fff',
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
          }}
        >
          <ArrowForwardIos />
        </IconButton>
      </Grid>
    </Box>
  );
};

export default ImageCarousel;
