import React from 'react';
import Slider from 'react-slick';
import { Box, Card, CardContent, CardMedia, Typography, IconButton, Grid } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import evcard15 from '../../../Image/Ev9/evcard15.jpg';
import evcard16 from '../../../Image/Ev9/evcard16.jpg';
import evcard17 from '../../../Image/Ev9/evcard17.jpg';
import evcard18 from '../../../Image/Ev9/evcard18.jpg';
import evcard19 from '../../../Image/Ev9/evcard19.jpg';
import evcard20 from '../../../Image/Ev9/evcard20.jpg';

// Custom next arrow component
const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      sx={{
        position: 'absolute',
        right: '-5px', 
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 2,
        color: 'black',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        '&:hover': { backgroundColor: 'rgba(255, 255, 255, 1)' },
      }}
    >
      <ArrowForwardIosIcon />
    </IconButton>
  );
};

// Custom previous arrow component
const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      sx={{
        position: 'absolute',
        left: '-5px', 
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 2,
        color: 'black',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        '&:hover': { backgroundColor: 'rgba(255, 255, 255, 1)' },
      }}
    >
      <ArrowBackIosIcon />
    </IconButton>
  );
};

// CarouselSection component
const CarouselSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const cards = [
    {
      image: evcard15,
      title: '10 Airbags',
      description:'Designed to protect from every angle, the EV9 ensures peace of mind with 10 strategically placed airbags',
    },
    {
      image: evcard16,
      title: '*ADAS Level2',
      description:'Advanced driving assistance at your fingertips, empowering you with confidence and control on every journey.',
    },
    {
      image: evcard17,
      title: 'Forward Collision Avoidance Assist- Junction Crossing',
      description:'Stay ahead of the unexpected with intelligent collision avoidance that adapts to evry intersection.',
    },
    {
        image:evcard18,
        title:'Blind Spot Collision Avoidance Assist',
        description:"Eyes where you can't seededRandom; advanced sensors ensure safe lane changes with blind spot collision avoidance",
    },
    {
        image:evcard19,
        title:'360-Degree Camera',
        description:"Never miss a detail with the 360-degree camera that surrounds you with an all-seeing eye for ultimate precision.",
    },{
        image:evcard20,
        title:'Blind View Monitor',
        description:'Innovative technology that brings your blind spots into view, enhancing safety with every turn',
    },
  ];

  return (
    <Box
      sx={{
        width: '90%',
        margin: 'auto',
        marginTop: '40px',
        marginBottom: '40px',
        overflow: 'hidden',
      }}
    >
      <Slider {...settings}>
        {cards.map((card, index) => (
          <Grid key={index} item xs={12} md={4} sx={{ padding: '0 15px' }}>
            <Card
              sx={{
                padding: 2,
                boxShadow: 3,
                borderRadius: '8px',
                backgroundColor: '#fff',
                textAlign: 'center',
                height: '100%',
                '&:hover': {
                  boxShadow: 6,
                },
              }}
            >
              <CardMedia
                component="img"
                height="180"
                image={card.image}
                alt={card.title}
                sx={{
                  objectFit: 'cover',
                  borderRadius: '8px 8px 0 0',
                }}
              />
              <CardContent sx={{ padding: '20px', height: '200px' }}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    fontWeight: 'bold',
                    color: '#333',
                    fontSize: '18px',
                    marginBottom: '8px',
                    fontFamily:"KIAbodyLatinbasic_Bold"
                  }}
                >
                  {card.title}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    color: '#666',
                    fontSize: '14px',
                    fontFamily:"KIAbodyLatinbasic_Regular"
                  }}
                >
                  {card.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Slider>
    </Box>
  );
};

export default CarouselSection;
