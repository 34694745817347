import React,{useState}from 'react';
import { Grid, Typography, Box, Link,IconButton } from '@mui/material';
import List from "@mui/material/List";
import logoImage from "../../Image/knlogo.jpg"
import centralLogo from "../../Image/download.jpg";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
const Footer = () => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const cars = [
    { name: "Kia Seltos", link: "/seltos" },
    { name: "Kia Sonet", link: "/sonet" },
    {name:'Kia Carens', link:'/carens'},
    {name:'Kia EV6', link:'/ev6'},
    { name: "Kia Carnival", link: "/carnival" },
    {name:'Kia EV9', link:'/ev9'},
    {name:'Kia Syros', link:'/syros'},

  ];

  return (
    <Box
      sx={{
        backgroundColor: '#000',
        padding: '40px',
        borderTop: '1px solid #ddd',
      }}
    >
         <Box
      sx={{
        marginTop: "20px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingBottom: "40px",
        alignItems: "center",
      }}
    >
      {/* Left-aligned Image */}
      <Link to="/">
        <img
          src={logoImage}
          alt="mg"
          style={{
            width: "80px",
            height: "auto",
          }}
        />
      </Link>

      {/* Right-aligned Image */}
      <Link to="/">
        <img
          src={centralLogo}
          alt="central group"
          style={{
            width: "190px",
            height: "auto",
          }}
        />
      </Link>
    </Box>
     <Grid container spacing={4} justifyContent="center">
  
  <Grid item xs={12} sm={2.2} sx={{ color: 'white' }}>
    <Typography variant="h6" sx={{ color: '#889092', fontWeight: '700',paddingBottom:'15px',fontFamily:"KIAbodyLatinbasic_Bold"}}>
      Menus
    </Typography>
    <Box>
    <div>
      <Link
        href="#"
        color="inherit"
        underline="hover"
        onClick={(e) => {
          e.preventDefault(); // Prevent navigation
          handleToggle();
        }}
        sx={{ display: "flex", mb: 1, cursor: "pointer",alignItems:'center',fontFamily:"KIAbodyLatinbasic_Regular" }}
      >
        Cars {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Link>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {cars.map((car) => (
            <ListItem
              button
              key={car.name}
              component="a"
              href={car.link}
              sx={{ pl: 4 }}
            >
              <ListItemText primary={car.name} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </div>
      <Link href="/preowned-cars" color="inherit" underline="hover" sx={{ display: 'block', mb: 1,fontFamily:"KIAbodyLatinbasic_Regular"}}>
        Pre-owned Cars
      </Link>
      <Link href="/" color="inherit" underline="hover" sx={{ display: 'block', mb: 1,fontFamily:"KIAbodyLatinbasic_Regular" }}>
        Book Now
      </Link>
      <Link href="/" color="inherit" underline="hover" sx={{ display: 'block', mb: 1, fontFamily:"KIAbodyLatinbasic_Regular" }}>
        Service
      </Link>
      <Link href="/contact" color="inherit" underline="hover" sx={{ display: 'block', mb: 1, fontFamily:"KIAbodyLatinbasic_Regular" }}>
        Contact Us
      </Link>
      <Link href="/" color="inherit" underline="hover" sx={{ display: 'block', mb: 1, fontFamily:"KIAbodyLatinbasic_Regular" }}>
        About Us
      </Link>
      <Link href="/" color="inherit" underline="hover" sx={{ display: 'block', mb: 1, fontFamily:"KIAbodyLatinbasic_Regular" }}>
        Career
      </Link>
    </Box>
  </Grid>

  <Grid item xs={12} sm={2.2} sx={{ color: 'white' }}>
    <Typography variant="h6" sx={{ color: '#889092', fontWeight: '700',paddingBottom:'15px',fontFamily:'KIAbodyLatinbasic_Bold' }}>
      Cars
    </Typography>
    <Box sx={{fontFamily:'KIAbodyLatinbasic_Regular'}}>
      <Link href="/seltos" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
        Seltos
      </Link>
      <Link href="/sonet" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
        Sonet
      </Link>
      <Link href="/carens" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
        Carens
      </Link>
      <Link href="/ev6" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
        EV6
      </Link>
      <Link href="/carnival" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
        Carnival
      </Link>
      <Link href="/ev9" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
        EV9
      </Link>
      <Link href="/syros" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
        Syros
      </Link>
    </Box>
  </Grid>

  {/* Forms Section */}
  <Grid item xs={12} sm={2.2} sx={{ color: 'white' }}>
    <Typography variant="h6" sx={{ color: '#889092', fontWeight: '700',paddingBottom:'15px',fontFamily:"KIAbodyLatinbasic_Bold" }}>
      Forms
    </Typography>
    <Box sx={{fontFamily:'KIAbodyLatinbasic_Regular'}}>
      <Link href="/enquiry" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
        Enquiry
      </Link>
      <Link href="/getQuotation" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
        Get Quotation
      </Link>
      <Link href="/test-drive" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
        Test Drive
      </Link>
      <Link href="/service" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
        Service Appointment Booking
      </Link>
      <Link href="/job" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
        Apply for job
      </Link>
    </Box>
  </Grid>

  <Grid item xs={12} sm={2.4} sx={{ color: 'white' }}>
    <Typography variant="h6" sx={{ color: '#889092', fontWeight: '700',paddingBottom:'15px',fontFamily:"KIAbodyLatinbasic_Bold" }}>
      Service
    </Typography>
    <Box sx={{fontFamily:'KIAbodyLatinbasic_Regular'}}>
    <Link href="/service" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
       Book Service Appointment
      </Link>
      <Link href="/preowned-cars" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
        Pre-owned Cars
      </Link>
      <Link href="/service" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
        Service and Maintainance
      </Link>
      <Link href="/service" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
          Service Offerings
      </Link>
      <Link href="/" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
        Customer Awareness
      </Link>
      <Link href="/" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
        EV Owners
      </Link>
    </Box>
  </Grid>

  <Grid item xs={12} sm={2.5} sx={{ color: 'white' }}>
    <Typography variant="h6" sx={{ color: '#889092', fontWeight: '700',paddingBottom:'15px',fontFamily:"KIAbodyLatinbasic_Bold" }}>
      Legal
    </Typography>
    <Box sx={{fontFamily:"KIAbodyLatinbasic_Regular"}}>
      <Link href="/privacy" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
        Privacy Policy
      </Link>
      <Link href="/" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
        Terms & Conditions
      </Link>
      <Link href="/contact" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
        Contact Us
      </Link>
    </Box>
  </Grid>
</Grid>


      <Box
        sx={{
          marginTop: '20px',
          display: 'flex',
          flexDirection:{md:'row', sm:'',xs:'column'},
          justifyContent: 'space-between',
          padding: '20px',
          alignItems:'center',
          gap:'20px',
          borderTop: '1px solid #ccc',
        }}
      >
        <Typography variant="body2" color="#fff" sx={{whiteSpace:{md:"nowrap",xs:'wrap'}, fontFamily:"KIAbodyLatinbasic_Regular",}}>
        For any assistance contact us at :09237386213, Email:customercare@centeralkia.in
        </Typography>
        <Typography variant="body2" color="#fff"  sx={{whiteSpace: "nowrap", fontFamily:"KIAbodyLatinbasic_Regular",}}>
        Designed & Developed by: J Nine Infotech
        </Typography>
        <Box
                   sx={{
                      backgroundColor: '#000000',
                      color: 'white',
                      textAlign: 'center',
                      display: 'flex',
                      flexDirection: 'row', 
                      alignItems: 'center',
                      justifyContent: 'end', 
                      width: '100%',
                      p: 2, 
                      gap:'10px'
                    }}
                  > 
                    <IconButton 
                        sx={{ color: '#fff', border: '1px solid grey' }} 
                        component="a" 
                        href="http://www.facebook.com/centralkiabbsr" 
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                      <FacebookIcon />
                    </IconButton>
                    <IconButton 
                        sx={{ color: '#fff', border: '1px solid grey' }} 
                        component="a" 
                        href="http://www.instagram.com/centralkiabbsr" 
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                      <InstagramIcon />
                    </IconButton>
                    <IconButton 
                        sx={{ color: '#fff', border: '1px solid grey' }} 
                        component="a" 
                        href="http://www.linkedin.com/centralkiabbsr" 
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                      <LinkedInIcon />
                    </IconButton>
                    <IconButton 
                        sx={{ color: '#fff', border: '1px solid grey' }} 
                        component="a" 
                        href="http://www.youtube.com/@central_group" 
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                      <YouTubeIcon />
                    </IconButton>
                    
                </Box>
      </Box>
    </Box>
  );
};

export default Footer;
