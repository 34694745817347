// Import necessary dependencies
import React, { useState,useEffect } from "react";
import {
  Typography,
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import "./Pricing.css";
import SeltosImg from "../../Image/Seltos/seltos1.jpg";
import SonetImg from "../../Image/Sonet/image1.jpg";
import CarensImg from "../../Image/Carens/carensfullimage.jpg";
import EV6Img from "../../Image/Ev6/evcard.jpg";
import CarnivalImg from "../../Image/Carnival/image2.jpg";
import EV9Img from "../../Image/Ev9/full5.jpg";
// import Snackbar from "../../../Common/snackbar/components/snackbar";
// import LoaderCon from "../../../Common/loader/containers/loader_cont";
const cars = [
  {
    id: 1,
    name: "KIA SELTOS",
    image: SeltosImg,
    des: "Price starts at ₹10.89 Lakhs",
    route: "/seltos",
  },
  {
    id: 2,
    name: "KIA SONET",
    image: SonetImg,
    des: "Price starts at ₹8 Lakhs",
    route: "/sonet",
  },
  {
    id: 3,
    name: "KIA CARENS",
    image: CarensImg,
    des: "Price starts at ₹10.52 Lakhs",
    route: "/carens",
  },
  {
    id: 4,
    name: "KIA EV6",
    image: EV6Img,
    des: "Price starts at ₹60.97 Lakhs",
    route: "/ev6",
  },
  {
    id: 5,
    name: "KIA CARNIVAL",
    image: CarnivalImg,
    des: "Price is ₹63.90 Lakhs",
    route: "/carnival",
  },
  {
    id: 6,
    name: "KIA EV9",
    image: EV9Img,
    des: "Price starts at ₹1.30 Cr",
    route: "/ev9",
  },
];

const PricePage = (props) => {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCar, setSelectedCar] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [city, setCity] = useState("");
  const [dealership, setDealership] = useState("");
  const [pincode, setPincode] = useState("");

  const handleCardClick = (car) => {
    setSelectedCar(car);
    setOpenDialog(true);
  };
  useEffect(() => {
    console.log(props.snackbar.response_received);
  }, []);
  
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedCar(null);
    setFirstName("");
    setLastName("");
    setEmail("");
    setMobile("");
    setCity("");
    setDealership("");
    setPincode("");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const carName = selectedCar.name;
    props.getQuotationsRequest(
      carName,
      firstName,
      lastName,
      email,
      mobile,
      city,
      dealership,
      pincode
    );
    handleCloseDialog();

  };

  return (
    <div
      style={{
        marginTop: "30px",
        backgroundColor: "black",
        overflowX: "hidden",
      }}
    >
      <div id="banner-image-price"></div>
      {/* Car Price List */}
      <Container>
        <Grid container spacing={3}>
          {cars.map((car) => (
            <Grid item xs={12} sm={6} md={4} key={car.id}>
              <Card
                sx={{
                  marginBottom: "20px",
                  marginTop: "20px",
                  cursor: "pointer",
                  transition: "transform 0.3s ease-in-out",
                  "&:hover": { transform: "scale(1.05)" },
                }}
                onClick={() => handleCardClick(car)}
              >
                <CardMedia
                  component="img"
                  height="200"
                  image={car.image}
                  alt={car.name}
                />
                <CardContent>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bolder",
                      textAlign: "center",
                      fontFamily: "KIAbodyLatinbasic_Bold",
                    }}
                  >
                    {car.name}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bolder",
                      textAlign: "center",
                      fontFamily: "KIAbodyLatinbasic_Bold",
                    }}
                  >
                    {car.des}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Dialog for Form */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          id="dialog-title"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              textAlign: "center",
              flex: 1,
              color: "#D62E31",
              fontSize: { md: "30px", xs: "15px", sm: "25px" },
              fontFamily: "KIAbodyLatinbasic, Arial, sans-serif",
              fontWeight: "bolder",
            }}
          >
            SHOW INTEREST - {selectedCar?.name}
          </Typography>
          <IconButton onClick={handleCloseDialog} sx={{ color: "grey" }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              {/* Grid for two fields per row */}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="First Name"
                    variant="outlined"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    variant="outlined"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Mobile Number"
                    variant="outlined"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Email ID"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined" required>
                    <InputLabel>Select City</InputLabel>
                    <Select
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    >
                      <MenuItem value="angul">Angul</MenuItem>
                      <MenuItem value="berhampur">Berhampur</MenuItem>
                      <MenuItem value="bhubaneswar">Bhubaneswar</MenuItem>
                      <MenuItem value="cuttack">Cuttack</MenuItem>
                      <MenuItem value="jajpur">Jajpur</MenuItem>
                      <MenuItem value="jharsuguda">Jharsuguda</MenuItem>
                      <MenuItem value="sambalpur">Sambalpur</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Pincode"
                    variant="outlined"
                    value={pincode}
                    onChange={(e) => setPincode(e.target.value)}
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined" required>
                    <InputLabel>Select Dealership</InputLabel>
                    <Select
                      value={dealership}
                      onChange={(e) => setDealership(e.target.value)}
                    >
                      <MenuItem value="angul">Angul</MenuItem>
                      <MenuItem value="berhampur">Berhampur</MenuItem>
                      <MenuItem value="bbsr">Bhubaneswar</MenuItem>
                      <MenuItem value="ctc">Cuttack</MenuItem>
                      <MenuItem value="jajpur">Jajpur</MenuItem>
                      <MenuItem value="jharsuguda">Jharsuguda</MenuItem>
                      <MenuItem value="sambalpur">Sambalpur</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    backgroundColor: "#D23132",
                    fontWeight: "bolder",
                    width: "40%",
                    "&:hover": {
                      backgroundColor: "#D23132",
                    },
                  }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
      {/* <LoaderCon />
      <Snackbar
        open={props.snackbar.response_received}
        onClose={props.close_snack_bar}
        message={props.snackbar.message}
        // anchorOrigin={{ vertical: "top", horizontal: "center" }}
      /> */}
    </div>
  );
};

export default PricePage;
