import React, { useEffect, useRef, useState } from 'react';
import './AnimationSection.css';
import ani1 from '../../../Image/Ev6/ani1.jpg';
import ani2 from '../../../Image/Ev6/ani2.jpg';
import ani3 from '../../../Image/Ev6/ani3.jpg'
const AnimationSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target); 
          }
        });
      },
      {
        threshold: 0.1, 
      }
    );

    if (contentRef.current) {
      observer.observe(contentRef.current);
    }

    return () => {
      if (contentRef.current) {
        observer.unobserve(contentRef.current);
      }
    };
  }, []);

  return (
    <>
    <div className="animation-container">
      <div className="image-section">
        <img src={ani1} alt="Left content" style={{ width: '100%' }} />
      </div>
      <div ref={contentRef} className={`center-content ${isVisible ? 'visible' : ''}`}>
        <h2 style={{fontFamily:"KIAbodyLatinbasic_Bold"}}>Space Innovation</h2>
        <h4 style={{fontFamily:"KIAbodyLatinbasic_Bold"}}>Driver and Passenger Relaxation Seats</h4>
        <p style={{fontFamily:"KIAbodyLatinbasic_Regular"}}>
          Front seats recline at the press of a button to a zero-gravity position to give maximum relaxation to the occupant.
        </p>
      </div>
      <div className="image-section">
        <img src={ani2} alt="Right content" style={{ width: '100%' }} />
      </div>
    </div>

        <div className="animation-container">
        
        <div ref={contentRef} className={`center-content ${isVisible ? 'visible' : ''}`}>
        <h2 style={{fontFamily:"KIAbodyLatinbasic_Bold"}}>31.24 cm (12.3") Curved Touchscreen Navigation</h2>
        <p style={{fontFamily:"KIAbodyLatinbasic_Regular"}}>
        The integrated curved display is not only beautiful but also offers a truly immersive experience along with maximum visibility and intuitiveness
        </p>
        </div>

        <div className="image-section">
        <img src={ani3} alt="Left content" style={{ width: '100%' }} />
        </div>

        <div ref={contentRef} className={`center-content ${isVisible ? 'visible' : ''}`}>
        <h2 style={{fontFamily:"KIAbodyLatinbasic_Bold"}}>Augmented Reality Head-Up Display</h2>
        <p style={{fontFamily:"KIAbodyLatinbasic_Regular"}}>
        Key information such as speed, and lane safety indication, is projected in an intuitive manner on the windscreen
        </p>
        </div>

        </div>
    </>
  );
};

export default AnimationSection;
