import React, { Component } from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  FormControl,
  OutlinedInput,
  Button,
} from "@mui/material";
import { Eye, EyeOff } from "lucide-react";
import centralLogo from "../../Image/download.jpg";
import logoImage from "../../Image/knlogo.jpg";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate

// Create a functional wrapper to use hooks
const ProfilePage = (props) => {
  const navigate = useNavigate(); // Use the navigate hook
  const { login, passwordChange } = props;

  class Profile extends Component {
    constructor(props) {
      super(props);
      this.state = {
        email: "",
        name: "",
        currentPassword: "",
        newPassword: "",
        showPassword: false,
      };
    }

    handleClickShowPassword = () => {
      this.setState((prevState) => ({
        showPassword: !prevState.showPassword,
      }));
    };

    handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    render() {
      const { showPassword, email, name, currentPassword, newPassword } = this.state;

      return (
        <Grid>
          <Dialog
            open={true}
            maxWidth="md"
            fullWidth
            PaperProps={{
              style: {
                backgroundColor: "#000",
                color: "#fff",
                borderRadius: "10px",
                padding: "20px",
              },
            }}
          >
            <DialogContent>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  alignItems="center"
                  style={{ textAlign: "center", marginBottom: "20px" }}
                >
                  <img src={centralLogo} alt="Logo" className="logoImage" />
                </Grid>
                <Grid item xs={12} sm={6} className="imageContainer imageOverlay">
                  <img src={logoImage} alt="Profile" className="responsiveImage" />
                </Grid>
                <Grid item xs={12} sm={6} alignContent={"center"}>
                  <Typography variant="h4" textAlign={"center"} style={{ color: "#fff" }}>
                    Profile
                  </Typography>
                  <Grid container mt={4}>
                    <Grid item xs={12}>
                      <Typography className="text" style={{ color: "#ccc" }}>
                        Name{" "}
                        <span style={{ color: "red", fontSize: "20px" }}>*</span>
                      </Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={login.name}
                        InputProps={{
                          style: { backgroundColor: "#333", color: "#fff" },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container mt={2}>
                    <Grid item xs={12}>
                      <Typography className="text" style={{ color: "#ccc" }}>
                        Email{" "}
                        <span style={{ color: "red", fontSize: "20px" }}>*</span>
                      </Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={login.email}
                        InputProps={{
                          style: { backgroundColor: "#333", color: "#fff" },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container mt={2}>
                    <Grid item xs={12}>
                      <Typography className="text" style={{ color: "#ccc" }}>
                        Current Password{" "}
                        <span style={{ color: "red", fontSize: "20px" }}>*</span>
                      </Typography>
                      <FormControl fullWidth>
                        <OutlinedInput
                          type={showPassword ? "text" : "password"}
                          size="small"
                          value={currentPassword}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={this.handleClickShowPassword}
                                onMouseDown={this.handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <EyeOff color="white" />
                                ) : (
                                  <Eye color="white" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          onChange={(e) =>
                            this.setState({ currentPassword: e.target.value })
                          }
                          style={{ backgroundColor: "#333", color: "#fff" }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container mt={2}>
                    <Grid item xs={12}>
                      <Typography className="text" style={{ color: "#ccc" }}>
                        New Password{" "}
                        <span style={{ color: "red", fontSize: "20px" }}>*</span>
                      </Typography>
                      <FormControl fullWidth>
                        <OutlinedInput
                          type={showPassword ? "text" : "password"}
                          size="small"
                          value={newPassword}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={this.handleClickShowPassword}
                                onMouseDown={this.handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <EyeOff color="white" />
                                ) : (
                                  <Eye color="white" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          onChange={(e) =>
                            this.setState({ newPassword: e.target.value })
                          }
                          style={{ backgroundColor: "#333", color: "#fff" }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <center>
                    <Button
                      variant="contained"
                      sx={{
                        marginTop: 5,
                        backgroundColor: "#ff0000",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#d00000",
                        },
                      }}
                      onClick={() => {
                        // Handle profile update logic
                        console.log("Profile updated:", {
                          name,
                          email,
                          currentPassword,
                          newPassword,
                        });
                        passwordChange(
                          login.token,
                          login.user_id,
                          currentPassword,
                          newPassword
                        );
                        this.setState({
                          name: "",
                          email: "",
                          currentPassword: "",
                          newPassword: "",
                        });
                        navigate("/admin"); // Navigate back to the client page
                      }}
                    >
                      Update Profile
                    </Button>
                  </center>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </Grid>
      );
    }
  }

  return <Profile {...props} />;
};

export default ProfilePage;
