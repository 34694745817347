import React, { useEffect,useState } from 'react';
import { Box, Typography, Grid, IconButton, Divider, Button } from '@mui/material';
import cam from '../../../Image/Sonet/cam.jpg';
import gravity2 from '../../../Image/Sonet/gravity2.jpg';
import gravity3 from '../../../Image/Sonet/gravity3.jpg';
import gravity4 from '../../../Image/Sonet/gravity4.jpg';
import gravity5 from '../../../Image/Sonet/gravity5.jpg';
import x1 from '../../../Image/Sonet/x1.jpg';
import x2 from '../../../Image/Sonet/x2.jpg';
import x3 from '../../../Image/Sonet/x3.png';
import x4 from '../../../Image/Sonet/x4.jpg';
import ShowInterest from '../../../show interest/Container/showinterestCon';
import ext1 from '../../../Video/Sonet/ext1.mp4'
import ext2 from '../../../Image/Sonet/ext2.jpg'
import ext3 from '../../../Video/Sonet/ext3.mp4'
import ext4 from '../../../Image/Sonet/ext4.jpg';
import ext5 from '../../../Video/Sonet/ext5.mp4';
import ext6 from '../../../Image/Sonet/ext6.jpg';
import ext7 from '../../../Image/Sonet/ext7.jpg';
import ext8 from '../../../Image/Sonet/ext8.jpg';
import int1 from '../../../Image/Sonet/int1.jpg';
import int2 from '../../../Image/Sonet/int2.jpg';
import int3 from '../../../Image/Sonet/int3.jpg';
import int4 from '../../../Image/Sonet/int4.jpg';
import int5 from '../../../Image/Sonet/int5.jpg';
import int6 from '../../../Image/Sonet/int6.jpg';
import int7 from '../../../Video/Sonet/int7.mp4';
import int8 from '../../../Video/Sonet/int8.mp4';
import per1 from '../../../Image/Sonet/per1.jpg';
import per2 from '../../../Image/Sonet/per2.jpg';
import per3 from '../../../Image/Sonet/per3.jpg'
import trust1 from '../../../Image/Sonet/trust1.jpg';
import trust2 from '../../../Image/Sonet/trust2.jpg';
import trust3 from '../../../Image/Sonet/trust3.jpg';
import trust4 from '../../../Image/Sonet/trust4.jpg';
import trust5 from '../../../Image/Sonet/trust5.jpg';
import trust6 from '../../../Image/Sonet/trust6.jpg';
import trust7 from '../../../Image/Sonet/trust7.jpg';
import trust8 from '../../../Video/Sonet/trust8.mp4';
import car1 from '../../../Image/Sonet/car1.jpg';
import car2 from '../../../Image/Sonet/car2.jpg'
import car3 from '../../../Image/Sonet/car3.jpg';
import car4 from '../../../Image/Sonet/car4.jpg'
import adas1 from '../../../Video/Sonet/adas1.mp4'
import adas2 from '../../../Image/Sonet/adas2.jpg';
import adas3 from '../../../Image/Sonet/adas3.jpg';
import adas4 from '../../../Image/Sonet/adas4.jpg';
import adas5 from '../../../Video/Sonet/adas5.mp4';
import adas6 from '../../../Image/Sonet/adas6.jpg';
import adas7 from '../../../Image/Sonet/adas7.jpg';
import adas8 from '../../../Image/Sonet/adas8.jpg';
import adas9 from '../../../Image/Sonet/adas9.jpg';
import adas10 from '../../../Image/Sonet/adas10.jpg';
import Cardcarrousel from '../../../Cars/Sonet/Component/cardcarrousel'
import BookIcon from '@mui/icons-material/LibraryBooks';
import CallIcon from '@mui/icons-material/Call';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Sonetbrochure from '../../../Brochure/Sonet/Sonet.pdf'
import Faq from '../../../Cars/Sonet/Component/faqs';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const contents = [
  {
    title: 'Dash Cam with Dual Camera',
    description: 'Capture every untamed moment on the road with eyes on the wild at all times.',
    image: cam,
  },
  {
    title: 'Wireless Charger',
    description: 'Stay powered up on the go with the freedom to roam untethered.',
    image: gravity2,
  },
  {
    title: 'R16 40.56cm (16”) Sporty Crystal Cut Alloy Wheels',
    description: 'Ride wild with wheels that turn heads and carve paths through the unknown.',
    image: gravity3,
  },
  {
    title: 'Indigo Pera Seats',
    description: 'Sink into adventure with luxury that carries an untamed edge.',
    image: gravity4,
  },
  {
    title: 'Gravity Emblem',
    description: 'Badge your ride with a style that defies gravity and embraces the wild spirit.',
    image: gravity5,
  },
];

  const contents2 = [
    {
      title: 'R16 40.56cm (16”) Sporty Crystal Cut Alloy Wheels',
      description: 'A distinctive design, featuring untamed crystal-cut patterns on a sleek black surface, is the perfect blend of sporty look with performance.',
      image: x1,
    },
    {
      title: 'Kia Signature Tiger Nose Grille with Knurled Xclusive Black High Gloss Surround',
      description: 'Bring out the wild in you with the fierce Kia Signature Tiger Nose grille, while the knurled glossy surround adds a touch of wild charm.',
      image: x2,
    },
    {
      title: 'All Door Power Window One Touch Auto Up/Down with Safety',
      description: 'Effortlessly control all windows with a single touch while ensuring safety with auto-stop, in case of obstructions.',
      image: x3,
    },
    {
      title: 'Full Digital Cluster with 26.04cm (10.25") Color LCD MID',
      description: 'Embrace a futuristic instrument cluster that features a comprehensive and sleek interface to make every journey convenient and immersive.',
      image: x4,
    },
   
  ];

  const contents3 = [
    {
      title: 'Electric Sunroof',
      description: 'Elevate your drive with a sunroof, turning every road trip into a scenic adventure.',
      video: ext1,
    },
    {
      title: 'R-18 (46.20 cm) Crystal-Cut Glossy Black Alloy Wheels',
      description: 'The assertive crystal-cut alloy wheels of the new Seltos elevate its impressive appearance to newer heights.',
      image: ext2,
    },
    {
      title: 'Crown Jewel LED Headlamps and Star Map LED DRLs',
      description: 'Highlight the all-new bold front and give it a dazzling road presence.',
      video: ext3,
    },
    {
      title: 'R16 - 40.56 cm (16”) Sporty Crystal Cut Alloy Wheels',
      description: 'Give your wheels a striking spin as you prowl the streets.​',
      image: ext4,
    },
    {
      title:'Star Map LED Connected Tail Lamps',
      description:'For a distinctive look that seamlessly blends style and innovation. ',
      video:ext5,
    },
    {
      title:'Signature Tiger Nose Grille',
      description:'Takes the untamed style quotient to stunning new heights.',
      image:ext6,
    },
    {
      title:"Sleek LED Fog Lamps",
      description:"Enhance the overall sleek appeal.​",
      image:ext7,
    },
    {
      title:"Sporty Aerodynamic Rear Skid Plates with Dark Metallic Accents",
      description:"Adds a sporty edge to the car’s unbridled design appeal.",
      image:ext8,
    }
   
  ];

  const contents4 = [
    {
      title: 'Center Fascia AC Vents',
      description: 'Ensure optimal airflow for driver and passenger, with the center console placed within easy reach of both.',
      image: int1,
    },
    {
      title: 'Front Ventilated Seats',
      description: "Make every drive a breeze in hot and humid conditions. ​​",
      image: int2,
    },
    {
      title: 'Smart Pure Air Purifier with Virus and Bacteria Protection^',
      description: "Ensures you breathe the cleanest air inside.",
      image: int3,
    },
    {
      title: 'Bose Premium 7- Speakers System',
      description: "Elevate your drive with the perfect beats, making each moment a musical adventure.",
      image: int4,
    },
    {
      title:'4-Way Power Driver’s Seat',
      description:'Helps you get the perfect driving stance and maximum comfort.  ',
      image:int5,
    },
    {
      title:'26.03 cm (10.25’’) HD Touchscreen Navigation',
      description:'Enables you to stay in command when it comes to finding your way effortlessly.',
      image:int6,
    },
    {
      title:'LED Ambient Sound Mood Lights',
      description:"Creates the perfect atmosphere for every drive.",
      video:int7,
    },
    {
      title:'Full Digital Cluster with 26.04 cm (10.25’’) Color LCD MID',
      description:"Provides real-time information and vehicle status updates.",
      video:int8,
    }
   
  ];

  const contents5 = [
    {
      title:'Powerful Smartstream 1.0 T – GDi Petrol Engine',
      description: "Dominate the urban landscape with the new Sonet and its Smartstream 1.0 T-GDi Petrol Engine, offering 88 kW power and 172 Nm torque.",
      image: per1,
    },
    {
      title: 'Refined 1.5 CRDi VGT Diesel Engine',
      description: "The new Sonet features a redefined 1.5 CRDi VGT Diesel Engine delivering 85 kW and 250 Nm torque, accompanied by a first-in-segment 6 Speed Advance AT transmission.",
      image: per2,
    },
    {
      title: 'Advanced Smartstream 1.2 Petrol Engine',
      description: "Experience the thrill with the advanced Smartstream 1.2 Petrol Engine of the new Sonet, providing 61 kW power and 115 Nm torque.",
      image: per3,
    },
   
  ];

  const contents6 = [
    {
      title: '6 Airbags as Standard',
      description:"Allows the car to become your shield, protecting you from every corner.",
      image: trust1,
    },
    {
      title: 'Electronic Stability Control (ESC), Hill Assist Control (HAC) & Vehicle Stability Management (VSM)',
      description:"Comes as a part of the standard safety package, making your journey safe & reliable.",
      image: trust2,
    },
    {
      title: 'Blind View Monitor (BVM)',
      description:"Eliminates any blind spots and surprises, giving you a more holistic view.",
      image: trust3,
    },
    {
      title:"Front and Rear Parking Sensors",
      description:"Make parking and safe maneuvering at tricky spots simple.",
      image:trust4,
    },
    {
      title:"ESS (Emergency Stop Signal)",
      description:"Alerts the driver behind by blinking the brake lights whilst braking sharply and severely.​",
      image:trust5,
    },{
      title:"All Seat 3 point seatbelt with reminder",
      description:"Reminds you to strap in whether you’re in the front or back.",
      image:trust6,
    },{
      title:"All Wheel Disc Brake",
      description:"Enhances braking performance, so you are always in control of any unforeseen event.",
      image:trust7,
    },{
      title:"360 Degree Camera",
      description:"Keeps you aware of surroundings, at all times.",
      video:trust8,
    },

  ];

  const contents7 = [
    {
      title: 'Front Collision Warning (FCW)',
      description: "Alerts you to any unexpected obstacles ahead.",
      video: adas1,
    },
    {
      title: 'Front Collision-Avoidance Assist - Cycle (FCA – Cyclist)',
      description: 'Warns you of imminent collision with vehicles and applies brakes.',
      image: adas2,
    },
    {
      title: 'Front Collision-Avoidance Assist - Cyclist (FCA – Cyclist)',
      description: "Warns of imminent collision with cyclist and applies brakes.",
      image: adas3,
    },
    {
      title: 'Front Collision Avoidance Assist – Pedestrian (FCA - Pedestrian)',
      description: "Warns you of imminent collision with pedestrians and applies brakes.",
      image: adas4,
    },
    {
      title: 'Lane Keep Assist (LKA)',
      description: "Counter steers to prevent vehicle from departing from its lane.",
      video: adas5,
    },
    {
      title:'Lane Departure Warning (LDW)',
      description:"Alerts the driver whenever the car is going out of its lane on either side without turn indicator input.",
      image:adas6,
    },
    {
      title:'Lane Following Assist (LFA)',
      description:"Detects lanes and steers to keep you in the center.",
      image:adas7,
    },
    {
      title:'Leading Vehicle Departure Alert (LVDA)',
      description:"Follows movements of the vehicle in front.",
      image:adas8,
    },
    {
      title:'High Beam Assist (HBA)',
      description:"Adapt your car’s headlights to oncoming traffic.",
      image:adas9,
    },
    {
      title:'Driver Attention Warning (DAW)',
      description:"Warns you of any lapse in attention.",
      image:adas10,
    }

  ];

  const contents8 = [
    {
      title: 'OTA Map & System Update',
      description: "Offers latest maps and system updates conveniently via Over-the-air Technology.",
      image:car1,
      
    },
    {
      title: 'Voice Controlled Window Function',
      description: "Lets you pull up or open your windows without taking your hands off the wheel.​",
      image: car2,
    },
    {
      title:'Remote AC Control',
      description: 'Gives you the power to set your car to the perfect temperature from anywhere.',
      image: car3,
    },
   
    {
      title: 'Kia Connect Skill with Amazon Alexa',
      description: "Helps you control your Kia from the comfort of your home.",
      image: car4,
    },
    
    
  ];
  


const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [currentIndex2, setCurrentIndex2] = useState(0);
  const [activeImageIndex2, setActiveImageIndex2] = useState(0);
  const [currentIndex3, setCurrentIndex3] = useState(0);
  const [activeImageIndex3, setActiveImageIndex3] = useState(0);
  const [currentIndex4, setCurrentIndex4] = useState(0);
  const [activeImageIndex4, setActiveImageIndex4] = useState(0);
  const [currentIndex5, setCurrentIndex5] = useState(0);
  const [activeImageIndex5, setActiveImageIndex5] = useState(0);
  const [currentIndex6, setCurrentIndex6] = useState(0);
  const [activeImageIndex6, setActiveImageIndex6] = useState(0);
  const [currentIndex7, setCurrentIndex7] = useState(0);
  const [activeImageIndex7, setActiveImageIndex7] = useState(0);
  const [currentIndex8, setCurrentIndex8] = useState(0);
  const [activeImageIndex8, setActiveImageIndex8] = useState(0);
  const [visibleCount, setVisibleCount] = useState(3);

  useEffect(() => {
    const updateVisibleCount = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 1024) setVisibleCount(3);
      else if (screenWidth >= 768) setVisibleCount(2);
      else setVisibleCount(1);
    };

    updateVisibleCount();
    window.addEventListener('resize', updateVisibleCount);
    return () => {
      window.removeEventListener('resize', updateVisibleCount);
    };
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + contents.length) % contents.length);
    setActiveImageIndex((prevIndex) => (prevIndex - 1 + contents.length) % contents.length);
  };

  const handlePrev2 = () => {
    setCurrentIndex2((prevIndex2) => (prevIndex2 - 1 + contents2.length) % contents2.length);
    setActiveImageIndex2((prevIndex2) => (prevIndex2 - 1 + contents2.length) % contents2.length);
  };

  const handlePrev3 = () => {
    setCurrentIndex3((prevIndex3) => (prevIndex3 - 1 + contents3.length) % contents3.length);
    setActiveImageIndex3((prevIndex3) => (prevIndex3 - 1 + contents3.length) % contents3.length);
  };
  const handlePrev4 = () => {
    setCurrentIndex4((prevIndex4) => (prevIndex4 - 1 + contents4.length) % contents4.length);
    setActiveImageIndex4((prevIndex4) => (prevIndex4 - 1 + contents4.length) % contents4.length);
  };
  const handlePrev5 = () => {
    setCurrentIndex5((prevIndex5) => (prevIndex5 - 1 + contents5.length) % contents5.length);
    setActiveImageIndex5((prevIndex5) => (prevIndex5 - 1 + contents5.length) % contents5.length);
  };

  const handlePrev6 = () => {
    setCurrentIndex6((prevIndex6) => (prevIndex6 - 1 + contents6.length) % contents6.length);
    setActiveImageIndex6((prevIndex6) => (prevIndex6 - 1 + contents6.length) % contents6.length);
  };

  const handlePrev7 = () => {
    setCurrentIndex7((prevIndex7) => (prevIndex7 - 1 + contents7.length) % contents7.length);
    setActiveImageIndex7((prevIndex7) => (prevIndex7 - 1 + contents7.length) % contents7.length);
  };

  const handlePrev8 = () => {
    setCurrentIndex8((prevIndex8) => (prevIndex8 - 1 + contents8.length) % contents8.length);
    setActiveImageIndex8((prevIndex8) => (prevIndex8 - 1 + contents8.length) % contents8.length);
  };


  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % contents.length);
    setActiveImageIndex((prevIndex) => (prevIndex + 1) % contents.length);
  };

  const handleNext2 = () => {
    setCurrentIndex2((prevIndex2) => (prevIndex2 + 1) % contents2.length);
    setActiveImageIndex2((prevIndex2) => (prevIndex2 + 1) % contents2.length);
  };

  const handleNext3 = () => {
    setCurrentIndex3((prevIndex3) => (prevIndex3 + 1) % contents3.length);
    setActiveImageIndex3((prevIndex3) => (prevIndex3 + 1) % contents3.length);
  };

  const handleNext4 = () => {
    setCurrentIndex4((prevIndex4) => (prevIndex4 + 1) % contents4.length);
    setActiveImageIndex4((prevIndex4) => (prevIndex4 + 1) % contents4.length);
  };
  const handleNext5 = () => {
    setCurrentIndex5((prevIndex5) => (prevIndex5 + 1) % contents5.length);
    setActiveImageIndex5((prevIndex5) => (prevIndex5 + 1) % contents5.length);
  };

  const handleNext6 = () => {
    setCurrentIndex6((prevIndex6) => (prevIndex6 + 1) % contents6.length);
    setActiveImageIndex6((prevIndex6) => (prevIndex6 + 1) % contents6.length);
  };

  const handleNext7 = () => {
    setCurrentIndex7((prevIndex7) => (prevIndex7 + 1) % contents7.length);
    setActiveImageIndex7((prevIndex7) => (prevIndex7 + 1) % contents7.length);
  };

  const handleNext8 = () => {
    setCurrentIndex8((prevIndex8) => (prevIndex8 + 1) % contents8.length);
    setActiveImageIndex8((prevIndex8) => (prevIndex8 + 1) % contents8.length);
  };

  const getVisibleContents = () => {
    const items = [];
    for (let i = 0; i < visibleCount; i++) {
      items.push(contents[(currentIndex + i) % contents.length]);
    }
    return items;
  };

  const getVisibleContents2 = () => {
    const items = [];
    for (let i = 0; i < visibleCount; i++) {
      items.push(contents2[(currentIndex2 + i) % contents2.length]);
    }
    return items;
  };
  const getVisibleContents3 = () => {
    const items = [];
    for (let i = 0; i < visibleCount; i++) {
      items.push(contents3[(currentIndex3 + i) % contents3.length]);
    }
    return items;
  };
  const getVisibleContents4 = () => {
    const items = [];
    for (let i = 0; i < visibleCount; i++) {
      items.push(contents4[(currentIndex4 + i) % contents4.length]);
    }
    return items;
  };
  const getVisibleContents5 = () => {
    const items = [];
    for (let i = 0; i < visibleCount; i++) {
      items.push(contents5[(currentIndex5 + i) % contents5.length]);
    }
    return items;
  };
  const getVisibleContents6 = () => {
    const items = [];
    for (let i = 0; i < visibleCount; i++) {
      items.push(contents6[(currentIndex6 + i) % contents6.length]);
    }
    return items;
  };
  const getVisibleContents7 = () => {
    const items = [];
    for (let i = 0; i < visibleCount; i++) {
      items.push(contents7[(currentIndex7 + i) % contents7.length]);
    }
    return items;
  };
  const getVisibleContents8 = () => {
    const items = [];
    for (let i = 0; i < visibleCount; i++) {
      items.push(contents8[(currentIndex8 + i) % contents8.length]);
    }
    return items;
  };


  const handleTextClick = (index) => {
    const actualIndex = (currentIndex + index) % contents.length;
    setActiveImageIndex(actualIndex);
  };

  const handleTextClick2 = (index) => {
    const actualIndex = (currentIndex2 + index) % contents2.length;
    setActiveImageIndex2(actualIndex);
  };

  const handleTextClick3 = (index) => {
    const actualIndex = (currentIndex3 + index) % contents3.length;
    setActiveImageIndex3(actualIndex);
  };
  const handleTextClick4 = (index) => {
    const actualIndex = (currentIndex4 + index) % contents4.length;
    setActiveImageIndex4(actualIndex);
  };
  const handleTextClick5 = (index) => {
    const actualIndex = (currentIndex5 + index) % contents5.length;
    setActiveImageIndex5(actualIndex);
  };
  const handleTextClick6 = (index) => {
    const actualIndex = (currentIndex6 + index) % contents6.length;
    setActiveImageIndex6(actualIndex);
  };
  const handleTextClick7 = (index) => {
    const actualIndex = (currentIndex7 + index) % contents7.length;
    setActiveImageIndex7(actualIndex);
  };
  const handleTextClick8 = (index) => {
    const actualIndex = (currentIndex8 + index) % contents8.length;
    setActiveImageIndex8(actualIndex);
  };

  return (
    <Box sx={{}}>

      {/*CAROUSEL1- GRAVITY */}
      <div className="carousel-container">
        <Box sx={{ position: 'relative', width: '100%', height: { md: '100%', xs: '100%' }, color: '#fff' }}>
          <Box sx={{ padding: 5 }}>
            <Typography variant="h6" color="#959191" sx={{ fontWeight: 'bold', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '18px', md: '22px' } }}>
              GRAVITY
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', marginTop: '10px', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '25px', md: '35px', sm: '32px' } }}>
            Wild at Heart, Elevated in Style.
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 1, width: { md: '80%', xs: '90%' }, color: 'black', fontFamily: 'KIAbodyLatinbasic_Regular', fontSize: { xs: '14px', sm: '17px', md: '18px' } }}>
            Experience the Sonet Gravity Variant that is a blend of wild and sophisticated craftsmanship. This brings together cutting-edge technology and aggressive styling, creating a driving experience that’s both thrilling and refined.​
            </Typography>
          </Box>
        </Box>

        {/* Navigation Section */}
        <div className="carousel-navigation">
          <button className="arrow-left" onClick={handlePrev} aria-label="Previous"><ArrowBackIosIcon /></button>
          <button className="arrow-right" onClick={handleNext} aria-label="Next"><ArrowForwardIosIcon /></button>
        </div>

        {/* Content Section */}
        <div className="carousel-content">
          {getVisibleContents().map((content, index) => (
            <div key={index} className="carousel-item-container">
              <div
                className={`carousel-item ${activeImageIndex === (currentIndex + index) % contents.length ? 'active' : ''}`}
                onClick={() => handleTextClick(index)}
                style={{height:"150px"}}
              >
                <h3>{content.title}</h3>
                <p>{content.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Image Section */}
        <div className="carousel-image">
          <img src={contents[activeImageIndex].image} alt={contents[activeImageIndex].title} />
        </div>
      </div>

      {/*CAROUSEL2- XLINE */}
      <div className="carousel-container">
        <Box sx={{ padding: 5 }}>
          <Typography variant="h6" color="#959191" sx={{ fontWeight: 'bold', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '18px', md: '22px' } }}>
            X-LINE
          </Typography>
          <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', marginTop: '10px', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '25px', md: '35px', sm: '32px' } }}>
          The wild experience you can't resist.
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 1, width: { md: '80%', xs: '90%' }, color: 'black', fontFamily: 'KIAbodyLatinbasic_Regular', fontSize: { xs: '14px', sm: '17px', md: '18px' } }}>
          Presenting the new Sonet X-Line, now wilder than ever. Explore the wild allure of the exclusive Matte Graphite exterior, embracing a cabin experience that defies resistance. Ready to set the stage for envy as you journey through diverse horizons, this subcompact SUV in India is designed to captivate and impress.​
          </Typography>
        </Box>

        {/* Navigation Section */}
        <div className="carousel-navigation">
          <button className="arrow-left" onClick={handlePrev2} aria-label="Previous"><ArrowBackIosIcon /></button>
          <button className="arrow-right" onClick={handleNext2} aria-label="Next"><ArrowForwardIosIcon /></button>
        </div>

        {/* Content Section */}
        <div className="carousel-content">
          {getVisibleContents2().map((content, index) => (
            <div key={index} className="carousel-item-container">
              <div
                className={`carousel-item ${activeImageIndex2 === (currentIndex2 + index) % contents2.length ? 'active' : ''}`}
                onClick={() => handleTextClick2(index)}
                style={{height:"190px"}}
              >
                <h3>{content.title}</h3>
                <p>{content.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Image Section */}
        <div className="carousel-image">
          <img src={contents2[activeImageIndex2].image} alt={contents2[activeImageIndex2].title} />
        </div>
      </div>

      {/* SHOWINTEREST FORM Section */}
      <ShowInterest />

      {/* CAROUSEL3-EXTERIOR */}
      <div className="carousel-container">
      <Box sx={{ padding: 5 }}>
          <Typography variant="h6" color="#959191" sx={{ fontWeight: 'bold', fontFamily: "KIAbodyLatinbasic_Bold", fontSize: { xs: '18px', md: '22px' } }}>
            EXTERIOR
          </Typography>
          <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', marginTop: '10px', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '25px', md: '35px', sm: '32px' } }}>
          A dominating stance.
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 1, width: { md: '80%', xs: '90%' }, color: 'black', fontFamily: 'KIAbodyLatinbasic_Regular', fontSize: { xs: '14px', sm: '17px', md: '18px' } }}>
          The majestic attitude of the new Sonet is sure to make a statement. The reborn new Sonet facelift is designed to look intimidating with a bold new Signature Tiger Nose Grille. The new Sonet, a popular subcompact SUV in India, commands respect and oozes dominance wherever it goes.
          </Typography>
        </Box>

        {/* Navigation Section */}
        <div className="carousel-navigation">
          <button className="arrow-left" onClick={handlePrev3} aria-label="Previous"><ArrowBackIosIcon /></button>
          <button className="arrow-right" onClick={handleNext3} aria-label="Next"><ArrowForwardIosIcon /></button>
        </div>

        {/* Content Section */}
        <div className="carousel-content">
          {getVisibleContents3().map((content, index) => (
            <div key={index} className="carousel-item-container">
              <div
                className={`carousel-item ${activeImageIndex3 === (currentIndex3 + index) % contents3.length ? 'active' : ''}`}
                onClick={() => handleTextClick3(index)}
                style={{height:"160px", display:"flex",flexDirection:"column", justifyContent:"center", alignItems:"center"}}
              >
                <h3>{content.title}</h3>
                <p>{content.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Image/Video Section */}
        <div
            className="carousel-image"
            style={{
              position: "relative", 
              marginBottom: "20px", 
              width: "100%", 
              height: "auto",
            }}
          >
            {contents3[activeImageIndex3].video ? (
              <video
                key={activeImageIndex3}
                playsInline
                autoPlay
                loop
                muted
                preload="auto"
                style={{
                  position: "relative", 
                  width: "100%", 
                  height: "auto", 
                  objectFit: "cover", 
                }}
              >
                <source src={contents3[activeImageIndex3].video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={contents3[activeImageIndex3].image}
                alt={contents3[activeImageIndex3].title}
                style={{
                  width: "100%", 
                  height: "auto", 
                }}
              />
            )}
         </div>
      </div>
      
      {/* CAROUSEL 4 - INTERIOR */}
      <div className="carousel-container">
       <Box sx={{ padding: 5 }}>
          <Typography variant="h6" color="#959191" sx={{ fontWeight: 'bold', fontFamily: "KIAbodyLatinbasic_Bold", fontSize: { xs: '18px', md: '22px' } }}>
            INTERIOR
          </Typography>
          <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', marginTop: '10px', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '25px', md: '35px', sm: '32px' } }}>
          Wild outside. Suave inside.
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 1, width: { md: '80%', xs: '90%' }, color: 'black', fontFamily: 'KIAbodyLatinbasic_Regular', fontSize: { xs: '14px', sm: '17px', md: '18px' } }}>
          Fierce on the outside, spacious and calm inside; the new Sonet is here to sharpen your sense of comfort. Be it the Smart Pure Air Purifier with Virus & Bacteria Protection, a futuristic Digital Screen & Cluster, or front ventilated & 4-Way Power Driver’s Seats; the abundance of tech in the new Kia Sonet will put you in a state of serenity.
          </Typography>
        </Box>

        {/* Navigation Section */}
        <div className="carousel-navigation">
          <button className="arrow-left" onClick={handlePrev4} aria-label="Previous"><ArrowBackIosIcon /></button>
          <button className="arrow-right" onClick={handleNext4} aria-label="Next"><ArrowForwardIosIcon /></button>
        </div>

        {/* Content Section */}
        <div className="carousel-content">
          {getVisibleContents4().map((content, index) => (
            <div key={index} className="carousel-item-container">
              <div
                className={`carousel-item ${activeImageIndex4 === (currentIndex4 + index) % contents4.length ? 'active' : ''}`}
                onClick={() => handleTextClick4(index)}
                style={{height:"130px"}}
              >
                <h3>{content.title}</h3>
                <p>{content.description}</p>
              </div>
            </div>
          ))}
        </div>

          {/* Image/Video Section */}
          <div
            className="carousel-image"
            style={{
              position: "relative", 
              marginBottom: "20px", 
              width: "100%", 
              height: "auto",
            }}
          >
            {contents4[activeImageIndex4].video ? (
              <video
                key={activeImageIndex4}
                playsInline
                autoPlay
                loop
                muted
                preload="auto"
                style={{
                  position: "relative", 
                  width: "100%", 
                  height: "auto", 
                  objectFit: "cover", 
                }}
              >
                <source src={contents4[activeImageIndex4].video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={contents4[activeImageIndex4].image}
                alt={contents4[activeImageIndex4].title}
                style={{
                  width: "100%", 
                  height: "auto", 
                }}
              />
            )}
         </div>

      </div>

      {/* CAROUSEL 5 - PERFORMANCE */}
      <div className="carousel-container">
        <Box sx={{ padding: 5 }}>
          <Typography variant="h6" color="#959191" sx={{ fontWeight: 'bold', fontFamily: "KIAbodyLatinbasic_Bold", fontSize: { xs: '18px', md: '22px' } }}>
          PERFORMANCE
          </Typography>
          <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', marginTop: '10px', fontFamily: 'Kia-sig', fontSize: { xs: '25px', md: '35px', sm: '32px' } }}>
          Power of a beast.
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 1, width: { md: '80%', xs: '90%' }, color: 'black', fontFamily: 'Roboto, sans-serif', fontSize: { xs: '12px', sm: '15px', md: '16px' } }}>
          At the heart of every new Sonet is an engine throbbing with power. Power that's honed by precision driving dynamics from a wide range of refined and fuel-efficient engines mated to multiple transmission options. So, choose your favorite combination, put the pedal to the metal, and let every road hear you roar.
          </Typography>
        </Box>

        {/* Navigation Section */}
        <div className="carousel-navigation">
          <button className="arrow-left" onClick={handlePrev5} aria-label="Previous"><ArrowBackIosIcon /></button>
          <button className="arrow-right" onClick={handleNext5} aria-label="Next"><ArrowForwardIosIcon /></button>
        </div>

        {/* Content Section */}
        <div className="carousel-content">
          {getVisibleContents5(contents5, currentIndex5).map((content, index) => (
            <div key={index} className="carousel-item-container">
              <div
                className={`carousel-item ${activeImageIndex5 === (currentIndex5 + index) % contents5.length ? 'active' : ''}`}
                onClick={() => handleTextClick5(index)}
                style={{height:"160px"}}
              >
                <h3>{content.title}</h3>
                <p>{content.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Image Section */}
        <div className="carousel-image">
          <img src={contents5[activeImageIndex5].image} alt={contents5[activeImageIndex5].title} />
        </div>
      </div>

      {/* CAROUSEL 6 - NAVIGATE WITH TRUST, EMBRACE THE UNEXPECTED */}
      <div className="carousel-container">
        <Box sx={{ position: 'relative', width: '100%', height: { md: '100%', xs: '100%' }, color: '#fff' }}>
          <Box sx={{ padding: 5 }}>
            <Typography variant="h6" color="#959191" sx={{ fontWeight: 'bold', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '18px', md: '22px' } }}>
            NAVIGATE WITH TRUST, EMBRACE THE UNEXPECTED
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', marginTop: '10px', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '25px', md: '35px', sm: '32px' } }}>
            Drive with confidence.
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 1, width: { md: '80%', xs: '90%' }, color: 'black', fontFamily: 'KIAbodyLatinbasic_Regular', fontSize: { xs: '14px', sm: '17px', md: '18px' } }}>
            With such raw power comes the responsibility to keep you and your loved ones out of harm's way. That’s why we’ve built the new Sonet with robust 15 safety features across all variants; giving you dependable safety and a calm sense of security.
            </Typography>
          </Box>
        </Box>

        {/* Navigation Section */}
        <div className="carousel-navigation">
          <button className="arrow-left" onClick={handlePrev6} aria-label="Previous"><ArrowBackIosIcon /></button>
          <button className="arrow-right" onClick={handleNext6} aria-label="Next"><ArrowForwardIosIcon /></button>
        </div>

        {/* Content Section */}
        <div className="carousel-content">
          {getVisibleContents6().map((content, index) => (
            <div key={index} className="carousel-item-container">
              <div
                className={`carousel-item ${activeImageIndex6 === (currentIndex6 + index) % contents6.length ? 'active' : ''}`}
                onClick={() => handleTextClick6(index)}
                style={{height:"150px"}}
              >
                <h3>{content.title}</h3>
                <p>{content.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Image/Video Section */}
        <div className="carousel-image">
        {contents6[activeImageIndex6].video ? (
          <video 
              playsinline
              autoPlay
              loop
              muted

          width="100%" height="100%">
            <source src={contents6[activeImageIndex6].video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img
            src={contents6[activeImageIndex6].image}
            alt={contents6[activeImageIndex6].title}
            style={{ width: '100%', height: 'auto' }}
          />
        )}
        </div>
      </div>


      {/* CAROUSEL7- INTUITIVE ADAS LEVEL 1 */}
      <div className="carousel-container">
        <Box sx={{ position: 'relative', width: '100%', height: { md: '100%', xs: '100%' }, color: '#fff' }}>
          <Box sx={{ padding: 5 }}>
            <Typography variant="h6" color="#959191" sx={{ fontWeight: 'bold', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '18px', md: '22px' } }}>
            INTUITIVE ADAS LEVEL 1
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', marginTop: '10px', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '25px', md: '35px', sm: '32px' } }}>
            Innovation meets safety.
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 1, width: { md: '80%', xs: '90%' }, color: 'black', fontFamily: 'KIAbodyLatinbasic_Regular', fontSize: { xs: '14px', sm: '17px', md: '18px' } }}>
            Maneuver the roads with confidence. The new Sonet is equipped with an intuitive Advance Driver Assistance System (ADAS Level 1) featuring 10 autonomous safety features that help make your drive more responsive with multiple safeguards.
            </Typography>
          </Box>
        </Box>

        {/* Navigation Section */}
        <div className="carousel-navigation">
          <button className="arrow-left" onClick={handlePrev7} aria-label="Previous"><ArrowBackIosIcon /></button>
          <button className="arrow-right" onClick={handleNext7} aria-label="Next"><ArrowForwardIosIcon /></button>
        </div>

        {/* Content Section */}
        <div className="carousel-content">
          {getVisibleContents7().map((content, index) => (
            <div key={index} className="carousel-item-container">
              <div
                className={`carousel-item ${activeImageIndex7 === (currentIndex7 + index) % contents7.length ? 'active' : ''}`}
                onClick={() => handleTextClick7(index)}
                style={{height:"150px"}}
              >
                <h3>{content.title}</h3>
                <p>{content.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Image/Video Section */}
        <div className="carousel-image">
        {contents7[activeImageIndex7].video ? (
          <video 
              playsinline
              autoPlay
              loop
              muted

          width="100%" height="100%">
            <source src={contents7[activeImageIndex7].video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img
            src={contents7[activeImageIndex7].image}
            alt={contents7[activeImageIndex7].title}
            style={{ width: '100%', height: 'auto' }}
          />
        )}
        </div>
      </div>


      {/* CAROUSEL8- CONNECTED */}
      <div className="carousel-container">
        <Box sx={{ padding: 5 }}>
          <Typography variant="h6" color="#959191" sx={{ fontWeight: 'bold', fontFamily: "KIAbodyLatinbasic_Bold", fontSize: { xs: '18px', md: '22px' } }}>
          CONNECTED CAR TECHNOLOGY
          </Typography>
          <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', marginTop: '10px', fontFamily: 'Kia-sig', fontSize: { xs: '25px', md: '35px', sm: '32px' } }}>
          Your journey, your command.
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 1, width: { md: '80%', xs: '90%' }, color: 'black', fontFamily: 'Roboto, sans-serif', fontSize: { xs: '12px', sm: '15px', md: '16px' } }}>
          Untamed as it may be, the new Sonet is yours to command. Next-gen Kia Connect integrated with Alexa home-to-car connectivity, and 70+ features that let you control your vehicle through a smartphone or smartwatch.
          </Typography>
        </Box>

        {/* Navigation Section */}
        <div className="carousel-navigation">
          <button className="arrow-left" onClick={handlePrev8} aria-label="Previous"><ArrowBackIosIcon /></button>
          <button className="arrow-right" onClick={handleNext8} aria-label="Next"><ArrowForwardIosIcon /></button>
        </div>

        {/* Content Section */}
        <div className="carousel-content">
          {getVisibleContents8(contents8, currentIndex8).map((content, index) => (
            <div key={index} className="carousel-item-container">
              <div
                className={`carousel-item ${activeImageIndex8 === (currentIndex8 + index) % contents8.length ? 'active' : ''}`}
                onClick={() => handleTextClick8(index)}
                style={{height:"120px"}}
              >
                <h3>{content.title}</h3>
                <p>{content.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Image Section */}
        <div className="carousel-image">
          <img src={contents8[activeImageIndex8].image} alt={contents8[activeImageIndex8].title} />
        </div>
      </div>

           {/* cardcarrousel */}
           <Cardcarrousel/>

            {/* Info */}
            <Box sx={{ padding: '50px 0', textAlign: 'center' }}>
            <Typography 
            variant="h5" 
            color="text.secondary" 
            sx={{ fontWeight: 'bold', mb: 5,fontFamily:'Kia-sig', }}
            >
            DETAILED INFORMATION
            </Typography>

            <Grid container spacing={4} justifyContent="center">
            {/* Book Now Card */}
            {/* <Grid item xs={12} md={4}>
              <Box>
                <BookIcon sx={{ fontSize: 60, color: 'black', mb: 2 }} />
                <Typography variant="h6" fontWeight="bold" sx={{fontFamily:'Kia-sig'}}>
                  Book Kia Carens Today
                </Typography>
                <Typography color="text.secondary" sx={{ mb: 2 ,fontFamily:'Kia-sig'}}>
                  Experience a different World
                </Typography>
                <Button 
                  variant="outlined" 
                  color="inherit"
                  sx={{fontFamily:'Kia-sig'}} 
                  onClick={() => window.location.href = '/booknow'} 
                >
                  Book Now
                </Button>
              </Box>
            </Grid> */}

            {/* Download Brochure Card */}
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  bgcolor: '#0b1d28',
                  color: '#fff',
                  padding: '20px',
                  borderRadius: '4px',
                }}
              >
                <PictureAsPdfIcon sx={{ fontSize: 60, color: 'white', mb: 2 }} />
                <Typography variant="h6" fontWeight="bold" sx={{fontFamily:'Kia-sig'}}>
                  Download Brochure
                </Typography>
                <Typography color="white" sx={{ mb: 2,fontFamily:'Kia-sig' }}>
                  Know it all about our Big Family Car
                </Typography>
                <Button 
                  variant="contained" 
                  color="secondary" 
                  onClick={() => {
                    const link = document.createElement('a');
                    link.href = Sonetbrochure; 
                    link.download = 'sonet_brochure.pdf';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }}
                >
                  Download
                </Button>
              </Box>
            </Grid>

            {/* Call Us Card */}
            <Grid item xs={12} md={4}>
              <Box>
                <CallIcon sx={{ fontSize: 60, color: 'black', mb: 2 }} />
                <Typography variant="h6" fontWeight="bold" fontFamily="Kia-sig">
                  Call us for more information
                </Typography>
                <Typography color="text.secondary" sx={{ mb: 1,fontFamily:'Kia-sig' }}>
                  We’ll be happy to answer all your queries.
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" fontFamily="Kia-sig">
                  1800 108 5000
                </Typography>
                <Button 
                  variant="outlined" 
                  color="inherit" 
                  onClick={() => {
                    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
                    if (isMobile) {
                      window.location.href = 'tel:18001085000';
                    } else {
                      alert('Please call 1800 108 5000 for more information.');
                    }
                  }}
                >
                  Call Us
                </Button>
              </Box>
            </Grid>
            </Grid>
            </Box>

            {/* Faq */}
            <Faq/>


    </Box>
  );
};

export default Carousel;
