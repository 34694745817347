import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const cardsData = [
  { title: 'Premium 7', price: '₹ 10,51,900', features: ['Robust 15 Hi-Safety Package [6 Airbags,ESC,VSM,BAS,HAC,DBC ( Downhill Brake Control),All Wheel Disc Brakes,Highline TPMS,Rear Parking Sensors,ABS}', "2nd Seat 'One touch Easy Electric Tumble'" , 'Roof Flushed 2nd and 3rd Row Diffused AC Vents', 'Semi Leatherette (Black and Indigo) Seats', "2nd Row 50:50 Split Seats with Reclining and Full Flat Folding","keyless Entry with Burglary Alarm",], colors: ['#2B2C71', '#A80F1C', '#2C2E31', '#E0E0DF', '#444444', '#F4F4F4','#3E493B'] },
  { title: 'Premium(O) 7', price: '₹ 11,15,900', features: ['20.32 cm (8.0")Touchscreen with Android Auto, Apple Carplay, (6 Speakers)','Semi Leatherette (Black and Indigo Seats)', 'Rear view camera with dynamic guidelines',  '31.7 cm(12.5") full segment LCD Cluster with Advanced 10.6 cm(4.2") Color TFT MD','Keyless Entry with Burglar Alarm'," Rear View camera with Dynamic Guidelines","Electric Adjust Outside Mirror with LED Turn Signals"], colors: ['#2B2C71', '#A80F1C', '#2C2E31', '#E0E0DF', '#444444', '#F4F4F4','#3E493B'] },
  { title: 'Gravity', price: '₹ 12,09,900', features: ['Das Cam with Dual Camera ', 'Black Leatherette Seats', 'Skylight Sunroof',"D-cut Steering Wheel",'Rear View Camera with Dynamic Guidelines','Keyless Entry with Burglar Alarm','20.32 cm (8.0") Touchscreen with Android Auto,Apple Carplay(6 Speakers)'], colors: ['#2B2C71', '#A80F1C', '#2C2E31', '#E0E0DF', '#444444', '#F4F4F4','#3E493B'] },
  { title: 'Prestige 7', price: '₹ 11,99,900', features: ['Fully Automatic Temperature Control','Premium Fabric and Leatherette Combination (Black and Beige) Seats','Star Map Connected LED DRLs','31.7 cm (12.5") full Segment LCD Cluster with Advanced 10.6 cm (4.2") Color TFT MD', 'Front Parking Sensors', 'Keyless Entry with Burglar Alarm','Integrated Roof rails'], colors: ['#2B2C71', '#A80F1C', '#2C2E31', '#E0E0DF', '#444444', '#F4F4F4','#3E493B'] },
  { title: 'Prestige(O) 7', price: '₹ 12,09,900', features: ['Premium Fabric and Leatherette Combination (Black and Beige) Seats', 'Rear View Camera with Dynamic Guidelines', '31.7 cm (12.5") full Segment LCD Cluster with Advanced 10.6 cm (4.2") Color TFT MD','Front Parking Sensors','Smart Key with push button start','Electric Adjust outside Mirror with LED Turn Signals',"Start Map LED DRLs and Taillamps"], colors: ['#2B2C71', '#A80F1C', '#2C2E31', '#E0E0DF', '#444444', '#F4F4F4','#3E493B'] },
  { title: 'Prestige Plus 7', price: '₹ 15,09,900', features: ['Star Map LED DRLs and Taillamps', 'R16 40.56cm (16") Dual Tone Crystal Cut Alloy Wheels','Fully Automatic Air Conditioner','Multi Drive Models (Normal/Eco/Sport)[7DCT]',"Smart key with push button start",'Auto Cruise Control with Speed Limiting Options',"Remote Engine Start"], colors: ['#2B2C71','#A80F1C','#050709','#E0E0DF','#444444','#F0F8FC','#3E493B'] }, 
  { title: 'Prestige Plus (O) 7', price: '₹ 16,30,900', features: [ 'Skylight Sunroof ', 'Star Map LED DRLs and Taillamps','Fully Automatic Air Conditioner ','Smart key with push button start','Rear Door with Sunshade Curtains','Cooling Cup and Can Holders'], colors: ['#2B2C71','#A80F1C','#050709','#E0E0DF','#444444','#F0F8FC','#3E493B'] },
  { title: 'Luxury 7', price: '₹ 17,24,900', features: ['Crown Jewel LED Headlamps with ICE Cube LED Fog Lamps','26.03 cm(10.25") HD Touchscreen Navigation with Android Auto and apple Carplay', 'Cabin Surround 64 Color Ambient Mood Lighting','Next Generation Kia Connect with OTA Map and System Updates','Smart Pure Air Purifier with Virus and bacteria Protection','All windows auto up/down safety with voice recognition','26.04cm(10.25")Full Digital Color Cluster'], colors: ['#B2001E','#F4F9FB','#35363A','#181A1D','#E0E0DF','#B2001E','#F4F9FB','#3E493B','#2B2C71'] },
  { title: 'Luxury 7(O)', price: '₹ 17,14,900', features: ['Skylight Sunroof','Ambient Lighting Linked with Multi-Drive Modes (Normal/Eco/Sport)'], colors: ['#2B2C71', '#A80F1C', '#3D4042','#E0E0DF',  '#444444', '#F0F8FC'] },
  { title: 'Luxury Plus 7', price: '₹ 18,34,900', features: ['Skylight Sunroof','BOSE Premium Sound System with 8 Speakers', 'Ventillated Front Seats','2nd Row Captains seats with Sliding, Reclining and Tumble (6 Seater)','Smart Phone Wireless Charger with Cooling Function ','Paddling Shifters [7 DCT/6AT]','Walkin-Lever:1st row Passenger seat sliding lever'], colors: ['#2B2C71', '#A80F1C', '#3D4042','#E0E0DF',  '#444444', '#F0F8FC','#3E493B'] },
  { title: 'Luxury 7(O)', price: '₹ 17,14,900', features: ['Xclusive X-line styling','25.65 cm (10.01") Rear Entertainment Passenger side'], colors: ['#262C30'] },
];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [maxCardHeight, setMaxCardHeight] = useState(0);

  // Media queries for responsiveness
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const isTablet = useMediaQuery('(min-width:600px) and (max-width:1023px)');

  // Determine number of cards to display based on screen size
  const cardsToShow = isDesktop ? 3 : isTablet ? 2 : 1;

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < cardsData.length - cardsToShow) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  // Update max height of the cards when the content changes
  useEffect(() => {
    const maxHeight = Math.max(...Array.from(document.querySelectorAll('.card')).map(card => card.offsetHeight));
    setMaxCardHeight(maxHeight);
  }, [cardsData, currentIndex]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', minHeight: '600px' }}>
      {/* Headings */}
      <Typography variant="h4" fontWeight="bold" gutterBottom sx={{fontFamily:"KIAbodyLatinbasic_Bold"}}>
        Meet the Family
      </Typography>
      <Typography variant="h6" fontWeight="normal" gutterBottom sx={{fontFamily:"KIAbodyLatinbasic_Regular"}}>
        11 Trim Available
      </Typography>

      {/* Carousel */}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px', width: '100%' }}>
        {/* Left Arrow */}
        <Button
          onClick={handlePrev}
          disabled={currentIndex === 0}
          sx={{
            minWidth: 'auto',
            padding: '10px',
            marginRight: '10px',
            color: 'black',
            borderRadius: '50%',
            bgcolor:'#EFF1F2',
            '&:hover': { bgcolor: '#EFF1F2' },
            position: 'absolute',
            left: '0', 
          }}
        >
          <ArrowBackIosIcon fontSize="large" />
        </Button>

        {/* Card Display */}
        <Grid container spacing={2} sx={{ width: '100%', maxWidth: '1000px', justifyContent: 'center' }}>
          {cardsData.slice(currentIndex, currentIndex + cardsToShow).map((card, index) => (
            <Grid item xs={12 / cardsToShow} key={index}>
              <Box
                className="card"
                sx={{
                  textAlign: 'center',
                  color: '#000',
                  borderRadius: '4px',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
                  border: '2px solid #ddd', 
                  height: '100%', 
                  display: 'flex',
                  flexDirection: 'column',
               }}
              >
                <Box
                  sx={{
                    textAlign: 'center',
                    backgroundColor: '#05141F',
                    color: '#fff',
                    padding: '10px',
                  }}
                >
                  <Typography variant="h5" fontWeight="bold" gutterBottom sx={{fontFamily:"KIAbodyLatinbasic_Bold"}}>
                    {card.title}
                  </Typography>
                  <Typography variant="h6" gutterBottom sx={{fontFamily:"KIAbodyLatinbasic_Regular"}}>
                    {card.price}{' '}
                    <span style={{ fontSize: '12px', color: '#b0b0b0', fontWeight: '800',fontFamily:"KIAbodyLatinbasic_Bold" }}>
                      Starting MSRP*
                    </span>
                  </Typography>
                </Box>

                <Box
                  sx={{
                    textAlign: 'center',
                    color: '#000',
                    padding: '20px',
                    flex: '1', 
                  }}
                >
                  {/* Color Circles */}
                  <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                    {card.colors.map((color, colorIndex) => (
                      <Box
                        key={colorIndex}
                        sx={{
                          width: 15,
                          height: 15,
                          bgcolor: color,
                          borderRadius: '50%',
                          margin: '0 5px',
                        }}
                      ></Box>
                    ))}
                  </Box>

                  {/* Features List */}
                  <Typography variant="subtitle1" fontWeight="bold" sx={{ marginTop: '15px', fontFamily:"KIAbodyLatinbasic_Bold" }} gutterBottom>
                    Features
                  </Typography>
                  <ul style={{ textAlign: 'left', marginTop: '12px', listStyleType: 'none', fontFamily:"KIAbodyLatinbasic_Regular",  }}>
                    {card.features.map((feature, featureIndex) => (
                      <li key={featureIndex} style={{ marginBottom: '5px' }}>
                        {feature}
                      </li>
                    ))}
                  </ul>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>

        {/* Right Arrow */}
        <Button
          onClick={handleNext}
          disabled={currentIndex >= cardsData.length - cardsToShow}
          sx={{
            minWidth: 'auto',
            padding: '10px',
            marginLeft: '10px',
            borderRadius: '50%',
            bgcolor:'#EFF1F2',
            color:'black',
            '&:hover': { bgcolor: '#EFF1F2' },
            position: 'absolute',
            right: '0', 
          }}
        >
          <ArrowForwardIosIcon fontSize="large" />
        </Button>
      </Box>
    </Box>
  );
};

export default Carousel;
