import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logoImage from "../../Pages/Image/knlogo.jpg";
import centralLogo from "../../Pages/Image/download.jpg";
import { Link as RouterLink } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";

const Navbar = (props) => {
  const [isMobile, setIsMobile] = useState(false);
  const [anchorEl, setIsAnchorEl] = useState(null);
  const [open, setIsOpen] = useState(false);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const handleMenuOpen = (event) => {
    setIsAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  const handleMenuClose = () => {
    setIsAnchorEl(null);
    setIsOpen(false);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        height: "60px",
        zIndex: 1201,
      }}
    >
      <Toolbar
        sx={{
          height: "60px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 16px",
        }}
      >
        {/* Logo Section */}
        <Box
          component={RouterLink}
          to="/admin"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={logoImage}
            alt="mg"
            style={{
              width: "60px",
              height: "auto",
              marginRight: "8px",
            }}
          />
          <img
            src={centralLogo}
            alt="central group"
            style={{
              width: "120px",
              height: "auto",
            }}
          />
        </Box>

        {/* Navigation Links */}
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            gap: 2,
            alignItems: "center",
            flexGrow: 1,
            justifyContent: "flex-end",
            marginRight: 3,
          }}
        >
          <Button
            component={RouterLink}
            to="/admin"
            sx={{
              color: "white",
              "&:hover": { color: "#FFD700" }, // Gold color on hover
            }}
          >
            Dashboard
          </Button>
          <Button
            component={RouterLink}
            to="/advertisement"
            sx={{
              color: "white",
              "&:hover": { color: "#FFD700" }, // Gold color on hover
            }}
          >
            Advertisement
          </Button>
          <Button
            component={RouterLink}
            to="/oldcar"
            sx={{
              color: "white",
              "&:hover": { color: "#FFD700" }, // Gold color on hover
            }}
          >
            Old Car
          </Button>
          <Button
            component={RouterLink}
            to="/contact-details"
            sx={{
              color: "white",
              "&:hover": { color: "#FFD700" }, // Gold color on hover
            }}
          >
            Contact
          </Button>
          <Button
            component={RouterLink}
            to="/service-request"
            sx={{
              color: "white",
              "&:hover": { color: "#FFD700" }, // Gold color on hover
            }}
          >
            Service Request
          </Button>
          <Button
            component={RouterLink}
            to="/book-test-drive"
            sx={{
              color: "white",
              "&:hover": { color: "#FFD700" }, // Gold color on hover
            }}
          >
            Book Test Drive
          </Button>
          <Button
            component={RouterLink}
            to="/vehicle-enquiry"
            sx={{
              color: "white",
              "&:hover": { color: "#FFD700" }, // Gold color on hover
            }}
          >
            Vehicle Enquiry
          </Button>
          <Button
            component={RouterLink}
            to="/enquiry"
            sx={{
              color: "white",
              "&:hover": { color: "#FFD700" }, // Gold color on hover
            }}
          >
            Enquiry
          </Button>
          <Button
            component={RouterLink}
            to="/quatation"
            sx={{
              color: "white",
              "&:hover": { color: "#FFD700" }, // Gold color on hover
            }}
          >
            Quatation
          </Button>
        </Box>

        {/* Avatar and Menu Section */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar
            alt="User Avatar"
            src={props.login?.profile_pic || props.login?.name}
            sx={{ width: 40, height: 40, cursor: "pointer" }}
            onClick={handleMenuOpen}
          />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              component={RouterLink}
              to="/profile"
              onClick={handleMenuClose}
            >
              <AccountCircleIcon sx={{ marginRight: 1 }} />
              Profile
            </MenuItem>
            <MenuItem
              component={RouterLink}
              to="/admin"
              onClick={(e) => {
                props.onLogout();
                handleMenuClose();
              }}
            >
              <LogoutIcon sx={{ marginRight: 1 }} />
              Logout
            </MenuItem>
          </Menu>
        </Box>

        {/* Menu Button for Mobile Screens */}
        <IconButton
          sx={{
            display: { xs: "flex", md: "none" },
            color: "white",
          }}
          onClick={() => setIsMobile(!isMobile)}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>

      {isMobile && (
        <Box
          sx={{
            position: "absolute",
            top: "60px",
            right: 0,
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            zIndex: 1201,
          }}
        >
          <Button
            component={RouterLink}
            to="/dashboard"
            sx={{ color: "white", width: "100%", textAlign: "center" }}
          >
            Dashboard
          </Button>
          <Button
            component={RouterLink}
            to="/advertisement"
            sx={{ color: "white", width: "100%", textAlign: "center" }}
          >
            Advertisement
          </Button>
          <Button
            component={RouterLink}
            to="/oldcar"
            sx={{ color: "white", width: "100%", textAlign: "center" }}
          >
            Old Car
          </Button>
          <Button
            component={RouterLink}
            to="/contact"
            sx={{ color: "white", width: "100%", textAlign: "center" }}
          >
            Contact
          </Button>
          <Button
            component={RouterLink}
            to="/book-test-drive"
            sx={{ color: "white", width: "100%", textAlign: "center" }}
          >
            Book Test Drive
          </Button>
          <Button
            component={RouterLink}
            to="/vehicle-enquiry"
            sx={{ color: "white", width: "100%", textAlign: "center" }}
          >
            Vehicle Enquiry
          </Button>
          <Button
            component={RouterLink}
            to="/enquiry"
            sx={{ color: "white", width: "100%", textAlign: "center" }}
          >
            Enquiry
          </Button>
          <Button
            component={RouterLink}
            to="/quatation"
            sx={{ color: "white", width: "100%", textAlign: "center" }}
          >
            Quatation
          </Button>
        </Box>
      )}
    </AppBar>
  );
};

export default Navbar;
