import React, { Component } from "react";
import { connect } from "react-redux";
import Enquiry from "../Component/Enquiry";

import { AddEnquiryForm } from "../../../Client/action";

export class Controller extends Component {
  render() {
    return <Enquiry {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    AddEnquiryForm: (
        name,
            mobile,
            email,
            city,
            model
    ) => {
      dispatch(
        AddEnquiryForm(
            name,
            mobile,
            email,
            city,
            model
        )
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
