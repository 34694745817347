export const ALLADDES = "ALLADDES";
export const ADDESBYSTATUS = "ADDESBYSTATUS";
export const ALLCONTACTUS = "ALLCONTACTUS";
export const ALLTESTDRIVE = "ALLTESTDRIVE";
export const SETCARID = "SETCARID";
export const ALLOLDCAR = "ALLOLDCAR";
export const VIEWFETURESBYID = "VIEWFETURESBYID";
export const VIEWOLDCARBYID = "VIEWOLDCARBYID";
export const VIEWOVERVIEWBYID = "VIEWOVERVIEWBYID";
export const VIEWSPECIFICATIONBYID= "VIEWSPECIFICATIONBYID";
export const VIEW_CONTACT_US_LENGTH= "VIEW_CONTACT_US_LENGTH";
export const VIEW_TESTDRIVE_LENGTH= "VIEW_TESTDRIVE_LENGTH";
export const OLDCARLENGTH= "OLDCARLENGTH";
export const ALLSERVICE= "ALLSERVICE";
export const ALLVEHICLEENQ= "ALLVEHICLEENQ";
export const ALLENQUIRY= "ALLENQUIRY";
export const ALLREVIEWS= "ALLREVIEWS";
export const ALLQUATATION= "ALLQUATATION";