import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import ev1 from '../../../Image/Ev9/blueev9.png';
import ev2 from '../../../Image/Ev9/blackev9.png';
import ev3 from '../../../Image/Ev9/silverev9.png';
import ev4 from '../../../Image/Ev9/blackishev9.png';
import ev5 from '../../../Image/Ev9/greyec9.png';
import { Typography,Box } from "@mui/material";

const CustomCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0); 
  const [fixedImage, setFixedImage] = useState(ev1); 

  const carImages = [
    { src: ev1, alt: "ev1", color: "#2A8DF2" },
    { src: ev2, alt: "ev2", color: "#0E0D12" },
    { src: ev3, alt: "ev3", color: "#D9DDDB" },
    { src: ev4, alt: "ev4", color: "#757A81" },
    { src: ev5, alt: "ev5", color: "#82858C" },

  ];

  const handleSelect = (index) => {
    setActiveIndex(index);
    setFixedImage(carImages[index].src); 
  };

  return (
    <div style={{ position: "relative",margin:0,padding:0 }}>
    <div style={{ textAlign: "center",padding: "10px" }}>
    <Box sx={{paddingLeft:{xs:6,sm:13,md:23}, textAlign:'left'}}>
      <Typography variant="h6" sx={{color:'#9F9C9C',fontSize:{xs:'16px',sm:'22px'},marginTop:'16px',fontFamily:'KIAbodyLatinbasic_Bold',fontWeight:800,paddingBottom:1}}>DESIGN</Typography>
      <Typography variant="h4" sx={{fontSize:{xs:'22px',sm:'48px'},fontFamily:'KIAbodyLatinbasic_Bold',fontWeight:800,}}>EV9</Typography>
    </Box>
      <img
        className="d-block w-100"
        src={carImages[activeIndex].src}
        alt={carImages[activeIndex].alt}
        style={{ maxWidth: "90%", height: "auto",paddingBottom:'10px' }}
      />
    </div>

    {/* Custom Circle Indicators */}
    <div style={{ position: "absolute", bottom: "20px", left: "50%", transform: "translateX(-50%)", display: "flex", gap: "12px" }}>
      {carImages.map((car, index) => (
        <div
          key={index}
          onClick={() => handleSelect(index)}
          style={{
            width: "23px",
            height: "22px",
            borderRadius: "50%",
            backgroundColor: activeIndex === index ? car.color : car.color, 
            cursor: "pointer",
          }}
        />
      ))}
    </div>
  </div>
  );
};

export default CustomCarousel;
