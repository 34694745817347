import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Drawer, Box, MenuItem, Divider, Button, Collapse } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logoImage from "../../Image/knlogo.jpg"
import centralLogo from "../../Image/download.jpg";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [newCarExpanded, setNewCarExpanded] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const toggleNewCarMenu = () => {
    setNewCarExpanded((prev) => !prev);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const closeNewCarMenu = () => {
    setNewCarExpanded(false);
  };

  return (
    <AppBar position="fixed" sx={{ background: 'black', zIndex: 1000 ,marginTop:'30px'}}>
      <Toolbar sx={{
         display: "flex",
         alignItems: "center",
         justifyContent: "space-between",
      }}>
          <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                borderRadius: "4px",
                width: { md: "250px", lg: "220px" },
                gap: 0,
                width: "220px",
                height: "55px",
                whiteSpace: "nowrap",
                "@media (min-width: 1440px)": {
                  width: "260px",
                },
              }}
            >
              <Link to="/">
              <img
                src={logoImage}
                alt="mg"
                style={{
                  width: "90px",
                  height: "auto",
                  "@media (max-width: 600px)": {
                    width: "40px",
                  },
                }}
              />
              <img
                src={centralLogo}
                alt="central group"
                style={{
                  width: "145px",
                  height: "auto",
                  "@media (max-width: 600px)": {
                    width: "100px",
                    height:"60px"
                  },
                }}
              />
              </Link>
            </Box>

        {isMobile ? (
          <>
            <IconButton edge="end" color="inherit" onClick={toggleDrawer(true)}>
              <MenuIcon sx={{ color: '#E80165' }} />
            </IconButton>

            <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)} >
              <Box sx={{ width: 250,backgroundColor:'black',color:'white' }} role="presentation">
                <Box sx={{ height: '100px',fontWeight:800 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      // backgroundColor: '#000',
                      borderRadius: "4px",
                      width: { md: "250px", lg: "220px" },
                      gap: 0,
                      width: "220px",
                      height: "100px",
                      "@media (min-width: 1440px)": {
                        width: "260px",
                      },
                    }}
                  >
                    <Link to="/">
                    <img
                      src={logoImage}
                      alt="mg"
                      style={{
                        width: "100px",
                        height: "100px",
                        "@media (max-width: 600px)": {
                          width: "40px",
                        },
                      }}
                    />
                    </Link>
                  </Box>
                </Box>
                <Divider sx={{backgroundColor:'grey'}} />
                <Divider sx={{backgroundColor:'grey'}} />

                <MenuItem component={Link} to="/" onClick={closeDrawer} sx={{height:'70px',fontFamily: 'KIAbodyLatinbasic_Regular',}}>
                  Home
                </MenuItem>
                <Divider sx={{backgroundColor:'grey'}} />
                <MenuItem onClick={toggleNewCarMenu} sx={{height:'70px', fontFamily: 'KIAbodyLatinbasic_Regular',}}>
                  New Car {newCarExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </MenuItem>
                <Collapse in={newCarExpanded}>
                  <Box sx={{ pl: 2 }}>
                    <MenuItem component={Link} to="/seltos" onClick={closeDrawer} sx={{fontFamily:'KIAbodyLatinbasic_Regular',}}>
                      Seltos
                    </MenuItem>
                    <MenuItem component={Link} to="/sonet" onClick={closeDrawer} sx={{fontFamily:'KIAbodyLatinbasic_Regular',}}>
                      Sonet
                    </MenuItem>
                    <MenuItem component={Link} to="/carens" onClick={closeDrawer} sx={{fontFamily:'KIAbodyLatinbasic_Regular',}}>
                      Carens
                    </MenuItem>
                    <MenuItem component={Link} to="/ev6" onClick={closeDrawer} sx={{fontFamily:'KIAbodyLatinbasic_Regular',}}>
                      EV6
                    </MenuItem>
                    <MenuItem component={Link} to="/carnival" onClick={closeDrawer} sx={{fontFamily:'KIAbodyLatinbasic_Regular',}}>
                      Carnival
                    </MenuItem>
                    <MenuItem component={Link} to="/ev9" onClick={closeDrawer} sx={{fontFamily:'KIAbodyLatinbasic_Regular',}}>
                      EV9
                    </MenuItem>
                    <MenuItem component={Link} to="/syros" onClick={closeDrawer} sx={{fontFamily:'KIAbodyLatinbasic_Regular',}}>
                      Syros
                    </MenuItem>
                  </Box>
                </Collapse>
                <Divider sx={{backgroundColor:'grey'}} />
                <MenuItem component={Link} to="/preowned" onClick={closeDrawer} sx={{height:'60px',fontFamily:'KIAbodyLatinbasic_Regular',}}>
                  Preowned Cars
                </MenuItem>
                <Divider sx={{backgroundColor:'grey'}} />
               
                <MenuItem component={Link} to="/service" onClick={closeDrawer} sx={{height:'70px',fontFamily:'KIAbodyLatinbasic_Regular'}}>
                  Service
                </MenuItem>  
                <Divider sx={{backgroundColor:'grey'}} />     
                <MenuItem component={Link} to="/contact" onClick={closeDrawer} sx={{height:'70px',fontFamily:'KIAbodyLatinbasic_Regular'}}>
                  Contact Us
                </MenuItem>
                <Divider sx={{backgroundColor:'grey'}}  />
                <MenuItem component={Link} to="/enquiry" onClick={closeDrawer} sx={{height:'70px',fontFamily:'KIAbodyLatinbasic_Regular'}}>
                  Enquiry
                </MenuItem>
              </Box>
            </Drawer>
          </>
        ) : (
          <Box sx={{ display: 'flex', gap: '30px', alignItems: 'center',fontFamily:'KIAbodyLatinbasic_Regular', }}>
            <Button component={Link} to="/" color="inherit" sx={{textTransform:'none',fontFamily: 'KIAbodyLatinbasic_Regular',fontSize:'17px' }}>
              Home
            </Button>
            <Box>
              <Button color="inherit" onClick={toggleNewCarMenu} sx={{ display: 'flex', alignItems: 'center',textTransform:'none',fontFamily: 'KIAbodyLatinbasic_Regular',fontSize:'17px' }}>
                Cars {newCarExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </Button>
              <Collapse in={newCarExpanded}>
                <Box
                  sx={{
                    position: 'absolute',
                    background: 'black',
                    color:'white',
                    boxShadow: 3,
                    mt: 1,
                    zIndex: 1100,
                    textTransform:'none',
                    fontFamily: 'KIAbodyLatinbasic_Regular'
                  }}
                  onMouseLeave={closeNewCarMenu}
                >
                  <MenuItem component={Link} to="/seltos" onClick={closeNewCarMenu}>
                    Seltos
                  </MenuItem>
                  <MenuItem component={Link} to="/sonet" onClick={closeNewCarMenu}>
                    Sonet
                  </MenuItem>
                  <MenuItem component={Link} to="/carens" onClick={closeNewCarMenu}>
                    Carens
                  </MenuItem>
                  <MenuItem component={Link} to="/ev6" onClick={closeNewCarMenu}>
                    EV6
                  </MenuItem>
                  <MenuItem component={Link} to="/carnival" onClick={closeNewCarMenu}>
                    Carnival
                  </MenuItem>
                  <MenuItem component={Link} to="/ev9" onClick={closeNewCarMenu}>
                    EV9
                  </MenuItem>
                  <MenuItem component={Link} to="/syros" onClick={closeDrawer}>
                      Syros
                    </MenuItem>
                </Box>
              </Collapse>
            </Box>
            <Button component={Link} to="/preowned" color="inherit" sx={{whiteSpace: "nowrap" ,textTransform:'none',fontFamily: 'KIAbodyLatinbasic_Regular',fontSize:'17px'}}>
              Preowned cars
            </Button>

            <Button component={Link} to="/service" color="inherit" sx={{textTransform:'none',fontFamily: 'KIAbodyLatinbasic_Regular',fontSize:'17px'}}>
              Service
            </Button>
            <Button component={Link} to="/contact" color="inherit" sx={{ whiteSpace: "nowrap",textTransform:'none',fontFamily: 'KIAbodyLatinbasic_Regular',fontSize:'17px'}}>
              Contact Us
            </Button>

            <Button
              component={Link}
              to="/enquiry"
              sx={{
                backgroundColor: '#fff',
                color: 'black',
                borderRadius: '8px',
                padding: '10px',
                height: '40px',
                width: '100px',
                fontSize:'17px',
                textTransform:'none',
                fontFamily: 'KIAbodyLatinbasic_Regular',
                '&:hover': {
                  backgroundColor: '#fff',
                },
              }}
            >
              Enquiry
            </Button>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
