import React from "react";
import { AppBar, Toolbar, Box, Typography, IconButton } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone"; 

const Nav = () => {
  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: "rgba(0,0,0)", 
        height: "40px", 
        padding: 0,
      }}
    >
      <Toolbar
        sx={{
          height: "40px", 
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 16px", 
        }}
      >
        {/* Empty Box to align content */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Contact Number Box with top margin */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: "-20px", 
            marginRight: "16px", 
          }}
        >
          <IconButton edge="end" color="inherit" aria-label="call">
            <PhoneIcon />
          </IconButton>
          <Typography
            sx={{
              fontFamily: 'KIAbodyLatinbasic_Regular',
              fontSize: { xs: "10px", sm: "16px" },
              color: "white",
              marginLeft: "4px", 
            }}
          >
            <strong>Call Us: 09237386213</strong>
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Nav;
