import React, { useState, useEffect } from 'react';
import { Box,Grid, Typography, useMediaQuery } from '@mui/material';

const cardsData = [
  { title: 'Limousine Plus', price: '₹ 63,90,000', features: ['2nd Row Luxury Powered Relaxation with leg Support (VIP Seats)', '12.3" AVNT with Kia Connect, India Maps and OTA', 'Dual Sunroof', 'One Touch Power Sliding Doors- with hands Free Power Sliding Door Function', 'ADAS','8 Airbags','12.3" Full TFT LCD Cluster'], colors: ['#F3F9FB','#070708'] },
];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [maxCardHeight, setMaxCardHeight] = useState(0);

  // Media queries for responsiveness
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const isTablet = useMediaQuery('(min-width:600px) and (max-width:1023px)');

  // Determine number of cards to display based on screen size
  const cardsToShow = isDesktop ? 3 : isTablet ? 2 : 1;

  
  // Update max height of the cards when the content changes
  useEffect(() => {
    const maxHeight = Math.max(...Array.from(document.querySelectorAll('.card')).map(card => card.offsetHeight));
    setMaxCardHeight(maxHeight);
  }, [cardsData, currentIndex]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', minHeight: '600px' }}>
      {/* Headings */}
      <Typography variant="h4" fontWeight="bold" gutterBottom sx={{fontFamily:"KIAbodyLatinbasic_Bold"}}>
      Meet the World’s Most Inspiring Electric. Ever.
      </Typography>
      <Typography variant="h6" fontWeight="normal" gutterBottom sx={{fontFamily:"KIAbodyLatinbasic_Regular"}}>
        1 Trim Available
      </Typography>

      {/* Carousel */}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px', width: '100%' }}>
        
        {/* Card Display */}
        <Grid container spacing={2} sx={{ width: '100%', maxWidth: '900px', justifyContent: 'center' }}>
          {cardsData.slice(currentIndex, currentIndex + cardsToShow).map((card, index) => (
            <Grid item xs={12 / cardsToShow} key={index}>
              <Box
                className="card"
                sx={{
                  textAlign: 'center',
                  color: '#000',
                  borderRadius: '4px',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
                  border: '2px solid #ddd', 
                  height: '100%', 
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box
                  sx={{
                    textAlign: 'center',
                    backgroundColor: '#05141F',
                    color: '#fff',
                    padding: '10px',
                  }}
                >
                  <Typography variant="h5" fontWeight="bold" gutterBottom sx={{fontFamily:"KIAbodyLatinbasic_Bold"}}>
                    {card.title}
                  </Typography>
                  <Typography variant="h6" gutterBottom sx={{fontFamily:"KIAbodyLatinbasic_Bold"}}>
                    {card.price}{' '}
                    <span style={{ fontSize: '12px', color: '#b0b0b0', fontWeight: '800', fontFamily:"KIAbodyLatinbasic_Bold" }}>
                      Starting MSRP*
                    </span>
                  </Typography>
                </Box>

                <Box
                  sx={{
                    textAlign: 'center',
                    color: '#000',
                    padding: '20px',
                    flex: '1',
                  }}
                >
                  {/* Color Circles */}
                  <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                    {card.colors.map((color, colorIndex) => (
                      <Box
                        key={colorIndex}
                        sx={{
                          width: 15,
                          height: 15,
                          bgcolor: color,
                          borderRadius: '50%',
                          margin: '0 5px',
                        }}
                      ></Box>
                    ))}
                  </Box>

                  {/* Features List */}
                  <Typography variant="subtitle1" fontWeight="bold" sx={{ marginTop: '15px', fontFamily:"KIAbodyLatinbasic_Bold" }} gutterBottom>
                    Features
                  </Typography>
                  <ul style={{ textAlign: 'left', marginTop: '12px', listStyleType: 'none', fontFamily:"KIAbodyLatinbasic_Regular" }}>
                    {card.features.map((feature, featureIndex) => (
                      <li key={featureIndex} style={{ marginBottom: '5px' }}>
                        {feature}
                      </li>
                    ))}
                  </ul>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>

      
      </Box>
    </Box>
  );
};

export default Carousel;
