import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import carn1 from '../../../Image/Carnival/carn1.png';
import carn2 from '../../../Image/Carnival/carn2.png';
import { Typography,Box } from "@mui/material";

const CustomCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0); 
  const [fixedImage, setFixedImage] = useState(carn1); 

  const carImages = [
    { src: carn1, alt: "whitecarn", color: "#F4F9FB" },
    { src: carn2, alt: "blackcarn", color: "#070708" },
    
  ];

  const handleSelect = (index) => {
    setActiveIndex(index);
    setFixedImage(carImages[index].src); 
  };

  return (
    <div style={{ position: "relative",margin:0,padding:0 }}>
    <div style={{ textAlign: "center",padding: "10px" }}>
    <Box sx={{paddingLeft:{xs:6,sm:13,md:23}, textAlign:"left"}}>
      <Typography variant="h6" sx={{color:'#9F9C9C',fontSize:{xs:'16px',sm:'22px'},marginTop:'16px',fontFamily:'KIAbodyLatinbasic_Bold',fontWeight:800,paddingBottom:1}}>DESIGN</Typography>
      <Typography variant="h4" sx={{fontSize:{xs:'22px',sm:'48px'},fontFamily:'KIAbodyLatinbasic_Bold',fontWeight:800,}}>CARNIVAL</Typography>
    </Box>
      <img
        className="d-block w-100"
        src={carImages[activeIndex].src}
        alt={carImages[activeIndex].alt}
        style={{ maxWidth: "70%", height: "auto",paddingBottom:'10px' }}
      />
    </div>

    {/* Custom Circle Indicators */}
    <div style={{ position: "absolute", bottom: "20px", left: "50%", transform: "translateX(-50%)", display: "flex", gap: "12px" }}>
      {carImages.map((car, index) => (
        <div
          key={index}
          onClick={() => handleSelect(index)}
          style={{
            width: "23px",
            height: "22px",
            borderRadius: "50%",
            backgroundColor: activeIndex === index ? car.color : car.color, 
            cursor: "pointer",
          }}
        />
      ))}
    </div>
  </div>
  );
};

export default CustomCarousel;
