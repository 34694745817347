import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid,Button } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import limo1 from '../../../Image/Carnival/LP-1.jpg';
import limo2 from '../../../Image/Carnival/LP-2.jpg';
import limo3 from '../../../Image/Carnival/LP-3.jpg';
import limo4 from '../../../Image/Carnival/LP-4.jpg';
import limo5 from '../../../Image/Carnival/LP-5.jpg';
import limo6 from '../../../Image/Carnival/LP-6.jpg';
import lu1 from '../../../Image/Carnival/Lu-1.jpg';
import lu2 from '../../../Image/Carnival/Lu-2.jpg';
import lu3 from '../../../Image/Carnival/Lu-3.jpg';
import lu4 from '../../../Image/Carnival/Lu-4.jpg';
import lu5 from '../../../Image/Carnival/Sa-1.jpg';
import ext1 from '../../../Image/Carnival/ext1.jpg';
import ext2 from '../../../Image/Carnival/ext2.jpg';
import ext3 from '../../../Image/Carnival/ext3.jpg';
import ext4 from '../../../Image/Carnival/ext4.jpg';
import int1 from '../../../Image/Carnival/int1.jpg';
import int2 from '../../../Image/Carnival/int2.jpg';
import int3 from '../../../Image/Carnival/int3.jpg';
import int4 from '../../../Image/Carnival/int4.jpg';
import safe1 from '../../../Image/Carnival/safe1.jpg';
import safe2 from '../../../Image/Carnival/safe2.jpg';
import safe3 from '../../../Image/Carnival/safe3.jpg'
import safe4 from '../../../Image/Carnival/safe4.jpg';
import safe5 from '../../../Image/Carnival/safe5.jpg';
import Cardcarrousel from '../../../Cars/Carnival/Component/cardcarrousel'
import BookIcon from '@mui/icons-material/LibraryBooks';
import CallIcon from '@mui/icons-material/Call';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Carnivalbrochure from '../../../Brochure/Carnival/Kia_Carnival_Brochure_Desktop.pdf' 
import Faq from '../../../Cars/Carnival/Component/faqs';

  const contents = [
    {
      title: 'Front LED Fog Lamps',
      description: 'Cut through the mist and navigate the path ahead with the ease.',
      imageUrl: limo1,
    },
    {
      title: '2nd Row Powered Relaxation Seats with Ventilation, Heating and Leg Support',
      description: 'Cruise with elegance for a journey more extravagant than the destination.',
      imageUrl: limo2,
    },
    {
      title: 'Bose Premium Surround Sound with 12 Speakers',
      description: 'Let every note and every symphony take flight and fill your private cabin.',
      imageUrl: limo3,
    },
    {
      title: 'Front Console Wireless Smartphone Charger',
      description: 'Charge up and take off with the convenience of a wireless smartphone charger. No cables. No limits.',
      imageUrl: limo4,
    },
    {
      title: 'Wide Electric Dual Sunroof',
      description: 'Limitless views for you, as if from an attitude of 30,000 feet in the sky.',
      imageUrl: limo5,
    },
    {
      title:'Advanced Head-up Display',
      description:'A personalised panel for your Digital Cockpit',
      imageUrl:limo6,
    }
  ];

  const contents2 = [
    {
      title: 'R18 - 45.72 Cm (18”) Diamond Cut Alloy',
      description: 'Take off in style with Black and Chrome coating B-Type Radiator Grille in the Alloy Wheels of your limousine liner.',
      imageUrl: lu1,
    },
    {
      title: 'Premium Leatherette VIP Seats',
      description: 'First-class comfort at every mile for you. Relax, sit back and buckle up to soar above the crowd.',
      imageUrl: lu2,
    },
    {
      title: 'One Touch Power Sliding Doors',
      description: 'Open up a world of convenience, an effortless onboarding for ultimate extravagance.',
      imageUrl: lu3,
    },
    {
      title: 'Independently Controlled 3-zone Fully-automatic Temperature Control',
      description: 'A customised climate zone for every mile of your journey.',
      imageUrl: lu4,
    },
    {
      title:'8 Airbags',
      description:'The ultimate peace of mind wrapped in the luxury of premium protection.',
      imageUrl:lu5
    }
   
  ];

  const contents3 = [
    {
      title: 'Black and Chrome “Tiger Nose Grille”',
      description: 'With Black and Chrome coating Tiger Nose Grille, the Kia Carnival takes off from the crowd in many ways than one.',
      imageUrl: ext1,
    },
    {
      title: 'One Touch Sliding Door with Power Tail Gate',
      description: 'An effortless, and smooth boarding experience, accessible at one touch. ​',
      imageUrl: ext2,
    },
    {
      title: 'Crown Jew',
      description: 'Highlight the all-new bold front and give it a dazzling road presence.',
      imageUrl: ext3,
    },
    {
      title: '18” Diamond Cut Alloy Wheels',
      description: 'Have a lift-off with striking design that cuts through the road with aerodynamic grace.​',
      imageUrl: ext4,
    },
  
  ];

  const contents4 = [
    {
      title: '3 Zone Seating with Tri-Zone Cooling',
      description: 'Personalized climate control for each zone ensuring a comfortable temperature for every one of your passengers.',
      imageUrl: int1,
    },
    {
      title: 'Infotainment System',
      description: "An immersive digital command center that elevates every journey with seamless connectivity and entertainment.​​",
      imageUrl: int2,
    },
    {
      title: 'Wireless Charger',
      description: "Seamlessly stay connected on the go with an effortless charging station in your cabin.",
      imageUrl: int3,
    },
    {
      title: 'Paddle Shifter',
      description: "Enjoy a more engaging driving experience with our Paddle Shifters. Quickly and easily shift gears for optimal performance.",
      imageUrl: int4,
    },
   
  ];

  const contents5 = [
    {
      title:'8 Airbags',
      description: "Safety for the full deck and the cabin alike, making every journey as secure as it is smooth.",
      imageUrl: safe1,
    },
    {
      title: 'Rear Cross-Traffic Collision Avoidance System',
      description: "Avoid collisions with approaching vehicles when backing up with our advanced Rear Cross-Traffic Collision Avoidance System.",
      imageUrl:safe2,
    },
    {
      title: '360-Degree-Camera',
      description: "Get an aerial view of your surroundings so that your eyes are not only on the road when you drive but all-around even when you’re not.",
      imageUrl: safe3,
    },
    {
      title:'Smart Cruise Control',
      description:'Avoid collisions and maintain a safe following distance with our advanced Smart Cruise Control system.',
      imageUrl:safe4,
    },
    {
      title:'Lane Keep Assist',
      description:'Stay on course with our Lane Keep Assist system. It helps prevent accidental lane departures and keeps you centered in your lane, ensuring a safer and more comfortable drive.',
      imageUrl:safe5,
    },
   
  ];

  const Carousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [activeImageIndex, setActiveImageIndex] = useState(0);
    const [currentIndex2, setCurrentIndex2] = useState(0);
    const [activeImageIndex2, setActiveImageIndex2] = useState(0);
    const [currentIndex3, setCurrentIndex3] = useState(0);
    const [activeImageIndex3, setActiveImageIndex3] = useState(0);
    const [currentIndex4, setCurrentIndex4] = useState(0);
    const [activeImageIndex4, setActiveImageIndex4] = useState(0);
    const [currentIndex5, setCurrentIndex5] = useState(0);
    const [activeImageIndex5, setActiveImageIndex5] = useState(0);
    const [visibleCount, setVisibleCount] = useState(3);
  
  
    useEffect(() => {
      const updateVisibleCount = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth >= 1024) setVisibleCount(3);
        else if (screenWidth >= 768) setVisibleCount(2);
        else setVisibleCount(1);
      };
  
      updateVisibleCount();
      window.addEventListener('resize', updateVisibleCount);
      return () => {
        window.removeEventListener('resize', updateVisibleCount);
      };
    }, []);
  
    const handlePrev = () => {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + contents.length) % contents.length);
      setActiveImageIndex((prevIndex) => (prevIndex - 1 + contents.length) % contents.length);
    };
  
    const handlePrev2 = () => {
      setCurrentIndex2((prevIndex2) => (prevIndex2 - 1 + contents2.length) % contents2.length);
      setActiveImageIndex2((prevIndex2) => (prevIndex2 - 1 + contents2.length) % contents2.length);
    };
  
    const handlePrev3 = () => {
      setCurrentIndex3((prevIndex3) => (prevIndex3 - 1 + contents3.length) % contents3.length);
      setActiveImageIndex3((prevIndex3) => (prevIndex3 - 1 + contents3.length) % contents3.length);
    };
  
    const handlePrev4 = () => {
      setCurrentIndex4((prevIndex4) => (prevIndex4 - 1 + contents4.length) % contents4.length);
      setActiveImageIndex4((prevIndex4) => (prevIndex4 - 1 + contents4.length) % contents4.length);
    };
  
    const handlePrev5 = () => {
      setCurrentIndex5((prevIndex5) => (prevIndex5 - 1 + contents5.length) % contents5.length);
      setActiveImageIndex5((prevIndex5) => (prevIndex5 - 1 + contents5.length) % contents5.length);
    };
  
  
  
    const handleNext = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % contents.length);
      setActiveImageIndex((prevIndex) => (prevIndex + 1) % contents.length);
    };
  
    const handleNext2 = () => {
      setCurrentIndex2((prevIndex2) => (prevIndex2 + 1) % contents2.length);
      setActiveImageIndex2((prevIndex2) => (prevIndex2 + 1) % contents2.length);
    };
  
    const handleNext3 = () => {
      setCurrentIndex3((prevIndex3) => (prevIndex3 + 1) % contents3.length);
      setActiveImageIndex3((prevIndex3) => (prevIndex3 + 1) % contents3.length);
    };
  
    const handleNext4 = () => {
      setCurrentIndex4((prevIndex4) => (prevIndex4 + 1) % contents4.length);
      setActiveImageIndex4((prevIndex4) => (prevIndex4 + 1) % contents4.length);
    };
  
    const handleNext5 = () => {
      setCurrentIndex5((prevIndex5) => (prevIndex5 + 1) % contents5.length);
      setActiveImageIndex5((prevIndex5) => (prevIndex5 + 1) % contents5.length);
    };
  
    
  
    const getVisibleContents = () => {
      const items = [];
      for (let i = 0; i < visibleCount; i++) {
        items.push(contents[(currentIndex + i) % contents.length]);
      }
      return items;
    };
  
    const getVisibleContents2 = () => {
      const items = [];
      for (let i = 0; i < visibleCount; i++) {
        items.push(contents2[(currentIndex2 + i) % contents2.length]);
      }
      return items;
    };
    const getVisibleContents3 = () => {
      const items = [];
      for (let i = 0; i < visibleCount; i++) {
        items.push(contents3[(currentIndex3 + i) % contents3.length]);
      }
      return items;
    };
    const getVisibleContents4 = () => {
      const items = [];
      for (let i = 0; i < visibleCount; i++) {
        items.push(contents4[(currentIndex4 + i) % contents4.length]);
      }
      return items;
    };
    const getVisibleContents5 = () => {
      const items = [];
      for (let i = 0; i < visibleCount; i++) {
        items.push(contents5[(currentIndex5 + i) % contents5.length]);
      }
      return items;
    };
    
  
    const handleTextClick = (index) => {
      const actualIndex = (currentIndex + index) % contents.length;
      setActiveImageIndex(actualIndex);
    };
  
    const handleTextClick2 = (index) => {
      const actualIndex = (currentIndex2 + index) % contents2.length;
      setActiveImageIndex2(actualIndex);
    };
  
    const handleTextClick3 = (index) => {
      const actualIndex = (currentIndex3 + index) % contents3.length;
      setActiveImageIndex3(actualIndex);
    };
    const handleTextClick4 = (index) => {
      const actualIndex = (currentIndex4 + index) % contents4.length;
      setActiveImageIndex4(actualIndex);
    };
    const handleTextClick5 = (index) => {
      const actualIndex = (currentIndex5 + index) % contents5.length;
      setActiveImageIndex5(actualIndex);
    };
   
  
    return (
      <Box sx={{}}>
  
        {/*CAROUSEL1- LIMOUSINE+ */}
        <div className="carousel-container">
          <Box sx={{ position: 'relative', width: '100%', height: { md: '100%', xs: '100%' }, color: '#fff' }}>
            <Box sx={{ padding: 5 }}>
              <Typography variant="h6" color="#959191" sx={{ fontWeight: 'bold', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '18px', md: '22px' } }}>
              LIMOUSINE+
              </Typography>
              <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', marginTop: '10px', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '25px', md: '35px', sm: '32px' } }}>
              Extravagance. Reborn.
              </Typography>
              <Typography variant="subtitle1" sx={{ mt: 1, width: { md: '80%', xs: '90%' }, color: 'black', fontFamily: 'KIAbodyLatinbasic_Regular', fontSize: { xs: '14px', sm: '17px', md: '18px' } }}>
              Engineered to make every journey a first-class experience, the Kia Carnival Limousine+ sets the new standard for luxury, and innovation.​
              </Typography>
            </Box>
          </Box>
  
          {/* Navigation Section */}
          <div className="carousel-navigation">
            <button className="arrow-left" onClick={handlePrev} aria-label="Previous"><ArrowBackIosIcon /></button>
            <button className="arrow-right" onClick={handleNext} aria-label="Next"><ArrowForwardIosIcon /></button>
          </div>
  
          {/* Content Section */}
          <div className="carousel-content">
            {getVisibleContents().map((content, index) => (
              <div key={index} className="carousel-item-container">
                <div
                  className={`carousel-item ${activeImageIndex === (currentIndex + index) % contents.length ? 'active' : ''}`}
                  onClick={() => handleTextClick(index)}
                  style={{height:"130px"}}
                >
                  <h3>{content.title}</h3>
                  <p>{content.description}</p>
                </div>
              </div>
            ))}
          </div>
  
          {/* Image Section */}
          <div className="carousel-image">
            <img src={contents[activeImageIndex].imageUrl} alt={contents[activeImageIndex].title} />
          </div>
        </div>
  
        {/*CAROUSEL2- LIMOUSINE */}
        <div className="carousel-container">
          <Box sx={{ padding: 5 }}>
            <Typography variant="h6" color="#959191" sx={{ fontWeight: 'bold', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '18px', md: '22px' } }}>
            LIMOUSINE
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', marginTop: '10px', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '25px', md: '35px', sm: '32px' } }}>
            Take off with Luxury.
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 1, width: { md: '80%', xs: '90%' }, color: 'black', fontFamily: 'KIAbodyLatinbasic_Regular', fontSize: { xs: '14px', sm: '17px', md: '18px' } }}>
            Built, and crafted to refine your journeys, every innovative and extravagant detail of the new Kia Carnival Limousine is meant to be your portal to sheer opulence.
            </Typography>
          </Box>
  
          {/* Navigation Section */}
          <div className="carousel-navigation">
            <button className="arrow-left" onClick={handlePrev2} aria-label="Previous"><ArrowBackIosIcon /></button>
            <button className="arrow-right" onClick={handleNext2} aria-label="Next"><ArrowForwardIosIcon /></button>
          </div>
  
          {/* Content Section */}
          <div className="carousel-content">
            {getVisibleContents2().map((content, index) => (
              <div key={index} className="carousel-item-container">
                <div
                  className={`carousel-item ${activeImageIndex2 === (currentIndex2 + index) % contents2.length ? 'active' : ''}`}
                  onClick={() => handleTextClick2(index)}
                  style={{height:"130px"}}
                >
                  <h3>{content.title}</h3>
                  <p>{content.description}</p>
                </div>
              </div>
            ))}
          </div>
  
          {/* Image Section */}
          <div className="carousel-image">
            <img src={contents2[activeImageIndex2].imageUrl} alt={contents2[activeImageIndex2].title} />
          </div>
        </div>
  
        {/* CAROUSEL3-EXTERIOR */}
        <div className="carousel-container">
          <Box sx={{ padding: 5 }}>
            <Typography variant="h6" color="#959191" sx={{ fontWeight: 'bold', fontFamily: "KIAbodyLatinbasic_Bold", fontSize: { xs: '18px', md: '22px' } }}>
              EXTERIOR
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', marginTop: '10px', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '25px', md: '35px', sm: '32px' } }}>
            Style that’s sky-high.
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 1, width: { md: '80%', xs: '90%' }, color: 'black', fontFamily: 'KIAbodyLatinbasic_Regular', fontSize: { xs: '14px', sm: '17px', md: '18px' } }}>
            Soar through the streets with the new Kia Carnival’s exceptionally elegant exterior, and make every drive feel like an ascent.
            </Typography>
          </Box>
  
          {/* Navigation Section */}
          <div className="carousel-navigation">
            <button className="arrow-left" onClick={handlePrev3} aria-label="Previous"><ArrowBackIosIcon /></button>
            <button className="arrow-right" onClick={handleNext3} aria-label="Next"><ArrowForwardIosIcon /></button>
          </div>
  
          {/* Content Section */}
          <div className="carousel-content">
            {getVisibleContents3(contents3, currentIndex3).map((content, index) => (
              <div key={index} className="carousel-item-container">
                <div
                  className={`carousel-item ${activeImageIndex3 === (currentIndex3 + index) % contents3.length ? 'active' : ''}`}
                  onClick={() => handleTextClick3(index)}
                  style={{height:"130px"}}
                >
                  <h3>{content.title}</h3>
                  <p>{content.description}</p>
                </div>
              </div>
            ))}
          </div>
  
          {/* Image Section */}
          <div className="carousel-image">
            <img src={contents3[activeImageIndex3].imageUrl} alt={contents3[activeImageIndex3].title} />
          </div>
        </div>
  
        {/* CAROUSEL 4 - INTERIOR */}
        <div className="carousel-container">
          <Box sx={{ padding: 5 }}>
            <Typography variant="h6" color="#959191" sx={{ fontWeight: 'bold', fontFamily: "KIAbodyLatinbasic_Bold", fontSize: { xs: '18px', md: '22px' } }}>
              INTERIOR
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', marginTop: '10px', fontFamily: 'Kia-sig', fontSize: { xs: '25px', md: '35px', sm: '32px' } }}>
            Comfort of the Captain’s Quarters.
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 1, width: { md: '80%', xs: '90%' }, color: 'black', fontFamily: 'Roboto, sans-serif', fontSize: { xs: '12px', sm: '15px', md: '16px' } }}>
            Designed for premium comfort, and to make every journey a first-class experience, the new Kia Carnival sets the standard for luxury, technology, and extravagance alike.
            </Typography>
          </Box>
  
          {/* Navigation Section */}
          <div className="carousel-navigation">
            <button className="arrow-left" onClick={handlePrev4} aria-label="Previous"><ArrowBackIosIcon /></button>
            <button className="arrow-right" onClick={handleNext4} aria-label="Next"><ArrowForwardIosIcon /></button>
          </div>
  
          {/* Content Section */}
          <div className="carousel-content">
            {getVisibleContents4(contents4, currentIndex4).map((content, index) => (
              <div key={index} className="carousel-item-container">
                <div
                  className={`carousel-item ${activeImageIndex4 === (currentIndex4 + index) % contents4.length ? 'active' : ''}`}
                  onClick={() => handleTextClick4(index)}
                  style={{height:"130px"}}
                >
                  <h3>{content.title}</h3>
                  <p>{content.description}</p>
                </div>
              </div>
            ))}
          </div>
  
          {/* Image Section */}
          <div className="carousel-image">
            <img src={contents4[activeImageIndex4].imageUrl} alt={contents4[activeImageIndex4].title} />
          </div>
        </div>
  
        {/* CAROUSEL 5 - SAFETY */}
        <div className="carousel-container">
          <Box sx={{ padding: 5 }}>
            <Typography variant="h6" color="#959191" sx={{ fontWeight: 'bold', fontFamily: "KIAbodyLatinbasic_Bold", fontSize: { xs: '18px', md: '22px' } }}>
            SAFETY
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', marginTop: '10px', fontFamily: 'Kia-sig', fontSize: { xs: '25px', md: '35px', sm: '32px' } }}>
            Mile-high Safety.
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 1, width: { md: '80%', xs: '90%' }, color: 'black', fontFamily: 'Roboto, sans-serif', fontSize: { xs: '12px', sm: '15px', md: '16px' } }}>
            Experience a journey safeguarded by advanced technology, with 23 autonomous ADAS features designed to keep you secure through every mile. The Kia Carnival provides smart protective systems that anticipate your needs on the road, ensuring utmost safety and peace of mind with every drive.
            </Typography>
          </Box>
  
          {/* Navigation Section */}
          <div className="carousel-navigation">
            <button className="arrow-left" onClick={handlePrev5} aria-label="Previous"><ArrowBackIosIcon /></button>
            <button className="arrow-right" onClick={handleNext5} aria-label="Next"><ArrowForwardIosIcon /></button>
          </div>
  
          {/* Content Section */}
          <div className="carousel-content">
            {getVisibleContents5(contents5, currentIndex5).map((content, index) => (
              <div key={index} className="carousel-item-container">
                <div
                  className={`carousel-item ${activeImageIndex5 === (currentIndex5 + index) % contents5.length ? 'active' : ''}`}
                  onClick={() => handleTextClick5(index)}
                  style={{height:"147px"}}
                >
                  <h3>{content.title}</h3>
                  <p>{content.description}</p>
                </div>
              </div>
            ))}
          </div>
  
          {/* Image Section */}
          <div className="carousel-image">
            <img src={contents5[activeImageIndex5].imageUrl} alt={contents5[activeImageIndex5].title} />
          </div>
        </div>
  
            {/* cardcarrousel */}
            <Cardcarrousel/>

              {/* Info */}
              <Box sx={{ padding: '50px 0', textAlign: 'center' }}>
              <Typography 
              variant="h5" 
              color="text.secondary" 
              sx={{ fontWeight: 'bold', mb: 5,fontFamily:'KIAbodyLatinbasic_Bold', }}
              >
              DETAILED INFORMATION
              </Typography>

              <Grid container spacing={4} justifyContent="center">
             
              {/* Download Brochure Card */}
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    bgcolor: '#0b1d28',
                    color: '#fff',
                    padding: '20px',
                    borderRadius: '4px',
                  }}
                >
                  <PictureAsPdfIcon sx={{ fontSize: 60, color: 'white', mb: 2 }} />
                  <Typography variant="h6" fontWeight="bold" sx={{fontFamily:'KIAbodyLatinbasic_Bold'}}>
                    Download Brochure
                  </Typography>
                  <Typography color="white" sx={{ mb: 2,fontFamily:'KIAbodyLatinbasic_Regular' }}>
                    Get all what you want to know about New Carnival Limousine
                  </Typography>
                  <Button 
                    variant="contained" 
                    color="secondary" 
                    onClick={() => {
                      const link = document.createElement('a');
                      link.href = Carnivalbrochure; 
                      link.download = 'Kia-Carnival_brochure.pdf';
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    }}
                  >
                    Download
                  </Button>
                </Box>
              </Grid>

              {/* Call Us Card */}
              <Grid item xs={12} md={4}>
                <Box>
                  <CallIcon sx={{ fontSize: 60, color: 'black', mb: 2 }} />
                  <Typography variant="h6" fontWeight="bold" fontFamily="KIAbodyLatinbasic_Bold">
                    Call us for more information
                  </Typography>
                  <Typography color="text.secondary" sx={{ mb: 1,fontFamily:'KIAbodyLatinbasic_Regular' }}>
                    We’ll be happy to answer all your queries.
                  </Typography>
                  <Typography variant="subtitle1" fontWeight="bold" fontFamily="KIAbodyLatinbasic_Regular">
                  09237386213
                  </Typography>
                  <Button 
                    variant="outlined" 
                    color="inherit" 
                    onClick={() => {
                      const isMobile = /Mobi|Android/i.test(navigator.userAgent);
                      if (isMobile) {
                        window.location.href = 'tel: 09237386213';
                      } else {
                        alert('Please call  09237386213 for more information.');
                      }
                    }}
                  >
                    Call Us
                  </Button>
                </Box>
              </Grid>
              </Grid>
              </Box>

              {/* Faq */}
              <Faq/>  


            
      </Box>
    );
  };
  
  export default Carousel;
  
