import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const cardsData = [
  { title: 'HTE', price: '₹ 10,89,900', features: ['6 Airbags (Front, Side and Curtain Airbags)', 'ESC, VSM and HAC', 'All Wheel Disc Brakes', 'Highline Tire Pressure Monitoring', 'Full digital Cluster with 10.5cm (4.22") Color TFD MID', "Rear Door Sunshade Curtain", "Halogen Projector Headlamps"], colors: ['#202040', '#465945', '#942828', '#6d6d6d', '#1c1c1c', '#e3e3e3'] },
  { title: 'HTK', price: '₹ 12,36,900', features: ['6 Airbags (Front, Side and Curtain Airbags)', 'ESC, VSM and HAC', 'All Wheel Disc Brakes', 'Highline Tire Pressure Monitoring','20.32 cm (8.0") Touchscreen with Wireless Android Auto and Apple Carplay','Full Digital Cluster with 10.5 cm (4.2") Color TFT MID','Rear Door sunshad Curatin'], colors: ['#202040', '#465945', '#942828', '#6d6d6d', '#1c1c1c', '#e3e3e3'] },
  { title: 'HTK+', price: '₹ 14,13,900', features: ['6 Airbags (Front, Side and Curtain Airbags)', 'ESC, VSM and HAC', 'Smart Key with Push Button Start/Stop',"Front LED DRLs",'20.32 cm (8.0") Touchscreen with Wireless Android Auto and Apple Carplay','Full Digital Cluster with 10.5 cm (4.2") Color TFT MID','Rear Door sunshad Curatin'], colors: ['#202040', '#465945', '#942828', '#6d6d6d', '#1c1c1c', '#e3e3e3'] },
  { title: 'HTX', price: '₹ 15,46,900', features: ['6 Airbags (Front, Side and Curtain Airbags)', 'Panoramic Sunroof', 'Crown jewel LED Headlamps with Start Map LED Sweeping Light Guide','Star Map LED Connected Tail Lamps','R17 43.66 cm Alloy Wheels','Smart Pure Air Purifier with Virus and Bacteria Protection**','26.03 cm (10.25") HD Touchscreen Navigation with Kia Connect with OTA Maps and System Update'], colors: ['#3E493B', '#2B2C71', '#B2001E', '#F3F9FB', '#35363A', '#040608','#E0E0DF'] },
  { title: 'Gravity', price: '₹ 16,62,900', features: ['6 Airbags (Front, Side and Curtain Airbags)', 'Panoramic Sunroof', 'Ventilated Front Seats','Auto Hold Electronic Parking Brake (EPB)','Intituitive HD Touch screen','BOSE Premium Sound System with 8 speakers','Dash Cam with Dual Camera'], colors: [ '#2B2C71', '#3E493B','#B2001E', '#35363A','#F3F9FB', '#E0E0DF', '#040608'] },
  { title: 'HTX+', price: '₹ 18,83,900', features: ['6 Airbags (Front, Side and Curtain Airbags)', 'Panoramic Sunroof', 'Crown jewel LED Headlamps with Start Map LED Sweeping Light Guide','Star Map LED Connected Tail Lamps','Sequential LED Turn Indicators with Ice cube LED and Fog Lamps','R17 43.66 cm Crystal Cut Alloy Wheels'], colors: ['#3E493B', '#2B2C71', '#B2001E', '#F3F9FB', '#35363A', '#040608','#E0E0DF'] },
  { title: 'GTX', price: '₹ 19,07,900', features: ['ADAS 2.0 with 14 Features', 'Crown jewel LED Headlamps with Start Map LED Sweeping Light Guide','Panoramic Sunroof','Star Map LED Connected Tail Lamps','R18 46.20 cm Crystal Cut Glossy Black Alloy Wheels','Front ventillated Seats','Dual Zone Fully Automatic Air Conditioner'], colors: ['#3E493B', '#2B2C71', '#B2001E', '#F3F9FB', '#35363A', '#040608','#E0E0DF'] },
  { title: 'GTX+(S)', price: '₹ 19,39,900', features: ['ADAS 2.0 with 19 Features','Panoramic Sunroof', 'Crown jewel LED Headlamps with Start Map LED Sweeping Light Guide','Star Map LED Connected Tail Lamps','R18 46.20 cm Crystal Cut Glossy Black Alloy Wheels','26.03 cm (10.25") HD Touchscreen Navigation with full Digital Cluster with 26.04 cm (10.25") Color LCD MID'], colors: ['#2B2C71', '#3E493B', '#B2001D', '#F3F9FB', '#35363A', '#353839','#E0E0DF','#151617','#B2001E'] },
  { title: 'GTX+', price: '₹ 19,99,900', features: ['ADAS 2.0 with 19 Features','Panoramic Sunroof', '360 Degree Camera Blind View Monitor in Cluster','BOSE Premium sound system with 8 speakers','Sequential LED Turn Indicators with Ice cube LED and Fog Lamps','R18 46.20 cm Crystal Cut Glossy Black Alloy Wheels','26.03 cm (10.25") HD Touchscreen Navigation with full Digital Cluster with 26.04 cm (10.25") Color LCD MID'], colors: ['#2B2C71', '#3E493B', '#B2001D', '#F3F9FB', '#35363A', '#353839','#E0E0DF','#151617','#B2001E'] },
  { title: 'XLine(S)', price: '₹ 19,64,900', features: ['ADAS 2.0 with 19 Features','Panoramic Sunroof', 'Crown jewel LED Headlamps with Start Map LED Sweeping Light Guide','Star Map LED Connected Tail Lamps','Sequential LED Turn Indicators with Ice cube LED and Fog Lamps','R18 46.20 cm Crystal Cut Glossy Black Alloy Wheels','26.03 cm (10.25") HD Touchscreen Navigation with full Digital Cluster with 26.04 cm (10.25") Color LCD MID'], colors: ['#262C30'] },
  { title: 'XLine', price: '₹ 20,44,900', features: ['ADAS 2.0 with 19 Features','Panoramic Sunroof', '360 Degree Camera Blind View Monitor in Cluster','BOSE Premium sound system with 8 speakers','Sequential LED Turn Indicators with Ice cube LED and Fog Lamps','R18 46.20 cm Crystal Cut Glossy Black Alloy Wheels','26.03 cm (10.25") HD Touchscreen Navigation with full Digital Cluster with 26.04 cm (10.25") Color LCD MID'], colors: ['#232528','#262C30'] },

];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [maxCardHeight, setMaxCardHeight] = useState(0);

  // Media queries for responsiveness
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const isTablet = useMediaQuery('(min-width:600px) and (max-width:1023px)');

  // Determine number of cards to display based on screen size
  const cardsToShow = isDesktop ? 3 : isTablet ? 2 : 1;

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < cardsData.length - cardsToShow) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  // Update max height of the cards when the content changes
  useEffect(() => {
    const maxHeight = Math.max(...Array.from(document.querySelectorAll('.card')).map(card => card.offsetHeight));
    setMaxCardHeight(maxHeight);
  }, [cardsData, currentIndex]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', minHeight: '600px' }}>
      {/* Headings */}
      <Typography variant="h4" fontWeight="bold" gutterBottom sx={{fontFamily:"KIAbodyLatinbasic_Bold"}}>
        Meet the Family
      </Typography>
      <Typography variant="h6" fontWeight="normal" gutterBottom sx={{fontFamily:"KIAbodyLatinbasic_Regular"}}>
        11 Trim Available
      </Typography>

      {/* Carousel */}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px', width: '100%' }}>
        {/* Left Arrow */}
        <Button
          onClick={handlePrev}
          disabled={currentIndex === 0}
          sx={{
            minWidth: 'auto',
            padding: '10px',
            marginRight: '10px',
            color: 'black',
            borderRadius: '50%',
            bgcolor:'#EFF1F2',
            '&:hover': { bgcolor: '#EFF1F2' },
            position: 'absolute',
            left: '0', 
          }}
        >
          <ArrowBackIosIcon fontSize="large" />
        </Button>

        {/* Card Display */}
        <Grid container spacing={2} sx={{ width: '100%', maxWidth: '900px', justifyContent: 'center' }}>
          {cardsData.slice(currentIndex, currentIndex + cardsToShow).map((card, index) => (
            <Grid item xs={12 / cardsToShow} key={index}>
              <Box
                className="card"
                sx={{
                  textAlign: 'center',
                  color: '#000',
                  borderRadius: '4px',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
                  border: '2px solid #ddd', 
                  height: '100%', 
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box
                  sx={{
                    textAlign: 'center',
                    backgroundColor: '#05141F',
                    color: '#fff',
                    padding: '10px',
                   
                  }}
                >
                  <Typography variant="h5" fontWeight="bold" gutterBottom sx={{ fontFamily:'KIAbodyLatinbasic_Bold'}}>
                    {card.title}
                  </Typography>
                  <Typography variant="h6" gutterBottom sx={{ fontFamily:'KIAbodyLatinbasic_Bold'}}>
                    {card.price}{' '}
                    <span style={{ fontSize: '12px', color: '#b0b0b0', fontWeight: '800', fontFamily:'KIAbodyLatinbasic_Bold' }}>
                      Starting MSRP*
                    </span>
                  </Typography>
                </Box>

                <Box
                  sx={{
                    textAlign: 'center',
                    color: '#000',
                    padding: '20px',
                    flex: '1', 
                  }}
                >
                  {/* Color Circles */}
                  <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                    {card.colors.map((color, colorIndex) => (
                      <Box
                        key={colorIndex}
                        sx={{
                          width: 15,
                          height: 15,
                          bgcolor: color,
                          borderRadius: '50%',
                          margin: '0 5px',
                        }}
                      ></Box>
                    ))}
                  </Box>

                  {/* Features List */}
                  <Typography variant="subtitle1" fontWeight="bold" sx={{ marginTop: '15px', fontFamily:'KIAbodyLatinbasic_Bold' }} gutterBottom>
                    Features
                  </Typography>
                  <ul style={{ textAlign: 'left', marginTop: '12px', listStyleType: 'none' , fontFamily:'KIAbodyLatinbasic_Bold'}}>
                    {card.features.map((feature, featureIndex) => (
                      <li key={featureIndex} style={{ marginBottom: '5px' }}>
                        {feature}
                      </li>
                    ))}
                  </ul>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>

        {/* Right Arrow */}
        <Button
          onClick={handleNext}
          disabled={currentIndex >= cardsData.length - cardsToShow}
          sx={{
            minWidth: 'auto',
            padding: '10px',
            marginLeft: '10px',
            borderRadius: '50%',
            bgcolor:'#EFF1F2',
            color:'black',
            '&:hover': { bgcolor: '#EFF1F2' },
            position: 'absolute',
            right: '0', 
          }}
        >
          <ArrowForwardIosIcon fontSize="large" />
        </Button>
      </Box>
    </Box>
  );
};

export default Carousel;
