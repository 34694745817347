import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const cardsData = [
  { title: 'HTE', price: '₹ 7,99,900', features: ['6 Airbags', 'ESC, VSM ,BA, HAC', 'High line Tyre Pressure Monitor', 'Advance 10.67 cm (4.2") Color instrument cluster', "Halogen Headlamps","Semi Leatherette Seats with All Black Interiors","Rear AC Vent"], colors: ['#35363A', '#2A2C2F', '#E0E0DF', '#B2001E', '#EBEEEF', '#3E493B','#2B2C71'] },
  { title: 'HTE(O)', price: '₹ 8,31,900', features: ['Electric Sunroof','6 Airbags', 'ESC, VSM ,BA, HAC',  'High line Tyre Pressure Monitoring','Advance 10.67cm (4.2")Color instrument cluster'," Headlamp Halogen","Rear AC Vent"], colors: ['#35363A', '#2A2C2F', '#B2001E', '#E0E0DF', '#EBEEEF','#2B2C71', '#3E493B',] },
  { title: 'HTK', price: '₹ 9,02,900', features: ['6 Airbags ', 'ESC, VSM ,BA, HAC', 'R16 40.56cm (16") Dual Tone Styled Wheels',"Front parking sensors",'20.32 cm (8.0") Touchscreen with Wireless Android Auto and Apple Carplay','Advance 10.67 cm (4.22") Color instrument cluster','Shark fin antenna'], colors: ['#35363A', '#2A2C2F', '#E0E0DF', '#B2001E', '#EBEEEF', '#3E493B','#2B2C71'] },
  { title: 'HTK(O)', price: '₹ 9,38,900', features: ['Electric Sunroof','Fully Automatic Temperature Control','Star Map Connected LED Tail','6 Airbags', 'ESC,HAC,BA,VSM', 'R16 40.56cm (16") Dual tone Styled wheels','Front Parking Sensors'], colors: ['#35363A', '#2A2C2F', '#E0E0DF', '#B2001E', '#EBEEEF','#2B2C71', '#3E493B'] },
  { title: 'HTK+', price: '₹ 10,74,900', features: ['6 Airbags ', 'Electric Sunroof-G1.0T only', 'Fully Automatic Air Conditioner','20.32 cm (8.0") Touchscreen with Wireless Android Auto and Apple Carplay','Star Map LED DRLs','Star Map LED Connected Taillamps',"Smart key with push button start"], colors: ['#35363A', '#2A2C2F', '#E0E0DF', '#B2001E', '#EBEEEF', '#3E493B','#2B2C71'] },
  { title: 'Gravity', price: '₹ 10,49,900', features: ['Das Cam with Dual Camera', 'Wireless charge', 'R16 40.56cm (16") Sporty Crystal Cut Alloy Wheels','Indigo perra Seats','6 Airbags','Electric Sunroof- G1.0T-GDi Smartstream iMT',"Smart key with push button start"], colors: ['#35363A', '#2A2C2F', '#E0E0DF', '#B2001E', '#EBEEEF', '#3E493B','#2B2C71'] },
  { title: 'HTX', price: '₹ 11,71,900', features: [ '6 Airbags ', 'Electric Sunroof','Crown jewel LED headlamps ','20.32 cm (8.0") Touchscreen with Wireless Android Auto and Apple Carplay','Rear Disc Brakes','Front ventillated seats- G1.0T-GDi Smartstream 7DCT and D1.5 CRDI VGT iMT/6AT','Black and Beige  Semi Leatherette Seats-G1.0T iMT and D1.5 MT'], colors: ['#35363A', '#2A2C2F', '#B2001E','#E0E0DF',  '#EBEEEF', '#3E493B','#2B2C71'] },
  { title: 'HTX+', price: '₹ 14,51,900', features: ['6 Airbags','Electric Sunroof', 'R16 40.56cm (16") Crystal Cut Alloy Wheels','Full Digital Cluster with 26.04cm (10.25") Color LCD MID','26.03 cm (10.25") HD Touchscreen Navigation with Wired Android Auto and Apple Carplay','Black and Brown Letherette Seats','BOSE Premium 7 speaker system'], colors: ['#B2001E','#F4F9FB','#35363A','#181A1D','#E0E0DF','#B2001E','#F4F9FB','#3E493B','#2B2C71'] },
  { title: 'GTX', price: '₹ 13,71,900', features: ['360 Degree Camera with Blind View Monitor in Cluster','R16 40.56cm (16") Sporty Crystal Cut Alloy Wheels', 'Full Digital Cluster with 26.04cm (10.25") Color LCD MID','26.03 cm (10.25") HD Touchscreen Navigation with Wired Android Auto and Apple Carplay','4-way Power Drivers Seat','Front Ventillated Seats','Smart Pure Air Purifier with Virus and Bacteria Protection'], colors: ['#35363A', '#2A2C2F', '#B2001E','#E0E0DF',  '#EBEEEF', '#3E493B','#2B2C71'] },
  { title: 'GTX+', price: '₹ 14,71,900', features: ['ADAS Level1 with 10 Autonomous Features','360 Degree Camera with Blind View Monitor in Cluster', 'R16 40.56cm (16") Sporty Crystal Cut Alloy Wheels','Full Digital Cluster with 26.04cm (10.25") Color LCD MID','26.03 cm (10.25") HD ','R18 46.20 cm Crystal Cut Glossy Black Alloy Wheels','26.03 cm (10.25") HD Touchscreen Navigation with Wired Android Auto and Apple Carplay', '4-way Power Drivers Seat','Front Ventillated Seats'], colors: ['#B2001E','#F4F9FB','#35363A','#181A1D','#E0E0DF','#B2001E','#F4F9FB','#3E493B','#2B2C71'] },

];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [maxCardHeight, setMaxCardHeight] = useState(0);

  // Media queries for responsiveness
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const isTablet = useMediaQuery('(min-width:600px) and (max-width:1023px)');

  // Determine number of cards to display based on screen size
  const cardsToShow = isDesktop ? 3 : isTablet ? 2 : 1;

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < cardsData.length - cardsToShow) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  // Update max height of the cards when the content changes
  useEffect(() => {
    const maxHeight = Math.max(...Array.from(document.querySelectorAll('.card')).map(card => card.offsetHeight));
    setMaxCardHeight(maxHeight);
  }, [cardsData, currentIndex]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', minHeight: '600px' }}>
      {/* Headings */}
      <Typography variant="h4" fontWeight="bold" gutterBottom sx={{fontFamily:"KIAbodyLatinbasic_Bold"}}>
        Meet the Family
      </Typography>
      <Typography variant="h6" fontWeight="normal" gutterBottom sx={{fontFamily:"KIAbodyLatinbasic_Regular"}}>
        11 Trim Available
      </Typography>

      {/* Carousel */}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px', width: '100%' }}>
        {/* Left Arrow */}
        <Button
          onClick={handlePrev}
          disabled={currentIndex === 0}
          sx={{
            minWidth: 'auto',
            padding: '10px',
            marginRight: '10px',
            color: 'black',
            borderRadius: '50%',
            bgcolor:'#EFF1F2',
            '&:hover': { bgcolor: '#EFF1F2' },
            position: 'absolute',
            left: '0', // Positioned on the left side
          }}
        >
          <ArrowBackIosIcon fontSize="large" />
        </Button>

        {/* Card Display */}
        <Grid container spacing={2} sx={{ width: '100%', maxWidth: '900px', justifyContent: 'center' }}>
          {cardsData.slice(currentIndex, currentIndex + cardsToShow).map((card, index) => (
            <Grid item xs={12 / cardsToShow} key={index}>
              <Box
                className="card"
                sx={{
                  textAlign: 'center',
                  color: '#000',
                  borderRadius: '4px',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
                  border: '2px solid #ddd', // Border added to card content
                  height: '100%', // Set height to 100%
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box
                  sx={{
                    textAlign: 'center',
                    backgroundColor: '#05141F',
                    color: '#fff',
                    padding: '10px',
                  }}
                >
                  <Typography variant="h5" fontWeight="bold" gutterBottom>
                    {card.title}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {card.price}{' '}
                    <span style={{ fontSize: '12px', color: '#b0b0b0', fontWeight: '800' }}>
                      Starting MSRP*
                    </span>
                  </Typography>
                </Box>

                <Box
                  sx={{
                    textAlign: 'center',
                    color: '#000',
                    padding: '20px',
                    flex: '1', // Allow the content to fill available space
                  }}
                >
                  {/* Color Circles */}
                  <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                    {card.colors.map((color, colorIndex) => (
                      <Box
                        key={colorIndex}
                        sx={{
                          width: 15,
                          height: 15,
                          bgcolor: color,
                          borderRadius: '50%',
                          margin: '0 5px',
                        }}
                      ></Box>
                    ))}
                  </Box>

                  {/* Features List */}
                  <Typography variant="subtitle1" fontWeight="bold" sx={{ marginTop: '15px',fontFamily:"KIAbodyLatinbasic_Bold" }} gutterBottom>
                    Features
                  </Typography>
                  <ul style={{ textAlign: 'left', marginTop: '12px', listStyleType: 'none', fontFamily:"KIAbodyLatinbasic_Regular"}}>
                    {card.features.map((feature, featureIndex) => (
                      <li key={featureIndex} style={{ marginBottom: '5px' }}>
                        {feature}
                      </li>
                    ))}
                  </ul>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>

        {/* Right Arrow */}
        <Button
          onClick={handleNext}
          disabled={currentIndex >= cardsData.length - cardsToShow}
          sx={{
            minWidth: 'auto',
            padding: '10px',
            marginLeft: '10px',
            borderRadius: '50%',
            bgcolor:'#EFF1F2',
            color:'black',
            '&:hover': { bgcolor: '#EFF1F2' },
            position: 'absolute',
            right: '0', 
          }}
        >
          <ArrowForwardIosIcon fontSize="large" />
        </Button>
      </Box>
    </Box>
  );
};

export default Carousel;
