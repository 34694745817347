import React, { Component } from "react";
import { connect } from "react-redux";
import Quatation from "../Components/quatation";

import {
  viewAllQuatation,
  uploadImaes,
  updateQuatationStatus,
  uploadupdatesImages,
  deleteQuatation,
} from "../action";
export class Controller extends Component {
  render() {
    return <Quatation {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    viewAllQuatation: (
      token,
      user_id,
      searchInput,
      page,
      pageSize,
      startDate,
      endDate,
      selectedStatus
    ) => {
      dispatch(
        viewAllQuatation(
          token,
          user_id,
          searchInput,
          page,
          pageSize,
          startDate,
          endDate,
          selectedStatus
        )
      );
    },

    updateQuatationStatus: (token, user_id, id, status, page, pageSize) => {
      dispatch(updateQuatationStatus(token, user_id, id, status, page, pageSize));
    },
    deleteQuatation: (token, user_id, id, page, pageSize) => {
      dispatch(deleteQuatation(token, user_id, id, page, pageSize));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
