import React from 'react';
import Slider from 'react-slick';
import { Box, Card, CardContent, CardMedia, Typography, IconButton, Grid } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import evcard21 from '../../../Image/Ev9/evcard21.jpg';
import evcard22 from '../../../Image/Ev9/evcard22.jpg';
import evcard23 from '../../../Image/Ev9/evcard23.jpg';
import evcard24 from '../../../Image/Ev9/evcard24.jpg';
import evcard25 from '../../../Image/Ev9/evcard25.jpg';

// Custom next arrow component
const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      sx={{
        position: 'absolute',
        right: '-5px', // reduced value to make it visible
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 2,
        color: 'black',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        '&:hover': { backgroundColor: 'rgba(255, 255, 255, 1)' },
      }}
    >
      <ArrowForwardIosIcon />
    </IconButton>
  );
};

// Custom previous arrow component
const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      sx={{
        position: 'absolute',
        left: '-5px', 
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 2,
        color: 'black',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        '&:hover': { backgroundColor: 'rgba(255, 255, 255, 1)' },
      }}
    >
      <ArrowBackIosIcon />
    </IconButton>
  );
};

// CarouselSection component
const CarouselSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const cards = [
    {
      image: evcard21,
      title: 'Shift-by-wire Selector',
      description:'Experience seamless gear shifts by turning the dial with the Shift-by-Wire selector. This advanced technology enhances driving precision while decluttering the cabin for a clean, modern interior feel',
    },
    {
      image: evcard22,
      title: 'Digital IRVM',
      description:"Experience enhanced visibility with the IRVM's integrated digital screen, offering a real-time rear camera view. Say goodbye to blind spots and enjoy safer, more confident driving, even in challenging conditions.",
    },
    {
      image: evcard23,
      title: 'Vehicle-to-load (V2L)',
      description:'V2L can be divided into two types: V2L inside (socket in the interior) and V2L outside (V2L connector in the charging socket). Both deliver a maximum output of 3.68 kW which can be used to charge or power electric devices or other electric vahicles.',
    },
    {
        image:evcard24,
        title:'Smart Power Tailgate',
        description:"Effortless access at the wave of a hand. The smart power tailgate iopens automatically, giving you hands-free convenience for loading and unloading with ease.",
    },
    {
        image:evcard25,
        title:'Meridian Premium Sound System with 14 Speakers',
        description:"With 14 precision-tuned Meridian speakers, the EV9 delivers immersive, crystal clear audio that transforms your cabin into personal soundstage.",
    },
  ];

  return (
    <Box
      sx={{
        width: '90%',
        margin: 'auto',
        marginTop: '40px',
        marginBottom: '40px',
        overflow: 'hidden',
      }}
    >
      <Slider {...settings}>
        {cards.map((card, index) => (
          <Grid key={index} item xs={12} md={4} sx={{ padding: '0 15px' }}>
            <Card
              sx={{
                padding: 2,
                boxShadow: 3,
                borderRadius: '8px',
                backgroundColor: '#fff',
                textAlign: 'center',
                height: '100%',
                '&:hover': {
                  boxShadow: 6,
                },
              }}
            >
              <CardMedia
                component="img"
                height="180"
                image={card.image}
                alt={card.title}
                sx={{
                  objectFit: 'cover',
                  borderRadius: '8px 8px 0 0',
                }}
              />
              <CardContent sx={{ padding: '20px', height: '200px' }}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    fontWeight: 'bold',
                    color: '#333',
                    fontSize: '18px',
                    marginBottom: '8px',
                    fontFamily:"KIAbodyLatinbasic_Bold"
                  }}
                >
                  {card.title}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    color: '#666',
                    fontSize: '14px',
                    fontFamily:"KIAbodyLatinbasic_Regular"
                  }}
                >
                  {card.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Slider>
    </Box>
  );
};

export default CarouselSection;
