import React, { useState, useEffect, useRef } from 'react';
import './Carousel.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Typography, Grid, Button } from '@mui/material';
import Epb from '../../../Image/Seltos/SE-EPB.jpg';
import bose from '../../../Image/Seltos/SE-Bose.jpg';
import seats from '../../../Image/Seltos/seats.jpg';
import screen from '../../../Image/Seltos/SE-Intutive.jpg';
import cam from '../../../Image/Seltos/SE-Dash.jpg';
import Sunroof from '../../../Image/Seltos/sunroof.jpg'
import lamp from '../../../Image/Seltos/lamp.jpg';
import scheme from '../../../Image/Seltos/scheme.jpg';
import speed from '../../../Image/Seltos/speed.jpg';
import x1 from '../../../Image/Seltos/slide-1-pc.jpg';
import x2 from '../../../Image/Seltos/slide-2-pc.jpg';
import x3 from '../../../Image/Seltos/slide-3-pc.jpg';
import x4 from '../../../Image/Seltos/slide-4-pc.jpg';
import x5 from '../../../Image/Seltos/slide-5-pc.jpg';
import x6 from '../../../Image/Seltos/slide-6-pc.jpg';
import ShowInterest from '../../../show interest/Container/showinterestCon';
import ext1 from '../../../Video/Seltos/ext1.mp4'
import ext2 from '../../../Image/Seltos/ext2.jpg'
import ext3 from '../../../Image/Seltos/ext3.jpg'
import ext4 from '../../../Image/Seltos/ext4.jpg';
import int1 from '../../../Video/Seltos/int1.mp4';
import int2 from '../../../Video/Seltos/int2.mp4';
import int3 from '../../../Image/Seltos/int3.jpg';
import int4 from '../../../Video/Seltos/int4.mp4';
import power1 from '../../../Image/Seltos/power1.jpg';
import power2 from '../../../Image/Seltos/power2.jpg';
import power3 from '../../../Image/Seltos/power3.jpg';
import Imt from '../../../Image/Seltos/imt.jpg'; 
import safe1 from '../../../Video/Seltos/safe1.mp4';
import safe2 from '../../../Image/Seltos/safety2.jpg'
import safe3 from '../../../Image/Seltos/safe3.jpg';
import safe4 from '../../../Image/Seltos/safe4.jpg'
import adas1 from '../../../Video/Seltos/adas1.mp4'
import adas2 from '../../../Image/Seltos/adas2.jpg';
import adas3 from '../../../Image/Seltos/adas3.jpg';
import adas4 from '../../../Video/Seltos/adas.mp4';
import tech1 from '../../../Image/Seltos/tech1.jpg';
import tech2 from '../../../Image/Seltos/tech2.jpg';
import tech3 from '../../../Image/Seltos/tech3.jpg';
import tech4 from '../../../Image/Seltos/tech4.jpg';
import Cardcarrousel from '../../../Cars/Seltos/Component/cardcarrousel'
import BookIcon from '@mui/icons-material/LibraryBooks';
import CallIcon from '@mui/icons-material/Call';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Seltosbrochure from '../../../Brochure/Seltos/Seltos.pdf'
import Faq from '../../../Cars/Seltos/Component/faqs';

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [currentIndex2, setCurrentIndex2] = useState(0);
  const [activeImageIndex2, setActiveImageIndex2] = useState(0);
  const [currentIndex3, setCurrentIndex3] = useState(0);
  const [activeImageIndex3, setActiveImageIndex3] = useState(0);
  const [currentIndex4, setCurrentIndex4] = useState(0);
  const [activeImageIndex4, setActiveImageIndex4] = useState(0);
  const [currentIndex5, setCurrentIndex5] = useState(0);
  const [activeImageIndex5, setActiveImageIndex5] = useState(0);
  const [currentIndex6, setCurrentIndex6] = useState(0);
  const [activeImageIndex6, setActiveImageIndex6] = useState(0);
  const [currentIndex7, setCurrentIndex7] = useState(0);
  const [activeImageIndex7, setActiveImageIndex7] = useState(0);
  const [currentIndex8, setCurrentIndex8] = useState(0);
  const [activeImageIndex8, setActiveImageIndex8] = useState(0);
  const [currentIndex9, setCurrentIndex9] = useState(0);
  const [activeImageIndex9, setActiveImageIndex9] = useState(0);
  const [visibleCount, setVisibleCount] = useState(3);

  const contents = [
    {
      title: 'Auto Hold Electronic Parking Brake (EPB)',
      description: 'Effortless parking with no second guessing to lock in with confidence.',
      image: Epb,
    },
    {
      title: 'BOSE Premium Sound System with 8 Speakers',
      description: 'Immerse yourself in crystal-clear sound that matches your drive’s intensity.',
      image: bose,
    },
    {
      title: 'Ventilated Front Seats',
      description: 'Unparalleled comfort, designed for every bold adventure.',
      image: seats,
    },
    {
      title: 'Intuitive HD Touch Screen',
      description: 'Experience smart, effortless, and responsive technology at your fingertips.',
      image: screen,
    },
    {
      title: 'Dash Cam with Dual Camera',
      description: 'Capture every untamed moment on the road with eyes on the wild at all times.',
      image: cam,
    },
  ];
  
    const contents2 = [
      {
        title: 'Dual-Pane Panoramic Sunroof',
        description: 'Redefine your perspective with scenic views that bring the world to you.',
        image: Sunroof,
      },
      {
        title: 'Star Map LED-Connected Tail Lamps',
        description: 'Illuminate paths with distinctively designed lights that demand attention.',
        image: lamp,
      },
      {
        title: 'Aurora Black Pearl Color Scheme',
        description: 'Stand out in sophisticated black that adds elegance to your adventurous rides.',
        image: scheme,
      },
      {
        title: '6-Speed IVT/AT Transmission',
        description: 'Command the road with seamless gear shifts tailored to your driving needs.',
        image: speed,
      },
     
    ];
  
    const contents3 = [
      {
        title: 'Signature Tiger Nose Grill-Matte Graphite',
        description: 'With its striking appearance and powerful stance, the Signature Tiger Nose Grille has been reincarnated to command attention.',
        image: x1,
      },
      {
        title: 'R-18 (46.20 cm) Crystal-Cut Glossy Black Alloy Wheels',
        description: 'The assertive crystal-cut alloy wheels of the new Seltos elevate its impressive appearance to newer heights.',
        image: x2,
      },
      {
        title: 'Dual Sports Exhaust (G1.5 T- Gdi)',
        description: 'With its newly added dual exhaust system, the Seltos X-Line now boasts a sportier look and sound.',
        image: x3,
      },
      {
        title: 'Dual-Pane Panoramic Sunroof',
        description: 'The panoramic sunroof of the new Seltos features two glass panels so that your journeys feel more immersive, open, and airy',
        image: x4,
      },
      {
        title:'All Black Interiors With Exclusive Sage Green Inserts',
        description:'The All-Black Interiors combined with Xclusive Sage Green inserts make it an unbeatable force to reckon with.',
        image:x5,
      },
      {
        title:'Smart 20.32 cm Heads Up Display',
        description:'A transparent and Smart 20.32 cm display projecting an image equivalent to an 8-inch screen onto the windshield for maximum visibility and convenience.',
        image:x6,
      }
     
    ];
  
    const contents4 = [
      {
        title: 'Distinctive Star Map LED Connected Tail Lamps',
        description: 'Drawing inspiration from the cosmic tapestry above, the distinctive Star Map LED Tail Lamps of the Seltos set new standards for cutting-edge design.',
        video: ext1,
      },
      {
        title: 'Striking R18 (46.20 cm) Crystal Cut Glossy Black Alloy Wheels',
        description: "Elevate your style quotient with the Seltos' Striking R18 Crystal Cut Alloy Wheels crafted with precision and featuring a glossy black finish to turn heads and conquer roads with an assertive spirit.",
        image: ext2,
      },
      {
        title: 'Dazzling Crown Jewel LED Headlamps with Star Map LED DRLs & Star Map Sweeping LED Light Guide',
        description: "The Seltos' Dazzling Crown Jewel LED Headlamps illuminate your path and create a captivating visual symphony. Sweeping Star Map LED Daytime Running Lights bring celestial companions to life, turning every drive into an artful journey.",
        image: ext3,
      },
      {
        title: 'Stylish Dual Sport Exhaust (G1.5T Only)',
        description: "The road becomes your playground with the Stylish Dual Sport Exhaust of the Seltos. Not just an addition, it's an attitude that defines the very essence of exhilaration.",
        image: ext4,
      },
      {
        title:'All Black Interiors With Exclusive Sage Green Inserts',
        description:'The All-Black Interiors combined with Xclusive Sage Green inserts make it an unbeatable force to reckon with.',
        image:x5,
      },
      {
        title:'Smart 20.32 cm Heads Up Display',
        description:'A transparent and Smart 20.32 cm display projecting an image equivalent to an 8-inch screen onto the windshield for maximum visibility and convenience.',
        image:x6,
      }
     
    ];
  
    const contents5 = [
      {
        title: 'Awe-inspiring Dual Pane Panoramic Sunroof',
        description: "Experience immersive journeys with Seltos' Dual Pane Panoramic Sunroof. Two expansive glass panels make the sky your companion, elevating every drive with wonder and freedom.",
        video: int1,
      },
      {
        title: 'Intuitive 26.03 cm (10.25’’) HD Touchscreen Navigation + Full Digital Cluster with 26.04 cm (10.25”) Color LCD MID',
        description: "Explore limitless possibilities with the Intuitive 26.03 cm HD Touchscreen and Digital Cluster. Dual panoramic screens immerse you in information and entertainment, captivating and functional at your fingertips.",
        video: int2,
      },
      {
        title: 'Comfortable Front Ventilated Seats with 8-way Power Driver’s Seat',
        description: "Indulge in luxury with Comfortable Front Ventilated Seats. Experience continuous airflow and embrace comfort, transforming each drive into rejuvenation.",
        image: int3,
      },
      {
        title: 'Kia Connect Skill with Amazon Alexa',
        description: "Experience the future of climate control with the Intelligent Dual Zone AC. Tailor your comfort with precision, making every ride an oasis of personalized relaxation.",
        video: int4,
      },
     
    ];
  
    const contents6 = [
      {
        title: 'Powerful Smartstream G1.5 T-GDi Petrol Engine',
        image: power1,
      },
      {
        title: 'Advanced Smartstream G1.5 Petrol Engine',
        image: power2,
      },
      {
        title: 'Refined 1.5l CRDi VGT Diesel Engine',
        image: power3,
      },
  
    ];
  
    const contents7 = [
      {
        title: 'OTA Map & System Update',
        description: "Through OTA Map & System Updates, the Badass is always in-sync with the future, ensuring you're always connected to the latest advancements.",
        image: tech1,
      },
      {
        title: '6 Airbags',
        description: 'Our commitment to your well-being extends in all directions, ensuring peace of mind for you and your loved ones.',
        image: tech2,
      },
      {
        title: 'Voice Controlled Window Function',
        description: "Experience the future of control with Voice Controlled Window Function. A simple command brings the world to your fingertips, making every journey effortless and truly hands-free.",
        image: tech3,
      },
      {
        title: 'Remote AC Control',
        description: "With Remote AC Control in the Seltos, your comfort begins the moment you step in, helping you stay ahead of the weather for a more welcoming experience.",
        image: tech4,
      },
  
    ];
  
    const contents8 = [
      {
        title: 'Smart Cruise Control with Stop and Go',
        description: "Smart Cruise Control with Stop and Go empowers you for effortless, stress-free travel on highways and urban streets.",
        video: adas4,
      },
      {
        title: 'Blind Spot Collision Warning',
        description: "The Blind Spot Collision Warning system keeps you informed and secure on the road. It's not just a feature; it's your invisible co-pilot, watching out for your well-being.",
        video: adas1,
      },
      {
        title: 'Front Collision Warning and Avoidance Assist (FCW and FCA)',
        description: 'Front Collision Warning and Avoidance Assist in the Seltos are your vigilant guardians, working tirelessly to prevent accidents and ensure your security on every drive.',
        image: adas2,
      },
      {
        title: 'Lane Keep Assist (LKA)',
        description: "Lane Keep Assist is your steadfast companion, ensuring your path remains true. Embrace the confidence of steady, safe journeys in your Seltos.",
        image: adas3,
      },
      
    ];
    
    const contents9 = [
      {
        title: '360º Camera with Blind View Monitor in Cluster',
        description: 'With the 360º Camera and Blind View Monitor, master the trickiest of parking spots with precision and confidence.',
        video: safe1,
      },
      {
        title: '6 Airbags',
        description: 'Our commitment to your well-being extends in all directions, ensuring peace of mind for you and your loved ones.',
        image: safe2,
      },
      {
        title: 'Electronic Stability Control (ESC), Hill Assist Control (HAC) & Vehicle Stability Management (VSM)',
        description: "Elevate your driving confidence with Electronic Stability Control, Hill Assist Control, and Vehicle Stability Management. These ensure your journeys are secure and your experiences are unforgettable. ",
        image: safe3,
      },
      {
        title: 'All Wheel Disc Brakes',
        description: "Discover the commanding performance of All Wheel Disc Brakes. With precision stopping at your command, you're not just driving a vehicle; you're mastering the road.",
        image: safe4,
      },
  
    ];
    
   
  useEffect(() => {
    const updateVisibleCount = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 1024) setVisibleCount(3);
      else if (screenWidth >= 768) setVisibleCount(2);
      else setVisibleCount(1);
    };

    updateVisibleCount();
    window.addEventListener('resize', updateVisibleCount);
    return () => {
      window.removeEventListener('resize', updateVisibleCount);
    };
  }, []);
 
  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + contents.length) % contents.length);
    setActiveImageIndex((prevIndex) => (prevIndex - 1 + contents.length) % contents.length);
  };

  const handlePrev2 = () => {
    setCurrentIndex2((prevIndex2) => (prevIndex2 - 1 + contents2.length) % contents2.length);
    setActiveImageIndex2((prevIndex2) => (prevIndex2 - 1 + contents2.length) % contents2.length);
  };

  const handlePrev3 = () => {
    setCurrentIndex3((prevIndex3) => (prevIndex3 - 1 + contents3.length) % contents3.length);
    setActiveImageIndex3((prevIndex3) => (prevIndex3 - 1 + contents3.length) % contents3.length);
  };

  const handlePrev4 = () => {
    setCurrentIndex4((prevIndex4) => (prevIndex4 - 1 + contents4.length) % contents4.length);
    setActiveImageIndex4((prevIndex4) => (prevIndex4 - 1 + contents4.length) % contents4.length);
  };

  const handlePrev5 = () => {
    setCurrentIndex5((prevIndex5) => (prevIndex5 - 1 + contents5.length) % contents5.length);
    setActiveImageIndex5((prevIndex5) => (prevIndex5 - 1 + contents5.length) % contents5.length);
  };

  const handlePrev6 = () => {
    setCurrentIndex6((prevIndex6) => (prevIndex6 - 1 + contents6.length) % contents6.length);
    setActiveImageIndex6((prevIndex6) => (prevIndex6 - 1 + contents6.length) % contents6.length);
  };

  const handlePrev7 = () => {
    setCurrentIndex7((prevIndex7) => (prevIndex7 - 1 + contents7.length) % contents7.length);
    setActiveImageIndex7((prevIndex7) => (prevIndex7 - 1 + contents7.length) % contents7.length);
  };

  const handlePrev8 = () => {
    setCurrentIndex8((prevIndex8) => (prevIndex8 - 1 + contents8.length) % contents8.length);
    setActiveImageIndex8((prevIndex8) => (prevIndex8 - 1 + contents8.length) % contents8.length);
  };
  const handlePrev9 = () => {
    setCurrentIndex9((prevIndex9) => (prevIndex9 - 1 + contents9.length) % contents9.length);
    setActiveImageIndex9((prevIndex9) => (prevIndex9 - 1 + contents9.length) % contents9.length);
  };


  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % contents.length);
    setActiveImageIndex((prevIndex) => (prevIndex + 1) % contents.length);
  };

  const handleNext2 = () => {
    setCurrentIndex2((prevIndex2) => (prevIndex2 + 1) % contents2.length);
    setActiveImageIndex2((prevIndex2) => (prevIndex2 + 1) % contents2.length);
  };

  const handleNext3 = () => {
    setCurrentIndex3((prevIndex3) => (prevIndex3 + 1) % contents3.length);
    setActiveImageIndex3((prevIndex3) => (prevIndex3 + 1) % contents3.length);
  };

  const handleNext4 = () => {
    setCurrentIndex4((prevIndex4) => (prevIndex4 + 1) % contents4.length);
    setActiveImageIndex4((prevIndex4) => (prevIndex4 + 1) % contents4.length);
  };

  const handleNext5 = () => {
    setCurrentIndex5((prevIndex5) => (prevIndex5 + 1) % contents5.length);
    setActiveImageIndex5((prevIndex5) => (prevIndex5 + 1) % contents5.length);
  };

  const handleNext6 = () => {
    setCurrentIndex6((prevIndex6) => (prevIndex6 + 1) % contents6.length);
    setActiveImageIndex6((prevIndex6) => (prevIndex6 + 1) % contents6.length);
  };

  const handleNext7 = () => {
    setCurrentIndex7((prevIndex7) => (prevIndex7 + 1) % contents7.length);
    setActiveImageIndex7((prevIndex7) => (prevIndex7 + 1) % contents7.length);
  };

  const handleNext8 = () => {
    setCurrentIndex8((prevIndex8) => (prevIndex8 + 1) % contents8.length);
    setActiveImageIndex8((prevIndex8) => (prevIndex8 + 1) % contents8.length);
  };
  const handleNext9 = () => {
    setCurrentIndex9((prevIndex9) => (prevIndex9 + 1) % contents9.length);
    setActiveImageIndex9((prevIndex9) => (prevIndex9 + 1) % contents9.length);
  };

  const getVisibleContents = () => {
    const items = [];
    for (let i = 0; i < visibleCount; i++) {
      items.push(contents[(currentIndex + i) % contents.length]);
    }
    return items;
  };

  const getVisibleContents2 = () => {
    const items = [];
    for (let i = 0; i < visibleCount; i++) {
      items.push(contents2[(currentIndex2 + i) % contents2.length]);
    }
    return items;
  };
  const getVisibleContents3 = () => {
    const items = [];
    for (let i = 0; i < visibleCount; i++) {
      items.push(contents3[(currentIndex3 + i) % contents3.length]);
    }
    return items;
  };
  const getVisibleContents4 = () => {
    const items = [];
    for (let i = 0; i < visibleCount; i++) {
      items.push(contents4[(currentIndex4 + i) % contents4.length]);
    }
    return items;
  };
  const getVisibleContents5 = () => {
    const items = [];
    for (let i = 0; i < visibleCount; i++) {
      items.push(contents5[(currentIndex5 + i) % contents5.length]);
    }
    return items;
  };
  const getVisibleContents6 = () => {
    const items = [];
    for (let i = 0; i < visibleCount; i++) {
      items.push(contents6[(currentIndex6 + i) % contents6.length]);
    }
    return items;
  };
  const getVisibleContents7 = () => {
    const items = [];
    for (let i = 0; i < visibleCount; i++) {
      items.push(contents7[(currentIndex7 + i) % contents7.length]);
    }
    return items;
  };
  const getVisibleContents8 = () => {
    const items = [];
    for (let i = 0; i < visibleCount; i++) {
      items.push(contents8[(currentIndex8 + i) % contents8.length]);
    }
    return items;
  };
  const getVisibleContents9 = () => {
    const items = [];
    for (let i = 0; i < visibleCount; i++) {
      items.push(contents9[(currentIndex9 + i) % contents9.length]);
    }
    return items;
  };


  const handleTextClick = (index) => {
    const actualIndex = (currentIndex + index) % contents.length;
    setActiveImageIndex(actualIndex);
  };

  const handleTextClick2 = (index) => {
    const actualIndex = (currentIndex2 + index) % contents2.length;
    setActiveImageIndex2(actualIndex);
  };

  const handleTextClick3 = (index) => {
    const actualIndex = (currentIndex3 + index) % contents3.length;
    setActiveImageIndex3(actualIndex);
  };
  const handleTextClick4 = (index) => {
    const actualIndex = (currentIndex4 + index) % contents4.length;
    setActiveImageIndex4(actualIndex);
  };
  const handleTextClick5 = (index) => {
    const actualIndex = (currentIndex5 + index) % contents5.length;
    setActiveImageIndex5(actualIndex);
  };
  const handleTextClick6 = (index) => {
    const actualIndex = (currentIndex6 + index) % contents6.length;
    setActiveImageIndex6(actualIndex);
  };
  const handleTextClick7 = (index) => {
    const actualIndex = (currentIndex7 + index) % contents7.length;
    setActiveImageIndex7(actualIndex);
  };
  const handleTextClick8 = (index) => {
    const actualIndex = (currentIndex8 + index) % contents8.length;
    setActiveImageIndex8(actualIndex);
  };
  const handleTextClick9 = (index) => {
    const actualIndex = (currentIndex9 + index) % contents9.length;
    setActiveImageIndex9(actualIndex);
  };

  return (
    <Box sx={{}}>

      {/*CAROUSEL1- GRAVITY */}
      <div className="carousel-container">
        <Box sx={{ position: 'relative', width: '100%', height: { md: '100%', xs: '100%' }, color: '#fff' }}>
          <Box sx={{ padding: 5 }}>
            <Typography variant="h6" color="#959191" sx={{ fontWeight: 'bold', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '18px', md: '22px' } }}>
              GRAVITY
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', marginTop: '10px', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '25px', md: '35px', sm: '32px' } }}>
            Take Off Like a Badass.
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 1, width: { md: '80%', xs: '90%' }, color: 'black', fontFamily: 'KIAbodyLatinbasic_Regular', fontSize: { xs: '14px', sm: '17px', md: '18px' } }}>
            The Seltos Gravity Variant is here to redefine what it means to drive with purpose. Bold, powerful, and equipped with cutting-edge features, this edition is designed for those who rise above the rest.​
            </Typography>
          </Box>
        </Box>

        {/* Navigation Section */}
        <div className="carousel-navigation">
          <button className="arrow-left" onClick={handlePrev} aria-label="Previous"><ArrowBackIosIcon /></button>
          <button className="arrow-right" onClick={handleNext} aria-label="Next"><ArrowForwardIosIcon /></button>
        </div>

        {/* Content Section */}
        <div className="carousel-content" >
          {getVisibleContents().map((content, index) => (
            <div key={index} className="carousel-item-container">
              <div
                className={`carousel-item ${activeImageIndex === (currentIndex + index) % contents.length ? 'active' : ''}`}
                onClick={() => handleTextClick(index)}
                style={{height:"150px"}}
              >
                <h3>{content.title}</h3>
                <p>{content.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Image Section */}
        <div className="carousel-image">
          <img src={contents[activeImageIndex].image} alt={contents[activeImageIndex].title} />
        </div>
      </div>

      {/*CAROUSEL2- All-new HTK+ */}
      <div className="carousel-container">
        <Box sx={{ padding: 5 }}>
          <Typography variant="h6" color="#959191" sx={{ fontWeight: 'bold', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '18px', md: '22px' }, textTransform:"uppercase"}}>
          All-new HTK+
          </Typography>
          <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', marginTop: '10px', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '25px', md: '35px', sm: '32px' } }}>
          Drive with the edge of Badass innovation and luxury.
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 1, width: { md: '80%', xs: '90%' }, color: 'black', fontFamily: 'KIAbodyLatinbasic_Regular', fontSize: { xs: '14px', sm: '17px', md: '18px' } }}>
          Redefining the segment with tech-driven luxury, the 2024 Kia Seltos HTK+ embodies the true spirit of
          the Badass. Decked out with cutting-edge features and an audacious attitude, it’s not just about driving; it’s about dominating the road.
          </Typography>
        </Box>

        {/* Navigation Section */}
        <div className="carousel-navigation">
          <button className="arrow-left" onClick={handlePrev2} aria-label="Previous"><ArrowBackIosIcon /></button>
          <button className="arrow-right" onClick={handleNext2} aria-label="Next"><ArrowForwardIosIcon /></button>
        </div>

        {/* Content Section */}
        <div className="carousel-content">
          {getVisibleContents2().map((content, index) => (
            <div key={index} className="carousel-item-container">
              <div
                className={`carousel-item ${activeImageIndex2 === (currentIndex2 + index) % contents2.length ? 'active' : ''}`}
                onClick={() => handleTextClick2(index)}
              >
                <h3>{content.title}</h3>
                <p>{content.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Image Section */}
        <div className="carousel-image">
          <img src={contents2[activeImageIndex2].image} alt={contents2[activeImageIndex2].title} />
        </div>
      </div>
      
       {/* CAROUSEL3-X-Line */}
       <div className="carousel-container">
        <Box sx={{ padding: 5 }}>
          <Typography variant="h6" color="#959191" sx={{ fontWeight: 'bold', fontFamily: "KIAbodyLatinbasic_Bold", fontSize: { xs: '18px', md: '22px' }, textTransform:"uppecase"}}>
          X-Line
          </Typography>
          <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', marginTop: '10px', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '25px', md: '35px', sm: '32px' } }}>
          Level up with the badass.
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 1, width: { md: '80%', xs: '90%' }, color: 'black', fontFamily: 'KIAbodyLatinbasic_Regular', fontSize: { xs: '14px', sm: '17px', md: '18px' } }}>
          The Seltos X-Line exudes confidence and power with its bold and aggressive design.
          Equipped with advanced features and offering an exhilarating driving experience, the new Seltos X-Line is designed to inspire and empower.​
          </Typography>
        </Box>

        {/* Navigation Section */}
        <div className="carousel-navigation">
          <button className="arrow-left" onClick={handlePrev3} aria-label="Previous"><ArrowBackIosIcon /></button>
          <button className="arrow-right" onClick={handleNext3} aria-label="Next"><ArrowForwardIosIcon /></button>
        </div>

        {/* Content Section */}
        <div className="carousel-content">
          {getVisibleContents3(contents3, currentIndex3).map((content, index) => (
            <div key={index} className="carousel-item-container">
              <div
                className={`carousel-item ${activeImageIndex3 === (currentIndex3 + index) % contents3.length ? 'active' : ''}`}
                onClick={() => handleTextClick3(index)}
                style={{height:'150px'}}
              >
                <h3>{content.title}</h3>
                <p>{content.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Image Section */}
        <div className="carousel-image">
          <img src={contents3[activeImageIndex3].image} alt={contents3[activeImageIndex3].title} />
        </div>
      </div>

      {/* SHOWINTEREST FORM Section */}
      <Box id="showinterest">
      <ShowInterest />
      </Box>

       {/* CAROUSEL4-EXTERIOR */}
       <div className="carousel-container">
        <Box sx={{ padding: 5 }}>
          <Typography variant="h6" color="#959191" sx={{ fontWeight: 'bold', fontFamily: "KIAbodyLatinbasic_Bold", fontSize: { xs: '18px', md: '22px' }, textTransform:"uppecase"}}>
          Exterior
          </Typography>
          <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', marginTop: '10px', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '25px', md: '35px', sm: '32px' } }}>
          Make a powerful statement
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 1, width: { md: '80%', xs: '90%' }, color: 'black', fontFamily: 'KIAbodyLatinbasic_Regular', fontSize: { xs: '14px', sm: '17px', md: '18px' } }}>
          Experience the bold new design of the Kia Seltos, exuding style and attitude. Its aggressive, muscular build, athletic shoulder lines, and stylized front and rear showcase its robust character. Get in and conquer any road.
          </Typography>
        </Box>

        {/* Navigation Section */}
        <div className="carousel-navigation">
          <button className="arrow-left" onClick={handlePrev4} aria-label="Previous"><ArrowBackIosIcon /></button>
          <button className="arrow-right" onClick={handleNext4} aria-label="Next"><ArrowForwardIosIcon /></button>
        </div>

        {/* Content Section */}
        <div className="carousel-content">
          {getVisibleContents4(contents4, currentIndex4).map((content, index) => (
            <div key={index} className="carousel-item-container">
              <div
                className={`carousel-item ${activeImageIndex4 === (currentIndex4 + index) % contents4.length ? 'active' : ''}`}
                onClick={() => handleTextClick4(index)}
                style={{height:'230px'}}
              >
                <h3>{content.title}</h3>
                <p>{content.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Image Section */}
         <div
            className="carousel-image"
            style={{
              position: "relative", 
              marginBottom: "20px", 
              width: "100%", 
              height: "auto",
            }}
          >
            {contents4[activeImageIndex4].video ? (
              <video
                key={activeImageIndex4}
                playsInline
                autoPlay
                loop
                muted
                preload="auto"
                style={{
                  position: "relative", 
                  width: "100%", 
                  height: "auto", 
                  objectFit: "cover", 
                }}
              >
                <source src={contents4[activeImageIndex4].video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={contents4[activeImageIndex4].image}
                alt={contents4[activeImageIndex4].title}
                style={{
                  width: "100%", 
                  height: "auto", 
                }}
              />
            )}
         </div>

      </div>  

     {/* CAROUSEL 5 - Interior */}
          <div className="carousel-container" >
            <Box sx={{ padding: 5 }}>
              <Typography variant="h6" color="#959191" sx={{ fontWeight: 'bold', fontFamily: "KIAbodyLatinbasic_Bold", fontSize: { xs: '18px', md: '22px' }, textTransform: "uppercase" }}>
                Interior
              </Typography>
              <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', marginTop: '10px', fontFamily: 'Kia-sig', fontSize: { xs: '25px', md: '35px', sm: '32px' } }}>
                Exquisitely designed, inside out.
              </Typography>
              <Typography variant="subtitle1" sx={{ mt: 1, width: { md: '80%', xs: '90%' }, color: 'black', fontFamily: 'Roboto, sans-serif', fontSize: { xs: '12px', sm: '15px', md: '16px' } }}>
                With its awe-inspiring interiors boasting stylish and edgy design, along with generous space for supreme comfort, the redefined new Kia Seltos becomes your ultimate travel companion.
                Parking Brake, Smart Pure Air Purifier with virus and bacteria protection, and Bose Premium Sound System with 8 Speakers, it redefines your driving experience with unparalleled comfort.
              </Typography>
            </Box>

            {/* Navigation Section */}
            <div className="carousel-navigation">
              <button className="arrow-left" onClick={handlePrev5} aria-label="Previous"><ArrowBackIosIcon /></button>
              <button className="arrow-right" onClick={handleNext5} aria-label="Next"><ArrowForwardIosIcon /></button>
            </div>

            {/* Content Section */}
            <div className="carousel-content">
              {getVisibleContents5(contents5, currentIndex5).map((content, index) => (
                <div key={index} className="carousel-item-container">
                  <div
                    className={`carousel-item ${activeImageIndex5 === (currentIndex5 + index) % contents5.length ? 'active' : ''}`}
                    onClick={() => handleTextClick5(index)}
                    style={{height:'250px'}}
                  >
                    <h3>{content.title}</h3>
                    <p>{content.description}</p>
                  </div>
                </div>
              ))}
            </div>

            {/* Image Section */}
            <div
            className="carousel-image"
            style={{
              position: "relative", 
              marginBottom: "20px", 
              width: "100%", 
              height: "auto",
            }}
          >
            {contents5[activeImageIndex5].video ? (
              <video
                key={activeImageIndex5}
                playsInline
                autoPlay
                loop
                muted
                preload="auto"
                style={{
                  position: "relative", 
                  width: "100%", 
                  height: "auto", 
                  objectFit: "cover", 
                }}
              >
                <source src={contents5[activeImageIndex5].video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={contents5[activeImageIndex5].image}
                alt={contents5[activeImageIndex5].title}
                style={{
                  width: "100%", 
                  height: "auto", 
                }}
              />
            )}
            </div>

          </div>

      {/* CAROUSEL 6 -POWER & PERFORMANCE */}
      <div className="carousel-container">
        <Box sx={{ position: 'relative', width: '100%', height: { md: '100%', xs: '100%' }, color: '#fff' }}>
          <Box sx={{ padding: 5 }}>
            <Typography variant="h6" color="#959191" sx={{ fontWeight: 'bold', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '18px', md: '22px' } }}>
            POWER & PERFORMANCE
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', marginTop: '10px', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '25px', md: '35px', sm: '32px' } }}>
            Unrivaled Power & Performance
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 1, width: { md: '80%', xs: '90%' }, color: 'black', fontFamily: 'KIAbodyLatinbasic_Regular', fontSize: { xs: '14px', sm: '17px', md: '18px' } }}>
            The new Kia Seltos delivers dynamic performance, elevating your driving experience. With advanced, powerful, and fuel-efficient engine options, it offers ultimate power and performance, empowering you to hit the road on your own terms.
            </Typography>
          </Box>
        </Box>

        {/* Navigation Section */}
        <div className="carousel-navigation">
          <button className="arrow-left" onClick={handlePrev6} aria-label="Previous"><ArrowBackIosIcon /></button>
          <button className="arrow-right" onClick={handleNext6} aria-label="Next"><ArrowForwardIosIcon /></button>
        </div>

        {/* Content Section */}
        <div className="carousel-content">
          {getVisibleContents6().map((content, index) => (
            <div key={index} className="carousel-item-container">
              <div
                className={`carousel-item ${activeImageIndex6 === (currentIndex6 + index) % contents6.length ? 'active' : ''}`}
                onClick={() => handleTextClick6(index)}
                style={{height:'70px'}}
              >
                <h3>{content.title}</h3>
                <p>{content.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Image Section */}
        <div className="carousel-image">
          <img src={contents6[activeImageIndex6].image} alt={contents6[activeImageIndex6].title} />
        </div>
      </div>
      
      {/* iMT */}
      <Box > 
      <Box 
        sx={{
          textAlign: 'left',
          paddingLeft: { xs: '20px', md: '50px' },
          marginTop:'30px'
        }}
      >
        <Typography variant="subtitle2" color="#959191" sx={{ fontWeight: 'bold', fontFamily:'KIAbodyLatinbasic_Bold', fontSize: { xs: '18px', md: '22px' } }}>
          iMT
        </Typography>

        <Typography 
          variant="h3" 
          sx={{ 
            fontWeight: 'bold', 
            marginTop:'6px', 
            fontWeight:'800',
            fontFamily:'KIAbodyLatinbasic_Bold',
            fontSize: { xs: '24px', md: '36px' } 
          }}
        >
          Badass 2.0 meets future of Manual Transmission
        </Typography>

        <Typography 
          variant="body1" 
          color="text.secondary" 
          sx={{ 
            fontSize: { xs: '14px', md: '16px' },
            width:'80%',
            fontFamily:'KIAbodyLatinbasic_Regular',
            marginTop: '6px'
          }}
        >
          Switch to the tech that inspires with the new Seltos. The Badass comes equipped with the convenient, fun, and exciting iMT technology that offers you hassle-free drive every time you hit the road.
        </Typography>

      </Box>

      <Box 
        sx={{
          backgroundImage: `url(${Imt})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: '100%',
          height: '300px', 
          mt: 3,
        }}
      />
      </Box>

      {/* CAROUSEL7- CONNECTED CAR TECHNOLOGY*/}
      <div className="carousel-container">
        <Box sx={{ position: 'relative', width: '100%', height: { md: '100%', xs: '100%' }, color: '#fff' }}>
          <Box sx={{ padding: 5 }}>
            <Typography variant="h6" color="#959191" sx={{ fontWeight: 'bold', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '18px', md: '22px' } }}>
            CONNECTED CAR TECHNOLOGY
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', marginTop: '10px', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '25px', md: '35px', sm: '32px' } }}>
            Take Control Like a boss.
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 1, width: { md: '80%', xs: '90%' }, color: 'black', fontFamily: 'KIAbodyLatinbasic_Regular', fontSize: { xs: '14px', sm: '17px', md: '18px' } }}>
            The new Kia Seltos offers integrated home-to-car connectivity through the Next-gen Kia Connect app.With Alexa and Google Voice Assistant, you can control your car using the app on your smartwatch or smartphone.Enjoy over 60 smart connected features for a fun and convenient drive.
            </Typography>
          </Box>
        </Box>

        {/* Navigation Section */}
        <div className="carousel-navigation">
          <button className="arrow-left" onClick={handlePrev7} aria-label="Previous"><ArrowBackIosIcon /></button>
          <button className="arrow-right" onClick={handleNext7} aria-label="Next"><ArrowForwardIosIcon /></button>
        </div>

        {/* Content Section */}
        <div className="carousel-content">
          {getVisibleContents7().map((content, index) => (
            <div key={index} className="carousel-item-container">
              <div
                className={`carousel-item ${activeImageIndex7 === (currentIndex7 + index) % contents7.length ? 'active' : ''}`}
                onClick={() => handleTextClick7(index)}
                style={{height:'160px'}}
              >
                <h3>{content.title}</h3>
                <p>{content.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Image Section */}
        <div className="carousel-image">
          <img src={contents7[activeImageIndex7].image} alt={contents7[activeImageIndex7].title} />
        </div>
      </div>


      {/* CAROUSEL8- ADAS LEVEL 2 */}
      <div className="carousel-container">
            <Box sx={{ padding: 5 }}>
              <Typography variant="h6" color="#959191" sx={{ fontWeight: 'bold', fontFamily: "KIAbodyLatinbasic_Bold", fontSize: { xs: '18px', md: '22px' }, textTransform: "uppercase" }}>
              ADAS LEVEL 2
              </Typography>
              <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', marginTop: '10px', fontFamily: 'Kia-sig', fontSize: { xs: '25px', md: '35px', sm: '32px' } }}>
              Safety maximized with intelligent ADAS Level 2
              </Typography>
              <Typography variant="subtitle1" sx={{ mt: 1, width: { md: '80%', xs: '90%' }, color: 'black', fontFamily: 'Roboto, sans-serif', fontSize: { xs: '12px', sm: '15px', md: '16px' } }}>
              The new Seltos enhances situational awareness by detecting vehicles, bicycles, and pedestrians using advanced sensing technology. It actively prevents collisions, ensures pedestrian safety, and offers a safer, more intuitive, and rewarding driving experience with its Advanced Driver Assistance System (ADAS) consisting of 19 Autonomous Level 2 features.
              </Typography>
            </Box>

            {/* Navigation Section */}
            <div className="carousel-navigation">
              <button className="arrow-left" onClick={handlePrev8} aria-label="Previous"><ArrowBackIosIcon /></button>
              <button className="arrow-right" onClick={handleNext8} aria-label="Next"><ArrowForwardIosIcon /></button>
            </div>

            {/* Content Section */}
            <div className="carousel-content">
              {getVisibleContents8(contents8, currentIndex8).map((content, index) => (
                <div key={index} className="carousel-item-container">
                  <div
                    className={`carousel-item ${activeImageIndex8 === (currentIndex8 + index) % contents8.length ? 'active' : ''}`}
                    onClick={() => handleTextClick8(index)}
                    style={{height:"180px"}}
                  >
                    <h3 style={{fontFamily:"KIAbodyLatinbasic_Bold"}}>{content.title}</h3>
                    <p style={{fontFamily:"KIAbodyLatinbasic_Regular"}}>{content.description}</p>
                  </div>
                </div>
              ))}
            </div>

            {/* Image Section */}
            <div
            className="carousel-image"
            style={{
              position: "relative", 
              marginBottom: "20px", 
              width: "100%", 
              height: "auto",
            }}
          >
            {contents8[activeImageIndex8].video ? (
              <video
                key={activeImageIndex8}
                playsInline
                autoPlay
                loop
                muted
                preload="auto"
                style={{
                  position: "relative", 
                  width: "100%", 
                  height: "auto", 
                  objectFit: "cover", 
                }}
              >
                <source src={contents8[activeImageIndex8].video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={contents8[activeImageIndex8].image}
                alt={contents8[activeImageIndex8].title}
                style={{
                  width: "100%", 
                  height: "auto", 
                }}
              />
            )}
         </div>
      </div>

       {/* CAROUSEL9- Safety */}
       <div className="carousel-container">
            <Box sx={{ padding: 5, }}>
              <Typography variant="h6" color="#959191" sx={{ fontWeight: 'bold', fontFamily: "KIAbodyLatinbasic_Bold", fontSize: { xs: '18px', md: '22px' }, textTransform: "uppercase" }}>
              Safety
              </Typography>
              <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', marginTop: '10px', fontFamily: 'Kia-sig', fontSize: { xs: '25px', md: '35px', sm: '32px' } }}>
              Unparalleled safety as standard.
              </Typography>
              <Typography variant="subtitle1" sx={{ mt: 1, width: { md: '80%', xs: '90%' }, color: 'black', fontFamily: 'Roboto, sans-serif', fontSize: { xs: '12px', sm: '15px', md: '16px' } }}>
              The new Seltos features a comprehensive range of 15 Robust Hi-Safety advanced features as part of its standard safety kit, allowing you to stay in control and respond to sudden impacts swiftly.
              </Typography>
            </Box>

            {/* Navigation Section */}
            <div className="carousel-navigation">
              <button className="arrow-left" onClick={handlePrev9} aria-label="Previous"><ArrowBackIosIcon /></button>
              <button className="arrow-right" onClick={handleNext9} aria-label="Next" ><ArrowForwardIosIcon /></button>
            </div>

            {/* Content Section */}
            <div className="carousel-content">
              {getVisibleContents9(contents9, currentIndex9).map((content, index) => (
                <div key={index} className="carousel-item-container">
                  <div
                    className={`carousel-item ${activeImageIndex9 === (currentIndex9 + index) % contents9.length ? 'active' : ''}`}
                    onClick={() => handleTextClick9(index)}
                    style={{height:"230px",display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center" }}
                  >
                    <h3>{content.title}</h3>
                    <p>{content.description}</p>
                  </div>
                </div>
              ))}
            </div>

            {/* Image Section */}
            <div
            className="carousel-image"
            style={{
              position: "relative", 
              marginBottom: "20px", 
              width: "100%", 
              height: "auto",
            }}
          >
            {contents9[activeImageIndex9].video ? (
              <video
                key={activeImageIndex9}
                playsInline
                autoPlay
                loop
                muted
                preload="auto"
                style={{
                  position: "relative", 
                  width: "100%", 
                  height: "auto", 
                  objectFit: "cover", 
                }}
              >
                <source src={contents9[activeImageIndex9].video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={contents9[activeImageIndex9].image}
                alt={contents9[activeImageIndex9].title}
                style={{
                  width: "100%", 
                  height: "auto", 
                }}
              />
            )}
         </div>
      </div>


       {/* Info */}
      <Box sx={{ padding: '50px 0', textAlign: 'center' }}>
        <Typography 
          variant="h5" 
          color="text.secondary" 
          sx=
          {{ 
            fontWeight: 'bolder', 
            mb: 5,
            fontFamily:'KIAbodyLatinbasic_Bold',
          }}
          >
          DETAILED INFORMATION
          </Typography>

        <Grid container spacing={4} justifyContent="center">

          {/* Download Brochure Card */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                bgcolor: '#0b1d28',
                color: '#fff',
                padding: '20px',
                borderRadius: '4px',
              }}
            >
              <PictureAsPdfIcon sx={{ fontSize: 60, color: 'white', mb: 2 }} />
              <Typography variant="h6" fontWeight="bold" sx={{fontFamily:'KIAbodyLatinbasic_Bold'}}>
                Download Brochure
              </Typography>
              <Typography color="white" sx={{ mb: 2, fontFamily:'KIAbodyLatinbasic_Regular' }}>
                Get all what you want to know about the Badass Reborn.
              </Typography>
              <Button 
                variant="contained" 
                color="secondary" 
                onClick={() => {
                  const link = document.createElement('a');
                  link.href = Seltosbrochure; 
                  link.download = 'seltos_brochure.pdf';
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }}
                sx={{ fontFamily:'KIAbodyLatinbasic_Bold',fontWeight:800}}
              >
                Download
              </Button>
            </Box>
          </Grid>

          {/* Call Us Card */}
          <Grid item xs={12} md={4}>
            <Box>
              <CallIcon sx={{ fontSize: 60, color: 'black', mb: 2 }} />
              <Typography variant="h6" fontWeight="bold" fontFamily='KIAbodyLatinbasic_Bold'>
                Call us for more information
              </Typography>
              <Typography color="text.secondary" sx={{ mb: 1, fontFamily:'KIAbodyLatinbasic_Regular' }}>
                We’ll be happy to answer all your queries.
              </Typography>
              <Typography variant="subtitle1" fontWeight="bold"  fontFamily='KIAbodyLatinbasic_Bold'>
              09237386213
              </Typography>
              <Button 
                variant="outlined" 
                color="inherit" 
                sx={{ fontFamily:'KIAbodyLatinbasic_Bold',fontWeight:800}}
                onClick={() => {
                  const isMobile = /Mobi|Android/i.test(navigator.userAgent);
                  if (isMobile) {
                    window.location.href = 'tel:09237386213';
                  } else {
                    alert('Please call 09237386213 for more information.');
                  }
                }}
              >
                Call Us
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* cardcarrousel */}
      <Cardcarrousel/>

      {/* Faq */}
      <Faq/>
      
    </Box>
  );
};

export default Carousel;
