import React, { useState } from 'react';
import { Box, Grid, Typography, IconButton, Divider } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import image1 from '../../../Image/Seltos/seltos1.jpg';
import image2 from '../../../Image/Seltos/seltos2.jpg';

// Array of image data with corresponding descriptions
const images = [
  {
    src: image1,
    descriptions: [
      'ADAS Level 2 with 19 Autonomous Features',
      'Dual Pane Panoramic Sunroof',
      'Best-in class Power',
    ],
  },
  {
    src: image2,
    descriptions: [
      'Signature Tiger Nose Grill-Matte Graphite',
      'Dual Sports Exhaust (G1.5 T-Gdi)',
      'Smart 20.32 cm Head Up Display',
    ],
  },
];

const ImageCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: { xs: '300px', md: '500px' },
        textAlign: 'center',
        overflowX: 'hidden',
      }}
    >
      <Grid container sx={{ height: '100%' }}>
        <Grid
          item
          xs={12}
          sx={{
            backgroundImage: `url(${images[currentIndex].src})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          {/* Description Section */}
          <Box
            sx={{
              position: 'absolute',
              bottom: '10%',
              left: '50%',
              transform: 'translateX(-50%)',
              color: '#fff',
              textShadow: '1px 1px 4px rgba(0, 0, 0, 0.8)',
              width: '90%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection:{xs:'column',sm:'row'},
                justifyContent: 'center',
                gap: {md:12,xs:'5px'},
              }}
            >
              {images[currentIndex].descriptions.map((text, index) => (
                <React.Fragment key={index}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: { xs: '12px', sm: '18px', md: '20px' },
                      width:{md:'280px',sm:'200px'},
                      fontWeight: 'bolder',
                      fontFamily:"KIAbodyLatinbasic_Bold",
                      lineHeight:{xs:"15.2px",sm:"20.2px"}
                    }}
                  >
                    {text}
                  </Typography>
                  {index < images[currentIndex].descriptions.length - 1 && (
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ backgroundColor: '#fff', height: '90px',display:{xs:'none',sm:'flex'} }}
                    />
                  )}
                </React.Fragment>
              ))}
            </Box>
          </Box>
        </Grid>

        {/* Left Arrow */}
        <IconButton
          onClick={handlePrev}
          sx={{
            position: 'absolute',
            left: { xs: '10px', md: '40px', lg: '70px' },
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            color: '#fff',
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
          }}
        >
          <ArrowBackIos />
        </IconButton>

        {/* Right Arrow */}
        <IconButton
          onClick={handleNext}
          sx={{
            position: 'absolute',
            right: { xs: '10px', md: '40px', lg: '70px' },
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            color: '#fff',
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
          }}
        >
          <ArrowForwardIos />
        </IconButton>
      </Grid>
    </Box>
  );
};

export default ImageCarousel;
