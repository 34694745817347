import { Component } from "react";
import React from "react";
import "./Service.css";
import {
  Box,
  Typography,
  Grid,
  Button,
  Container,
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
// import Navbar from "../../Home/Components/Nav";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      mobile: "",
      model: "",
      carRegisterationNumber: "",
      prefferedServiceCenterLocation: "",
      preferredDateforService: "",
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      name,
      email,
      mobile,
      model,
      carRegisterationNumber,
      prefferedServiceCenterLocation,
      preferredDateforService,
    } = this.state;
    this.props.AddServiceForm(
      name,
      email,
      mobile,
      model,
      carRegisterationNumber,
      prefferedServiceCenterLocation,
      preferredDateforService
    );
    this.setState({
      name: "",
      email: "",
      mobile: "",
      model: "",
      carRegisterationNumber: "",
      prefferedServiceCenterLocation: "",
      preferredDateforService: "",
    });
  };
  render() {
    const {
      name,
      email,
      mobile,
      model,
      carRegisterationNumber,
      prefferedServiceCenterLocation,
      preferredDateforService,
    } = this.state;
    return (
      <div id="container" style={{ overflowX: "hidden" }}>
        {/* Banner */}
        <div id="banner-image-service"></div>
        {/* CONTENT */}
        <Grid
          container
          spacing={4}
          sx={{ width: "90%", margin: { md: "20px", xs: "10px" } }}
        >
          {/* Title Section */}
          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h1"
              sx={{ fontFamily: "KIAbodyLatinbasic_Bold" }}
              gutterBottom
            >
              Central Kia Service Appointment Booking
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontFamily: "KIAbodyLatinbasic_Regular" }}
            >
              Welcome to Central Kia’s Service Appointment Booking page, your
              first step to ensuring your Kia vehicle receives top-notch care.
              Our experienced technicians are dedicated to providing exceptional
              service to keep your vehicle running smoothly. Whether you need
              routine maintenance, repairs, or a thorough inspection, we’re here
              to help.
            </Typography>
          </Grid>

          {/* Steps Section */}
          <Grid item xs={12}>
            <Typography
              variant="h5"
              component="h2"
              sx={{ fontFamily: "KIAbodyLatinbasic_Bold" }}
            >
              How to Book Your Service Appointment
            </Typography>
            <ol>
              <li>
                <Typography
                  variant="body1"
                  sx={{
                    marginTop: "10px",
                    fontFamily: "KIAbodyLatinbasic_Bold",
                  }}
                >
                  <strong>Fill Out the Form:</strong> Complete our simple online
                  booking form with your contact information, vehicle details,
                  and service needs.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{
                    marginTop: "5px",
                    fontFamily: "KIAbodyLatinbasic_Regular",
                  }}
                >
                  <strong>Select a Date and Time:</strong> Choose a convenient
                  date and time for your appointment.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{
                    marginTop: "5px",
                    paddingBottom: "20px",
                    fontFamily: "KIAbodyLatinbasic_Regular",
                  }}
                >
                  <strong>Confirmation:</strong> Receive a confirmation call
                  from us for your service booking with all the details of your
                  appointment.
                </Typography>
              </li>
            </ol>
          </Grid>
        </Grid>

        {/*  FORM SECTION*/}
        <Container
          maxWidth="md"
          style={{ paddingBottom: "20px", backgroundColor: "#F9F9F9" }}
        >
          <Box sx={{ textAlign: "center", mb: 4 }}>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: "6vw", md: "2.38333vw" },
                fontFamily: "KIAbodyLatinbasic_Bold",
                fontWeight: "800",
                marginTop: "15px",
              }}
            >
              Service Appointment Form
            </Typography>
          </Box>

          <form onSubmit={this.handleSubmit}>
            <Box noValidate autoComplete="off" sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                {/* Name */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    label="Enter Your Name"
                    type="text"
                    variant="standard"
                    name="name"
                    InputLabelProps={{
                      sx: { "& .MuiInputLabel-asterisk": { color: "#d32f2f" } },
                    }}
                    value={this.state.name}
                    onChange={(e) => {
                      this.setState({ name: e.target.value });
                    }}
                  />
                </Grid>

                {/* Email */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    label="Enter Your Email"
                    type="email"
                    variant="standard"
                    name="email"
                    InputLabelProps={{
                      sx: { "& .MuiInputLabel-asterisk": { color: "#d32f2f" } },
                    }}
                    value={this.state.email}
                    onChange={(e) => {
                      this.setState({ email: e.target.value });
                    }}
                  />
                </Grid>

                {/* Mobile Number */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    label="Mobile Number"
                    type="number"
                    variant="standard"
                    name="mobile"
                    InputLabelProps={{
                      sx: { "& .MuiInputLabel-asterisk": { color: "#d32f2f" } },
                    }}
                    value={this.state.mobile}
                    onChange={(e) => {
                      this.setState({ mobile: e.target.value });
                    }}
                  />
                </Grid>

                {/* Model Dropdown */}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required variant="standard">
                    <InputLabel
                      sx={{ "& .MuiInputLabel-asterisk": { color: "#d32f2f" } }}
                    >
                      Model
                    </InputLabel>
                    <Select
                      label="Model"
                      name="model"
                      value={this.state.model}
                      onChange={(e) => {
                        this.setState({ model: e.target.value });
                      }}
                    >
                      <MenuItem value="seltos">Seltos</MenuItem>
                      <MenuItem value="sonet">Sonet</MenuItem>
                      <MenuItem value="carens">Carens</MenuItem>
                      <MenuItem value="ev6">EV6</MenuItem>
                      <MenuItem value="carnival">Carnival</MenuItem>
                      <MenuItem value="ev9">EV9</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/*Registeration number */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    label="Car Registration Number "
                    type="text"
                    variant="standard"
                    placeholder="e.g: OD39ZZ5697"
                    name="carRegisterationNumber"
                    InputLabelProps={{
                      sx: { "& .MuiInputLabel-asterisk": { color: "#d32f2f" } },
                    }}
                    value={this.state.carRegisterationNumber}
                    onChange={(e) => {
                      this.setState({ carRegisterationNumber: e.target.value });
                    }}
                  />
                </Grid>

                {/* center */}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required variant="standard">
                    <InputLabel
                      sx={{ "& .MuiInputLabel-asterisk": { color: "#d32f2f" } }}
                    >
                      Preferred Service Center Location
                    </InputLabel>
                    <Select
                      label="Preferred Service Center Location"
                      name="prefferedServiceCenterLocation"
                      value={this.state.prefferedServiceCenterLocation}
                      onChange={(e) => {
                        this.setState({
                          prefferedServiceCenterLocation: e.target.value,
                        });
                      }}
                    >
                      <MenuItem value="bbsr">Bhubaneswar</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* date of service */}
                <Grid item xs={12} sm={12}>
                  <TextField
                    margin="dense"
                    label="Preffered Date for Service"
                    name="date"
                    type="datetime-local"
                    fullWidth
                    value={preferredDateforService}
                    onChange={(e) => {
                      this.setState({
                        preferredDateforService: e.target.value,
                      });
                    }}
                    InputLabelProps={{
                      shrink: true,
                      sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                    }}
                    required
                  />
                </Grid>

                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      color: "white",
                      backgroundColor: "#414141",
                      fontFamily: "KIAbodyLatinbasic_Bold",
                      "&:hover": {
                        backgroundColor: "#414141",
                      },
                    }}
                    size="large"
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Container>
      </div>
    );
  }
}

export default Contact;
