import React, { Component } from "react";
import { connect } from "react-redux";
import Showinterest from "../Components/showinterest";

import { kepInTouch } from "../../../Client/action";

export class Controller extends Component {
  render() {
    return <Showinterest {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    kepInTouch: (
      firstName,
      lastName,
      mobile,
      email,
      pincode,
      city,
      dealer,
      consentChecked,
      carType
    ) => {
      dispatch(
        kepInTouch(
          firstName,
          lastName,
          mobile,
          email,
          pincode,
          city,
          dealer,
          consentChecked,
          carType
        )
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
