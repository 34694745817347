import React, { useState } from 'react';
import { Container, Grid, Box, Button, Typography, Collapse, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const styles = {
  questionBox: {
    padding:'10px',
    width: '80vw',
    borderRadius: '4px',
    marginBottom: '10px',
    display: 'flex',
    fontFamily: 'KIAbodyLatinbasic_Regular',
    flexDirection: 'column',
    // fontSize:{xs:'18px'},
  },
  questionHeader: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'KIAbodyLatinbasic_Regular',
    justifyContent: 'space-between',
    color: 'black',
  },
  expandableContent: {
    padding: '5px',
  },
};

function QuestionBox({ question, content, expanded, onToggle }) {
  return (
    <Box sx={styles.questionBox}>
      <Box sx={styles.questionHeader}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'KIAbodyLatinbasic_Bold',
            fontSize: {md:'1.3vw',xs:'2.9vw',sm:'2.4vw'},
            backgroundColor: 'transparent',
            color: '#000',
            paddingRight: '4%',
            paddingLeft: '0',
            fontWeight: '750',
          }}
        >
          {question}
        </Typography>
        <Button onClick={onToggle} sx={{ color: 'black' }}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
      </Box>

      <Collapse in={expanded}>
        <Box sx={styles.expandableContent}>
          <Typography
            variant="body2"
            sx={{
              fontFamily: 'KIAbodyLatinbasic_Regular',
              fontSize: {md:'1.1vw',xs:'2.8vw',sm:'2.4vw'},
            }}
          >
            {content}
          </Typography>
        </Box>
      </Collapse>
    </Box>
  );
}


export default function App() {
  const [expanded, setExpanded] = useState(null);

  const contents = [
   
'Kia Sonet starts at ₹7 99 900*(Ex-showroom price all India).',
"The ADAS Level 1 in the new Sonet comes with 10 autonomous features to make your journey a wild one!",
"The new Sonet comes with Full Digital Cluster with 26.04 cm (10.25’’) Color LCD MID offering detailed car information.",
'The new Sonet offers 6 Airbags (Front, Side & Curtain Airbags) standard to ensure the safety of you and your loved ones.',
'The new Sonet compact SUV comes with Electric Sunroof that gives you a chance to connect with the outside world.',
'The new Sonet SUV car is available in both manual and automatic transmissions, namely 5MT, 6MT, 6iMT, 7DCT, 6AT',
  ];

  const handleToggle = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <Container>
      <Box
  sx={{
    width: '100%',
    height:'100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }}
>
  <Typography
    variant="h2"
    sx={{
      fontSize: { md: '3.08333vw', xs: '4.7vw' },
      fontFamily: 'KIAbodyLatinbasic_Bold',
      paddingBottom: { md: '3.125vw', xs: '5.4vw' },
      fontWeight: '900',
      marginTop: { xs: '50px' },
    }}
  >
    Frequently Asked Questions
  </Typography>
  <Grid container spacing={1}>
    <Grid item xs={12} md={12}>
      <Box
        sx={{
          fontFamily: 'KIAbodyLatinbasic_Bold',
          // fontSize: { md: '5.55556vw' },
          backgroundColor: 'transparent',
          color: '#000',
          paddingRight: '4%',
          paddingLeft: '0',
        }}
      >
        {[
          'What is the price of the new Sonet?',
          'How many features does the new Sonet offer in its ADAS system?', 
          'What kind of cluster is available in the new Sonet?',
          'How many airbags are there in the new Sonet?', 
          'What type of sunroof comes with the new Sonet?',
          'What are the transmission options available in the new Sonet?',

        ].map((question, index) => (
          <React.Fragment key={index}>
            <QuestionBox
              question={question}
              content={contents[index]}
              expanded={expanded === index}
              onToggle={() => handleToggle(index)}
            />
            {index < contents.length - 1 && (
              <Divider
                sx={{
                  margin: '10px 0',
                  backgroundColor: 'black',
                  height: '2px',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                }}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
    </Grid>
  </Grid>
</Box>

    </Container>
  );
}
