import React from 'react';
import {
  Typography,
  Button,
  Box,
  Grid,
  Card,
} from '@mui/material';
import bannerImage from '../../../Image/carens1.jpg'
import DriveEtaIcon from '@mui/icons-material/DriveEta'; 
import DescriptionIcon from '@mui/icons-material/Description'; 
import Slidingcarens from '../../../Cars/Carens/Component/Slidingcarens';
import Carensfullimage from '../../../Image/Carens/carensfullimage.jpg'
import Contentcarrousel from '../../../Cars/Carens/Component/Contentcarrousel';
import Carensbrochure from '../../../Brochure/Carens/Carens.pdf'
import Footer from '../../../Footer/Component/Footer';
import Carens from '../../../Image/Carens/Carens-BS-1.jpg'
import ColorCarens from './color'

const Caren = () => {

  return (
    <Box sx={{overflowX:'hidden'}}>
        <Box sx={{ position: 'relative', height:  {md:'100vh',sm:'100vh',xs:'50vh'}, overflow: 'hidden',marginTop:'55px' }}>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height:  {md:'100vh',sm:'100vh',xs:'50vh'},
            backgroundImage: `url(${bannerImage})`, 
            backgroundSize: 'cover', 
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat', 
          }}
        >
          <Typography
            variant="h5"
            sx={{
              position: 'absolute', 
              bottom: 20, 
              left: '50%', 
              transform: 'translateX(-50%)', 
              color: 'white',
              padding: '8px 16px', 
              borderRadius: 2,
              fontFamily:'KIAbodyLatinbasic_Bold',
              paddingBottom:'20px',
              fontSize:{md:'35px',sm:'32px',xs:'25px'}
            }}
          >
            Kia Carens
          </Typography>
          <Typography
            variant="body1"
            sx={{
              position: 'absolute', 
              bottom: 1, 
              left: {xs:'58%',md:'50%',sm:'52%'}, 
              width:'260px',
              transform: 'translateX(-48%)', 
              color: 'white',
              padding: '8px 16px', 
              borderRadius: 2,
              fontSize:{md:'20px',sm:'18px',xs:'17px'},
              fontFamily:"KIAbodyLatinbasic_Bold"
            }}
          >
              From a different world
          </Typography>
        </Box>


        </Box>

        <Box sx={{backgroundColor:'#FFFFFF'}}>
          
            {/* Secord card section */}
            <Box sx={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} sm={12} md={6}>
                        <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', boxShadow: 3 ,gap:'15px'}}>
                            <DriveEtaIcon sx={{ fontSize: '40px', color: '#000'  }} />
                            <Typography variant="h6" sx={{ marginTop: '10px',fontWeight:'bold',fontFamily:"KIAbodyLatinbasic_Bold" }}>
                            Register your interest
                            </Typography>
                            <Typography variant="body1" sx={{color:'#848484',fontSize:'13px',fontFamily:"KIAbodyLatinbasic_Regular"}}>Fill in the required details by clicking here, We will contact you as soon as possible.</Typography>
                            <Button
                                variant="contained"
                                sx={{
                                  marginTop: '10px',
                                  backgroundColor: "#E50063",
                                  color: 'white',
                                  borderRadius: '8px',
                                  height: '50px',
                                  fontFamily:"KIAbodyLatinbasic_Bold",
                                  fontWeight:800,
                                  '&:hover':{
                                    backgroundColor: "#E50063",
                                  }
                                }}
                                onClick={() => {
                                  const element = document.getElementById("showinterest");
                                  if (element) {
                                    element.scrollIntoView({ behavior: "smooth" });
                                  }
                                }}
                              >
                                Click Here
                            </Button>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', boxShadow: 3,gap:'15px' }}>
                            <DescriptionIcon sx={{ fontSize: '40px', color: '#000' }} />
                            
                                <Typography variant="h6" sx={{ marginTop: '10px',fontWeight:'bold',fontFamily:"KIAbodyLatinbasic_Bold" }}>
                                Download Brochure
                                </Typography>
                             
                                <Typography variant='body1' sx={{color:'#848484',fontSize:'13px',fontFamily:"KIAbodyLatinbasic_Regular"}}>Click here to download the Brochure and price list of all new Carens on your device</Typography>
                                <Button
                          variant="contained"
                          sx={{
                            marginTop: '10px',
                            backgroundColor: "#E50063",
                            color: 'white',
                            borderRadius: '8px',
                            height: '50px',
                            fontFamily: '"KIAbody Latin Basic Bold", Arial, sans-serif',
                            fontWeight:800,
                            '&:hover':{
                              backgroundColor: "#E50063",
                            }
                          }}
                          component="a"     
                          href={Carensbrochure} 
                          download="carens_brochure.pdf"  
                        >
                          Click To Download
                        </Button>
                        </Card>
                    </Grid>
                </Grid>
            </Box>

            {/* Content Section */}
            <Box sx={{ padding: '40px 20px' }}>
              <Grid
                container
                sx={{
                  display: 'flex',
                  flexDirection: 'column', 
                  alignItems: 'center',
                }}
              >
                {/* Description*/}
                <Grid item xs={12}>
                  <Box sx={{ padding: { xs: '10px',sm:'20px', md: '25px' } }}>
                  <Typography
                      variant="h4"
                      sx={{
                        marginBottom: '20px',
                        fontSize: { xs: '16px', md: '18px' },
                        color:'#959191',
                        paddingLeft:{md:6, xs:2,sm:4},
                        paddingRight:'40px',
                        fontFamily:"KIAbodyLatinbasic_Bold",
                        fontWeight:800,
                      }}
                    >
                         The futuristic journey begins
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{
                        marginBottom: '20px',
                        fontSize: { xs: '24px', md: '30px' },
                         paddingLeft:{md:6, xs:2,sm:4},
                        paddingRight:'40px',
                        fontFamily:"KIAbodyLatinbasic_Bold",
                        fontWeight:'bolder'
                      }}
                    >
                        Welcome to a world of infinite choices
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: '16px', md: '20px',sm:'18px' },
                        marginBottom: '20px',
                        paddingLeft:{md:6, xs:2,sm:4},
                        paddingRight:'70px',
                        fontFamily:'KIAbodyLatinbasic_Regular'
                      }}
                    >
                      Future calls out to those who are from a different world. The Kia Carens is hi-tech by design and inspiring by nature. It is futuristic sophistication clothed in bold and edgy armor with many class-leading features. So get ready to step into a different world.
                    </Typography>
                  </Box>
                </Grid>

                {/* Image Section */}
                <Grid item xs={12}>
                  <Box
                    component="img"
                    src={Carens}
                    alt="Car Model"
                    sx={{
                      width: '100%',
                      height: 'auto',
                      maxHeight: { xs: '300px', md: '700px' },
                      objectFit: 'cover',
                    }}
                    />
                </Grid>
              </Grid>
            </Box>
            

            {/* color */}
            <ColorCarens/>

            {/* Sliding Carens */}
            <Slidingcarens/>

             {/* Full-sized image */}
             <Box sx={{ width: '100vw', height: {md:'100vh',sm:'60vh',xs:'40vh'}, overflow: 'hidden' }}>
                <Grid
                  container
                  sx={{
                    width: '100%',
                    height: {md:'100vh',sm:'60vh',xs:'40vh'},
                    backgroundImage: `url(${Carensfullimage})`,
                    backgroundSize: '100% 100%', 
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      color: '#fff',
                      textShadow: '1px 1px 4px rgba(0, 0, 0, 0.7)',
                    }}
                  >

                  </Grid>
                </Grid>
              </Box>

              {/* Content carrousels */}
              <Contentcarrousel/>


            {/* Footer */}
            <Footer/>
        </Box>
    </Box>

  );
};

export default Caren;
