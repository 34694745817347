import React from 'react';
import Slider from 'react-slick';
import { Box, Typography } from '@mui/material';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Image1 from '../../../Image/Ev9/card1.jpg';
import Image2 from '../../../Image/Ev9/card2.jpg';
import Image3 from '../../../Image/Ev9/card3.jpg';
import Image4 from '../../../Image/Ev9/card4.jpg';
import Image5 from '../../../Image/Ev9/card5.jpg';
import Image6 from '../../../Image/Ev9/card6.jpg';
import Image7 from '../../../Image/Ev9/card7.jpg';


const cardData = [
  {
    image: Image1,
    title: '* ADAS Level-2 with 27 Autonomous Feature',
    description: 'Every vigilant and always ready to step in, the EV9 ensures the ultimate active and passive safety. With more trhan 27 * ADAS functions and a plethora of other fetaures, everyone in and around the EV9 stays safe and in control'
  },
  {
    image: Image2,
    title: 'Digital Pattern Lighting Grille',
    description: 'The sleek and muscular body of the EV9 features the Digital Pattern Ligting Grille with Intelligent Ice Cube LED Projectiion Headlamps; it certainly leaves a lasting impression on everyone'
  },
  {
    image: Image3,
    title: 'Trinity Panaromic  Display',
    description: 'Technology is integrated seamlessly, and every element is chosen to provide a modern , and convenient driving experience. Navigate and control every drive with the dynamic 12.3* Touchscreen Navigation'
  },
  {
    image: Image4,
    title: 'Over the Air Software Updates',
    description: 'Let your vehicle evolve effortlessly with wireless upgrades. '
  },
  {
    image: Image5,
    title: '2nd row Captain Seats with Relaxation & Massage Function',
    description: 'The 2nd row captain seats with relaxation and massage functions transform your ride into sanctuary. Experience customizable comfort and luxury, all the way. '
  },
  {
    image: Image6,
    title: 'Dual sunroof',
    description: "Embrace the open sky with the Kia Ev9's dual sunroof and enhance your connection with the world beyond"
  },
  {
    image: Image7,
    title: 'Digital IRVMs',
    description: "Experience enhanced visibility with the IRVM's integrated digital screen, offering a real-time rear camera view. Say goodbye to blind spots and enjoy safer, more confidient driving, evn in challenging conditions."
  },
];

const CustomCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024, // Tablet view
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600, // Mobile view
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <Box sx={{ margin: '15px' }}>
      <Typography variant="h3" sx={{ textAlign: 'center', fontFamily: 'Kia-sig', fontWeight: 800 }}>
      Meet the 2024 World Car of the Year
      </Typography>
      <Box sx={{ width: '88%', margin: 'auto', padding: '20px 30px' }}>
        <Slider {...settings}>
          {cardData.map((card, index) => (
            <Box key={index} sx={{ padding: '0 10px', }}>
              <img src={card.image} alt={card.title} style={{ width: '90%', height: 'auto', borderRadius: '8px' }} />
              <Typography variant="h6" sx={{ marginTop: 2 ,width:'95%',fontFamily:"Kia-sig"}}>{card.title}</Typography>
              <Typography variant="body2" sx={{ color: 'gray',width:'80%' }}>{card.description}</Typography>
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

// Custom arrow components
const SampleNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      style={{
        display: 'block',
        background: 'rgba(0, 0, 0, 0.5)',
        borderRadius: '50%',
        padding: '10px',
        right: '-50px',
        zIndex: 1,
      }}
    />
  );
}

const SamplePrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      style={{
        display: 'block',
        background: 'rgba(0, 0, 0, 0.5)',
        borderRadius: '50%',
        padding: '10px',
        left: '-50px',
        zIndex: 1,
      }}
    />
  );
}

export default CustomCarousel;
