import React, { useState } from 'react';
import { Container, Grid, Box, Button, Typography, Collapse, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const styles = {
  questionBox: {
    padding:'10px',
    width: '80vw',
    borderRadius: '4px',
    marginBottom: '10px',
    display: 'flex',
    fontFamily: 'KIAbodyLatinbasic_Bold',
    fontSize: '3.55556vw',
    flexDirection: 'column',
  },
  questionHeader: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'KIAbodyLatinbasic_Regular',
    justifyContent: 'space-between',
    color: 'black',
  },
  expandableContent: {
    padding: '5px',
  },
};

function QuestionBox({ question, content, expanded, onToggle }) {
  return (
    <Box sx={styles.questionBox}>
      <Box sx={styles.questionHeader}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'KIAbodyLatinbasic_Bold',
            fontSize: {md:'1.3vw',xs:'2.9vw',sm:'2.4vw'}, 
            backgroundColor: 'transparent',
            color: '#000',
            paddingRight: '4%',
            paddingLeft: '0',
            fontWeight: '750',
          }}
        >
          {question}
        </Typography>
        <Button onClick={onToggle} sx={{ color: 'black' }}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
      </Box>

      <Collapse in={expanded}>
        <Box sx={styles.expandableContent}>
          <Typography
            variant="body2"
            sx={{
              fontFamily: 'KIAbodyLatinbasic_Regular',
              fontSize: {md:'1.1vw',xs:'2.7vw',sm:'2.2vw'},
            }}
          >
            {content}
          </Typography>
        </Box>
      </Collapse>
    </Box>
  );
}


export default function App() {
  const [expanded, setExpanded] = useState(null);

  const contents = [
   
  'Kia EV6 MSRP* starts from ₹60 96 638* (exclusive of all taxes).',
  "Yes, Kia EV6 comes with a Wide Electric Sunroof.",
  "The seating capacity of Kia EV6 electric car is 5.",
  "Kia EV6 electric car offers 8 airbags for comprehensive safety and complete peace of mind.",
  "The fully electric new Kia EV6 car is available with AWD drive types.",
  "The Kia EV6 range of up to 708 kilometers* on a single charge, thanks to its 77.4 kWh battery pack employ a 2-way charging technology that turns it into a portable energy source capable of supplying current."
  ];

  const handleToggle = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <Container>
      <Box
  sx={{
    width: '100%',
    height:'100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }}
>
  <Typography
    variant="h2"
    sx={{
      fontSize: { md: '3.08333vw', xs: '4.7vw' },
      fontFamily: 'KIAbodyLatinbasic_Bold',
      paddingBottom: { md: '3.125vw', xs: '5.4vw' },
      fontWeight: '900',
      marginTop: { xs: '50px' },
    }}
  >
    Frequently Asked Questions
  </Typography>
  <Grid container spacing={1}>
    <Grid item xs={12} md={12}>
      <Box
        sx={{
          fontFamily: 'KIAbodyLatinbasic_Regular',
          backgroundColor: 'transparent',
          color: '#000',
          paddingRight: '4%',
          paddingLeft: '0',
        }}
      >
        {[
          'What is Kia EV6 new Electric car price in India?',
          "Does the Kia EV6 electric vehicles come with a sunroof?",
          "How many seats does the Kia EV6 luxury electric car have?",
          "How many airbags does the Kia EV6 electric vehicles feature?",
          "What are the drive types available in the Kia EV6?",
          "What is the driving range of Kia EV6?"
        ].map((question, index) => (
          <React.Fragment key={index}>
            <QuestionBox
              question={question}
              content={contents[index]}
              expanded={expanded === index}
              onToggle={() => handleToggle(index)}
            />
            {index < contents.length - 1 && (
              <Divider
                sx={{
                  margin: '10px 0',
                  backgroundColor: 'black',
                  height: '2px',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                }}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
    </Grid>
  </Grid>
</Box>

    </Container>
  );
}
