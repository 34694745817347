import React from 'react';
import { Button, Typography, Box, Grid, Card, CardContent, CardMedia } from '@mui/material';
import Seltos from '../../Brochure/Seltos/Seltos.pdf';
import Sonet from '../../Brochure/Sonet/Sonet.pdf';
import Carens from '../../Brochure/Carens/Carens.pdf';
import EV6 from '../../Brochure/EV6/Kia-EV6_Desktop.pdf';
import Carnival from '../../Brochure/Carnival/Kia_Carnival_Brochure_Desktop.pdf';
import EV9 from '../../Brochure/Ev9/Ev9.pdf';
import SeltosImg from '../../Image/Seltos/seltos1.jpg';
import SonetImg from '../../Image/Sonet/image1.jpg';
import CarensImg from '../../Image/Carens/carensfullimage.jpg';
import EV6Img from '../../Image/Ev6/evcard.jpg';
import CarnivalImg from '../../Image/Carnival/image2.jpg';
import EV9Img from '../../Image/Ev9/full5.jpg';

const cars = [
  {
    name: 'Kia Seltos',
    img: SeltosImg,
    brochure: Seltos,
    downloadName: 'Kia_Seltos_Brochure.pdf',
  },
  {
    name: 'Kia Sonet',
    img: SonetImg,
    brochure: Sonet,
    downloadName: 'Kia_Sonet_Brochure.pdf',
  },
  {
    name: 'Kia Carens',
    img: CarensImg,
    brochure: Carens,
    downloadName: 'Kia_Carens_Brochure.pdf',
  },
  {
    name: 'Kia EV6',
    img: EV6Img,
    brochure: EV6,
    downloadName: 'Kia_EV6_Brochure.pdf',
  },
  {
    name: 'Kia Carnival',
    img: CarnivalImg,
    brochure: Carnival,
    downloadName: 'Kia_Carnival_Brochure.pdf',
  },
  {
    name: 'Kia EV9',
    img: EV9Img,
    brochure: EV9,
    downloadName: 'Kia_EV9_Brochure.pdf',
  },
];

const BrochurePage = () => {
  return (
    <Box textAlign="center" sx={{ p: 4, marginTop: '90px' }}>
      <Typography
        variant="h4"
        sx={{
          fontFamily: 'KIAbodyLatinbasic_Bold',
          color: '#000',
          mb: 4,
        }}
      >
        KIA BROCHURES
      </Typography>

      <Grid container spacing={3} justifyContent="center">
        {cars.map((car, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ maxWidth: 345, mx: 'auto' }}>
              <CardMedia
                component="img"
                height="200"
                image={car.img}
                alt={car.name}
              />
              <CardContent>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: 'KIAbodyLatinbasic_Bold',
                    color: '#91999D',
                    mb: 2,
                    textTransform:"uppercase",
                    fontWeight:'bolder',
                  }}
                >
                  {car.name}
                </Typography>
                <Button
                  variant="contained"
                  color="error"
                  href={car.brochure}
                  download={car.downloadName}
                  sx={{ fontWeight: '800', 
                    fontSize: '12px',
                    FONTfAMILY:"KIAbodyLatinbasic_Bold",
                    backgroundColor:'#545454', 
                    '&:hover':{
                      backgroundColor:'#545454'
                    }
                  }}
                >
                  Download Brochure
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default BrochurePage;
