import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Controller from "../Containers/Controller_Cont.js";
import Drawer from "../Containers/DrawerCont.js";
import Brochure from "../../Pages/Brochure/Component/Brochure.js";
import Header from "../../Pages/Navbar/Component/headers.js";
import Navbar from "../../Pages/Navbar/Component/Nav.js";
import Home from "../../Pages/Home/Container/homeCon.js";
import Seltos from "../../Pages/Cars/Seltos/Component/Seltos.js";
import Sonet from "../../Pages/Cars/Sonet/Component/Sonet.js";
import Carens from "../../Pages/Cars/Carens/Component/Carens.js";
import Ev6 from "../../Pages/Cars/EV6/Component/Ev6.js";
import Carnival from "../../Pages/Cars/Carnival/Component/Carnival.js";
import Ev9 from "../../Pages/Cars/EV9/Component/Ev9.js";
import Syros from "../../Pages/Cars/Syros/Component/Syros.js";
import Contact from "../../Pages/contact/Container/contactCon.js";
import Enquiry from "../../Pages/Enquiry/Container/enquiryCon.js";
import Service from "../../Pages/Services/Container/ServiceCon.js";
import Pricing from "../../Pages/Pricing/Containers/pricingCon.js";
// import Socialmedia from "../../Pages/Socialmedia/Component/Socialmedia.js";
import Preowned from "../../Pages/Preownedcars/Container/preownedCon.js";
import Footer from "../../Pages/Footer/Component/Footer.js";
import Clientnav from "../../Client/Containers/NavbarCont.js";
import Advertisement from "../../Client/Containers/advertisementCon.js";
import Oldcar from "../../Client/Containers/oldCarCont.js";
import Admindashboard from "../../Client/Containers/dashboardCon.js";
import EditPage from "../../Client/Containers/addEditCarCon.js";
import View from "../../Client/Containers/ViewpageCon.js";
import Addinginfo from "../../Client/Containers/addOldCarsCon.js";
import Overview from "../../Client/Containers/addOverviewsCon.js";
import Specification from "../../Client/Containers/addSpecificationsCon.js";
import Features from "../../Client/Containers/addFeaturesCon.js";
import Login from "../../Auth/Containers/Login_Cont.js";
import ContactDetails from "../../Client/Containers/ContactDetailsCon.js";
import TestDrive from "../../Client/Containers/testDriveCon.js";
// import Footer from "../../Pages/Home/Components/Footer.js";
import Services from "../../Client/Containers/serviceCon.js";
// import VehicleEnq from "../../Client/Containers/ViewpageCon.js";
// import Login from "../../Auth/Containers/Login_Cont.js";
import Profile from "../../Pages/Profile/Containers/ProfileCont.js";
import Car1 from "../../Pages/car-details/Container/overviewCon.js";
import VehicleEnq from "../../Client/Containers/vehicleEnqCon.js";
import Enquirys from "../../Client/Containers/enquiryCon.js";
import Quatation from "../../Client/Containers/quatations.js";
class Routers extends Component {
  render() {
    const { login } = this.props;
    let body;
    console.log(login.type);

    if (login.isHome) {
      body = (
        <div>
          <main style={{ flexGrow: 1 }}>
            <Controller />
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <Header />
                    <Navbar />
                    <Home />
                  </>
                }
              />
              <Route
                path="/car-details/:id/:brand"
                element={
                  <>
                    <Header />
                    <Navbar />
                    <Car1 />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/pricing"
                element={
                  <>
                    <Header />
                    <Navbar />
                    <Pricing />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/brochure"
                element={
                  <>
                    <Header />
                    <Navbar />
                    <Brochure />
                    <Footer />
                  </>
                }
              />

              <Route
                path="/seltos"
                element={
                  <>
                    <Header />
                    <Navbar />
                    <Seltos />
                  </>
                }
              />
              <Route
                path="/sonet"
                element={
                  <>
                    <Header />
                    <Navbar />
                    <Sonet />
                  </>
                }
              />
              <Route
                path="/carens"
                element={
                  <>
                    <Header />
                    <Navbar />
                    <Carens />
                  </>
                }
              />
              <Route
                path="/ev6"
                element={
                  <>
                    <Header />
                    <Navbar />
                    <Ev6 />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/ev9"
                element={
                  <>
                    <Header />
                    <Navbar />
                    <Ev9 />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/carnival"
                element={
                  <>
                    <Header />
                    <Navbar />
                    <Carnival />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/syros"
                element={
                  <>
                    <Header />
                    <Navbar />
                    <Syros />
                    <Footer />
                  </>
                }
              />
              {/* <Route
                path="/socialmedia"
                element={
                  <>
                    <Header/>
                    <Navbar />
                    <Socialmedia />
                    <Footer />
                  </>
                }
              /> */}
              <Route
                path="/preowned"
                element={
                  <>
                    <Header />
                    <Navbar />
                    <Preowned />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/contact"
                element={
                  <>
                    <Header />
                    <Navbar />
                    <Contact />
                  </>
                }
              />
              <Route
                path="/enquiry"
                element={
                  <>
                    <Header />
                    <Navbar />
                    <Enquiry />
                  </>
                }
              />
              <Route
                path="/service"
                element={
                  <>
                    <Header />
                    <Navbar />
                    <Service />
                    <Footer />
                  </>
                }
              />
              {/* <Route path='/pre-owned' element={<><Navbar/><Preowned/><Footer/></>}/> */}
              <Route exact path="/admin" element={<Login />} />

              {/* <Route
                exact
                path="/oldcar"
                element={
                  <>
                    <Clientnav /> <Oldcar />
                  </>
                }
              />

              <Route
                exact
                path="/addinfo"
                element={
                  <>
                    <Clientnav />
                    <Addinginfo />
                    <Footer />
                  </>
                }
              />
              <Route
                exact
                path="/overview"
                element={
                  <>
                    <Clientnav />
                    <Overview />
                    <Footer />
                  </>
                }
              />
              <Route
                exact
                path="/specification"
                element={
                  <>
                    <Clientnav />
                    <Specification />
                    <Footer />
                  </>
                }
              />
              <Route
                exact
                path="/feature"
                element={
                  <>
                    <Clientnav />
                    <Features />
                    <Footer />
                  </>
                }
              />
              <Route
                exact
                path="/edit/:id"
                element={
                  <>
                    <Clientnav /> <EditPage />
                    <Footer />
                  </>
                }
              />
              <Route
                exact
                path="/view/:id"
                element={
                  <>
                    <Clientnav /> <View />
                    <Footer />
                  </>
                }
              /> */}
            </Routes>
          </main>
        </div>
      );
    } else {
      switch (login.type) {
        case "SA":
          console.log(login.type);
          body = (
            <div>
              {/* <Drawer type={login.type} /> */}
              <main style={{ flexGrow: 1, paddingTop: 0 }}>
                <Controller />

                <Routes>
                  <Route
                    exact
                    path="/admin"
                    element={
                      <>
                        <Header />
                        <Admindashboard />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/advertisement"
                    element={
                      <>
                        <Header /> <Clientnav /> <Advertisement />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/profile"
                    element={
                      <>
                        <Header /> <Clientnav /> <Profile />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/vehicle-enquiry"
                    element={
                      <>
                        <Header /> <Clientnav /> <VehicleEnq />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/enquiry"
                    element={
                      <>
                        <Header />
                        <Clientnav /> <Enquirys />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/quatation"
                    element={
                      <>
                        <Header />
                        <Clientnav /> <Quatation />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/contact-details"
                    element={
                      <>
                        <Header />
                        <Clientnav /> <ContactDetails />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/service-request"
                    element={
                      <>
                        <Header />
                        <Clientnav /> <Services />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/vehicle-enquiry"
                    element={
                      <>
                        <Header />
                        <Clientnav /> <VehicleEnq />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/oldcar"
                    element={
                      <>
                        <Header />
                        <Clientnav /> <Oldcar />
                      </>
                    }
                  />

                  <Route
                    exact
                    path="/book-test-drive"
                    element={
                      <>
                        <Header />
                        <Clientnav /> <TestDrive />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/addinfo"
                    element={
                      <>
                        <Header />
                        <Clientnav />
                        <Addinginfo />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/overview"
                    element={
                      <>
                        <Header />
                        <Clientnav />
                        <Overview />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/specification"
                    element={
                      <>
                        <Header />
                        <Clientnav />
                        <Specification />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/feature"
                    element={
                      <>
                        <Header />
                        <Clientnav />
                        <Features />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/edit/:id"
                    element={
                      <>
                        <Header />
                        <Clientnav /> <EditPage />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/view/:id"
                    element={
                      <>
                        <Header />
                        <Clientnav /> <View />
                        <Footer />
                      </>
                    }
                  />
                </Routes>
              </main>
            </div>
          );
          break;
        case "E":
          body = (
            <div>
              <Drawer type={login.type} />
              <main style={{ flexGrow: 1, paddingTop: 70 }}>
                <Controller />
                <Routes></Routes>
              </main>
            </div>
          );
          break;
        default:
          body = (
            <div>
              <Drawer type={login.type} />
              <main style={{ flexGrow: 1, paddingTop: 70 }}>
                <Controller />
                <Routes></Routes>
              </main>
            </div>
          );
          break;
      }
    }

    return <Router>{body}</Router>;
  }
}

export default Routers;
