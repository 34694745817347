import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import sel1 from '../../../Image/Sonet/son1.png';
import sel2 from '../../../Image/Sonet/son2.png';
import sel3 from '../../../Image/Sonet/son3.png';
import sel4 from '../../../Image/Sonet/son4.png';
import sel5 from '../../../Image/Sonet/son5.png';
import sel6 from '../../../Image/Sonet/son6.png';
import sel7 from '../../../Image/Sonet/son7.png';
import sel8 from '../../../Image/Sonet/son8.png';
import sel9 from '../../../Image/Sonet/son9.png';
import { Typography,Box } from "@mui/material";

const CustomCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0); 
  const [fixedImage, setFixedImage] = useState(sel1); 

  const carImages = [
    { src: sel1, alt: "son1", color: "#B3001E" },
    { src: sel2, alt: "son2", color: "#F4F9FB" },
    { src: sel3, alt: "son3", color: "#36363B" },
    { src: sel4, alt: "son4", color: "#050709" },
    { src: sel5, alt: "son5", color: "#E0E0DF" },
    { src: sel6, alt: "son6", color: "#B2001E" },
    { src: sel7, alt: "son7", color: "#F4F9FC" },
    { src: sel8, alt: "son8", color: "#2B2C71" },
    { src: sel9, alt: "son9", color: "#3E493B" },

  ];

  const handleSelect = (index) => {
    setActiveIndex(index);
    setFixedImage(carImages[index].src); 
  };

  return (
    <div style={{ position: "relative",margin:0,padding:0 }}>
    <div style={{ textAlign: "center",padding: "10px" }}>
    <Box sx={{paddingLeft:{xs:6,sm:13,md:23},textAlign:"left"}}>
      <Typography variant="h6" sx={{color:'#9F9C9C',fontSize:{xs:'16px',sm:'22px'},fontFamily:'KIAbodyLatinbasic_Bold',fontWeight:800,paddingBottom:1}}>DESIGN</Typography>
      <Typography variant="h4" sx={{fontSize:{xs:'22px',sm:'48px'},fontFamily:'KIAbodyLatinbasic_Bold',fontWeight:800,}}>SONET</Typography>
    </Box>
      <img
        className="d-block w-100"
        src={carImages[activeIndex].src}
        alt={carImages[activeIndex].alt}
        style={{ maxWidth: "70%", height: "auto",paddingBottom:'10px' }}
      />
    </div>

    {/* Custom Circle Indicators */}
    <div style={{ position: "absolute", bottom: "20px", left: "50%", transform: "translateX(-50%)", display: "flex", gap: "12px" }}>
      {carImages.map((car, index) => (
        <div
          key={index}
          onClick={() => handleSelect(index)}
          style={{
            width: "23px",
            height: "22px",
            borderRadius: "50%",
            backgroundColor: activeIndex === index ? car.color : car.color, 
            cursor: "pointer",
          }}
        />
      ))}
    </div>
  </div>
  );
};

export default CustomCarousel;
