import React, { useState } from 'react';
import { Container, Grid, Box, Button, Typography, Collapse, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const styles = {
  questionBox: {
    padding:'10px',
    width: '80vw',
    borderRadius: '4px',
    marginBottom: '10px',
    display: 'flex',
    fontFamily: 'KIAbodyLatinbasic_Regular',
    fontSize: '3.55556vw',
    flexDirection: 'column',
  },
  questionHeader: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'KIAbodyLatinbasic_Regular',
    justifyContent: 'space-between',
    color: 'black',
  },
  expandableContent: {
    padding: '5px',
  },
};

function QuestionBox({ question, content, expanded, onToggle }) {
  return (
    <Box sx={styles.questionBox}>
      <Box sx={styles.questionHeader}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'KIAbodyLatinbasic_Bold',
            fontSize: {md:'1.3vw',xs:'2.9vw',sm:'2.4vw'}, 
            backgroundColor: 'transparent',
            color: '#000',
            paddingRight: '4%',
            paddingLeft: '0',
            fontWeight: '750',
          }}
        >
          {question}
        </Typography>
        <Button onClick={onToggle} sx={{ color: 'black' }}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
      </Box>

      <Collapse in={expanded}>
        <Box sx={styles.expandableContent}>
          <Typography
            variant="body2"
            sx={{
              fontFamily: 'KIAbodyLatinbasic_Regular',
              fontSize: {md:'1.1vw',xs:'2.7vw',sm:'2.2vw'},
            }}
          >
            {content}
          </Typography>
        </Box>
      </Collapse>
    </Box>
  );
}


export default function App() {
  const [expanded, setExpanded] = useState(null);

  const contents = [
   
  'The New Kia electric EV9 is a 6-Seater car. It features captain seats in the second row, which include both relaxation and massage functions.',
  "Kia EV9 Electric Car starts at price of ₹ 1 29 90 000*(Ex-showroom price all India).",
  "Yes, the Kia EV9 is compatible with ultra-fast charging, supporting both 400V and 800V systems*",
  "Yes, the Kia EV9 is available with a dual sunroof option.",
  "The Kia EV9 can charge from 10% to 80% in approximately 24 minutes*."

  ];

  const handleToggle = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <Container>
      <Box
          sx={{
            width: '100%',
            height:'100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: { md: '3.08333vw', xs: '4.7vw' },
              fontFamily: 'KIAbodyLatinbasic_Bold',
              paddingBottom: { md: '3.125vw', xs: '5.4vw' },
              fontWeight: '900',
              marginTop: { xs: '50px' },
            }}
          >
            Frequently Asked Questions
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  fontFamily: 'KIAbodyLatinbasic_Bold',
                  backgroundColor: 'transparent',
                  color: '#000',
                  paddingRight: '4%',
                  paddingLeft: '0',
                }}
              >
                {[
                  'How many seats does the Kia EV9 electric car have?',
                  "What is the price of the Kia EV9 Electric Car in India?",
                  "Does Kia EV9 Supports Fast Charging?",
                  "Does the Kia EV9 have a sunroof?",
                  "How long does it take to charge the Kia EV9?",
                ].map((question, index) => (
                  <React.Fragment key={index}>
                    <QuestionBox
                      question={question}
                      content={contents[index]}
                      expanded={expanded === index}
                      onToggle={() => handleToggle(index)}
                    />
                    {index < contents.length - 1 && (
                      <Divider
                        sx={{
                          margin: '10px 0',
                          backgroundColor: 'black',
                          height: '2px',
                          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                        }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>

    </Container>
  );
}
