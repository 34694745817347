import React, { useState } from 'react';
import { Container, Grid, Box, Button, Typography, Collapse, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const styles = {
  questionBox: {
    padding:'10px',
    width: '80vw',
    borderRadius: '4px',
    marginBottom: '10px',
    display: 'flex',
    fontFamily: 'KIAbodyLatinbasic_Bold',
    fontSize: '3.55556vw',
    flexDirection: 'column',
  },
  questionHeader: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'KIAbodyLatinbasic_Regular',
    justifyContent: 'space-between',
    color: 'black',
  },
  expandableContent: {
    padding: '5px',
  },
};

function QuestionBox({ question, content, expanded, onToggle }) {
  return (
    <Box sx={styles.questionBox}>
      <Box sx={styles.questionHeader}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'KIAbodyLatinbasic_Bold',
            fontSize: { xs: '2.7vw', md: '1.55556vw', }, 
            backgroundColor: 'transparent',
            color: '#000',
            paddingRight: '4%',
            paddingLeft: '0',
            fontWeight: '750',
          }}
        >
          {question}
        </Typography>
        <Button onClick={onToggle} sx={{ color: 'black' }}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
      </Box>

      <Collapse in={expanded}>
        <Box sx={styles.expandableContent}>
          <Typography
            variant="body2"
            sx={{
              fontFamily: 'KIAbodyLatinbasic_Regular',
              fontSize: {md:'1.1vw',xs:'2.7vw',sm:'2.2vw'},
            }}
          >
            {content}
          </Typography>
        </Box>
      </Collapse>
    </Box>
  );
}


export default function App() {
  const [expanded, setExpanded] = useState(null);

  const contents = [
   
  'New 2024 Kia Carnival Limousine comes in two colors in India, Fusion Black and Glacier White Pearl.',
  "Kia Carnival Limousine plus starts at price of ₹ 63 90 000*(Ex-showroom price all India).",
  "The New Kia Carnival Limousine is a 7-Seater car, comes in (2+2+3).",
  'The Kia Carnival Limousine is available in 8AT.',
  'The new Carnival Limousine comes with 33 autonomous ADAS features designed to keep you secure through every mile.',
  'Yes, New Kia Carnival Limousine comes with wide electric dual sunroof.',
  ];

  const handleToggle = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <Container>
      <Box
  sx={{
    width: '100%',
    height:'100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }}
>
  <Typography
    variant="h2"
    sx={{
      fontSize: { md: '3.08333vw', xs: '4.7vw' },
      fontFamily: 'KIAbodyLatinbasic_Bold',
      paddingBottom: { md: '3.125vw', xs: '5.4vw' },
      fontWeight: '900',
      marginTop: { xs: '50px' },
    }}
  >
    Frequently Asked Questions
  </Typography>
  <Grid container spacing={1}>
    <Grid item xs={12} md={12}>
      <Box
        sx={{
          fontFamily: 'KIAbodyLatinbasic_Bold',
          backgroundColor: 'transparent',
          color: '#000',
          paddingRight: '4%',
          paddingLeft: '0',
        }}
      >
        {[
          'What colors does the 2024 Kia Carnival Limousine come in?',
          'What is 2024 Kia Carnival Limousine price in India?', 
          'How many seating options will the Kia Carnival Limousine offer?',
          'What are the transmission options available in the new Kia Carnival Limousine?', 
          'How many features does the new Carnival Limousine offer in its ADAS system?',
          'Is there Sunroof in Kia Carnival Limousine?',

        ].map((question, index) => (
          <React.Fragment key={index}>
            <QuestionBox
              question={question}
              content={contents[index]}
              expanded={expanded === index}
              onToggle={() => handleToggle(index)}
            />
            {index < contents.length - 1 && (
              <Divider
                sx={{
                  margin: '10px 0',
                  backgroundColor: 'black',
                  height: '2px',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                }}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
    </Grid>
  </Grid>
</Box>

    </Container>
  );
}
