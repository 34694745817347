import React, { useState } from 'react';
import { Container, Grid, Box, Button, Typography, Collapse, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const styles = {
  questionBox: {
    padding:'10px',
    width: '80vw',
    borderRadius: '4px',
    marginBottom: '10px',
    display: 'flex',
    fontFamily: 'KIAbodyLatinbasic_Regular',
    fontSize: '3.55556vw',
    flexDirection: 'column',
  },
  questionHeader: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'KIAbodyLatinbasic_Regular',
    justifyContent: 'space-between',
    color: 'black',
  },
  expandableContent: {
    padding: '5px',
  },
};

function QuestionBox({ question, content, expanded, onToggle }) {
  return (
    <Box sx={styles.questionBox}>
      <Box sx={styles.questionHeader}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'KIAbodyLatinbasic_Bold',
            fontSize: {md:'1.3vw',xs:'2.9vw',sm:'2.4vw'},
            backgroundColor: 'transparent',
            color: '#000',
            paddingRight: '4%',
            paddingLeft: '0',
            fontWeight: '750',
          }}
        >
          {question}
        </Typography>
        <Button onClick={onToggle} sx={{ color: 'black' }}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
      </Box>

      <Collapse in={expanded}>
        <Box sx={styles.expandableContent}>
          <Typography
            variant="body2"
            sx={{
              fontFamily: 'KIAbodyLatinbasic_Regular',
              fontSize: {md:'1.1vw',xs:'2.7vw',sm:'2.2vw'},
            }}
          >
            {content}
          </Typography>
        </Box>
      </Collapse>
    </Box>
  );
}


export default function App() {
  const [expanded, setExpanded] = useState(null);

  const contents = [
   
'Kia Seltos starts at price of ₹ 10 89 900*(Ex-showroom price all India)',
"The Kia Seltos is a 5 seater SUV car which comes with comfortable leatherette Seats and many other features",
"The Kia Seltos SUV offers 6 airbags as standard to ensure the safety of you and your loved ones.",
'The Kia Seltos SUV comes with a Dual Pane Panoramic Sunroof to enjoy the wide sky.',
'The Kia Seltos is available in both manual and automatic transmissions, namely 6MT / IVT 6iMT / 7DCT 6iMT / 6AT.',
'The Kia Seltos Compact SUV cars can be your perfect companion on a long drive with a boot space of 433** litres.',
'The Kia Seltos offers a sunroof on its premium models, including HTK+, HTX, HTX+, GTX+, GTX+(s), X-line(s), and X-line, all of which come equipped with a panoramic sunroof.',
'The X-Line is the top variant of the Kia Seltos compact SUV car in India. The ex-showroom Kia Seltos top model price is Rs. 20 34 900 starting MSRP*.'
  ];

  const handleToggle = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <Container>
      <Box
        sx={{
          width: '100%',
          height:'100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: { md: '3.08333vw', xs: '4.7vw' },
            paddingBottom: { md: '3.125vw', xs: '5.4vw' },
            fontWeight: '900',
            marginTop: { xs: '50px' },
            fontFamily:"KIAbodyLatinbasic_Bold"
          }}
        >
          Frequently Asked Questions
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                fontFamily: 'KIAbodyLatinbasic_Bold',
                backgroundColor: 'transparent',
                color: '#000',
                paddingRight: '4%',
                paddingLeft: '0',
              }}
            >
              {[
                'What is the price of the Kia Seltos?',
                'What are the seating options available in the Kia Seltos?', 
                'How many airbags are there in the Kia Seltos?',
                'What type of sunroof comes with the Kia Seltos?', 
                'What are the transmission options available in the Kia Seltos?',
                'What is the boot space of Kia Seltos SUV cars?',
                'Which model of Kia Seltos has a sunroof?',
                'Which is the top model of Kia Seltos?'

              ].map((question, index) => (
                <React.Fragment key={index}>
                  <QuestionBox
                    question={question}
                    content={contents[index]}
                    expanded={expanded === index}
                    onToggle={() => handleToggle(index)}
                  />
                  {index < contents.length - 1 && (
                    <Divider
                      sx={{
                        margin: '10px 0',
                        backgroundColor: 'black',
                        height: '2px',
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                      }}
                    />
                  )}
                </React.Fragment>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>

    </Container>
  );
}
